/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PSPaginationStyle = {
    self: css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 15px;
        border: 1px solid var(--color-black-10);
        border-top: none;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    `,

    paginationActions: css`
        display: flex;
        align-items: center;
        gap: 10px;
        padding-right: 80px;
    `
}
