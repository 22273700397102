import { entityFetcherMap } from "./index";
import { TDateSelection } from "../../../../contexts";
import { TOption } from "../AutocompleteSelect";

export type TEntityType = keyof typeof entityFetcherMap;

export type TFilterQueryProperties = {
    signal: AbortSignal | undefined,
    searchField: string,
    initialFetchCount: number,
    debounceValue: string,
    date: TDateSelection
}

export type TSelectedOptionsQueryProperties = {
    signal: AbortSignal | undefined,
    selectedIds: Array<string>,
    searchField: string,
    date: TDateSelection
}

export abstract class Entity<T, K extends string> {
    type: TEntityType;
    fieldsMap: Record<K, string>;
    query: T;

    constructor(type: TEntityType, fieldsMap: Record<K, string>, query: T) {
        this.type = type;
        this.fieldsMap = fieldsMap;
        this.query = query;
    }

    abstract filterQuery(properties: TFilterQueryProperties): Promise<Array<TOption>>;

    abstract selectedOptionsQuery(properties: TSelectedOptionsQueryProperties): Promise<Array<TOption>>;
}
