/** @jsxImportSource @emotion/react */
import { Drawer, IconButton } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useRightDrawerContext } from '../contexts';
import { RightDrawerStyle } from './RightDrawer.css';
import { Icon, Tooltip } from '../ui-kit';

type IProps = {

};

const RightDrawer: React.FC<IProps> = (props) => {
    const { } = props;
    const { drawerWidth, isDrawerOpen, toggleDrawer, drawerState, setWrapperDomNode, setDrawerContent } = useRightDrawerContext();

    const drawerContentWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const drawerRef = drawerContentWrapperRef.current;
        if (!drawerRef) return;
        setWrapperDomNode(drawerRef);
    }, [drawerContentWrapperRef, setDrawerContent, setWrapperDomNode])

    useEffect(() => {
        const drawerRef = drawerContentWrapperRef.current;
        const observer = new MutationObserver((mutationsList) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    setDrawerContent(mutation.target.childNodes.length > 0);
                }
            }
        });

        if (drawerRef) {
            observer.observe(drawerRef, { childList: true });
        }

        return () => {
            if (observer && drawerRef) {
                observer.disconnect();
            }
        };
    }, []);

    return (
        <div css={RightDrawerStyle.drawerContainer(drawerWidth)}>
            {drawerState !== 'CLOSED' &&
                <Tooltip title={isDrawerOpen ? 'Minimize drawer' : 'Open drawer'} placement='right'>
                    <IconButton
                        onClick={() => toggleDrawer()}
                        css={RightDrawerStyle.toggleDrawerButton(isDrawerOpen)}
                        disableRipple
                        disableFocusRipple
                    >
                        <Icon color='white' iconName={isDrawerOpen ? 'PSChevronRightIcon' : 'PSChevronLeftIcon'} />
                    </IconButton>
                </Tooltip>
            }
            <Drawer open={drawerWidth !== 0 || isDrawerOpen} variant='persistent' anchor='right'>
                <div css={drawerState !== 'OPEN' && RightDrawerStyle.drawerContentMinimizedState} ref={drawerContentWrapperRef}></div>
            </Drawer>
        </div>
    )
}

export default RightDrawer;