/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const AuditLogsDrawerStyle = {
    self: css`
        display: flex;
        flex-direction: column;
        padding: 0 30px 30px 30px;    
    `,
    header: css`
        padding: 20px 0;
    `,
    textKey: css`
        border-top: 1px solid var(--color-black-50);
        padding: 10px 0;
    `,
}