/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionFormProps, MODERATOR_CATEGORIES, TModeratorProtection } from '../Common';
import { ModeratorFormStyle } from './ModeratorForm.css';
import { Controller } from 'react-hook-form';
import { Slider } from '@mui/material';
import { PSAutocomplete, Text } from '../../../../ui-kit';

const ModeratorForm: React.FC<IProtectionFormProps<TModeratorProtection>> = (props) => {
    const { control, formName, protection, isAdvancedMode } = props;

    return (
        <div css={ModeratorFormStyle.self}>
            <Controller
                name={`${formName}.categories`}
                control={control}
                defaultValue={protection.categories}
                render={({ field }) => (
                    <PSAutocomplete
                        {...field}
                        multiple
                        includeSelectAll
                        optionsObject={MODERATOR_CATEGORIES}
                        textFieldLabel='Moderated Categories'
                        outsideOnChange={field.onChange}
                    />
                )}
            />

            {isAdvancedMode && <Controller
                name={`${formName}.threshold`}
                defaultValue={protection.threshold}
                control={control}
                rules={{
                    min: 0,
                    max: 1,
                }}
                render={({ field }) => (
                    <div>
                        <Text>Threshold</Text>
                        <Slider {...field} min={0} max={1} step={0.05} valueLabelDisplay="auto"
                            marks={[
                                { value: 0, label: '0' },
                                { value: 0.25, label: '0.25' },
                                { value: 0.5, label: '0.5' },
                                { value: 0.75, label: '0.75' },
                                { value: 1, label: '1' },
                            ]}
                        />
                    </div>
                )}
            />}
        </div>
    )
}

export default ModeratorForm;