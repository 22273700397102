/** @jsxImportSource @emotion/react */
import React from 'react';
import { PSBox, PSButton, PSCopyText, Text } from '../../../../ui-kit';
import { css } from '@emotion/react';
import { handleDownload } from './generateConfig';
import { toast } from 'react-toastify';
import { useConnectorContext } from '../../../../contexts/ConnectorContext';

type IProps = {

};

const EmployeesGoogleWorkspace: React.FC<IProps> = (props) => {
    const { } = props;
    const { connector } = useConnectorContext();
    if (!connector) return null;

    const handleDownloadExtensionPolicy = () => {
        const configFile = {
            content: JSON.stringify({
                apiDomain: { Value: window.location.hostname },
                apiKey: { Value: connector.id },
                addCustomHttpHeader: { Value: false },
            }, null, 2),
            filename: 'extension_policy.txt'
        }

        handleDownload(configFile);

        toast.success('Extension policy file downloaded successfully');
    };

    return (
        <PSBox padding={30} boxCss={css`display: flex; flex-direction: column; gap: 25px;`}>
            <Text variant='header2'>Google workspace</Text>
            <div css={css`display: flex; flex-direction: column; gap: 20px;`}>
                <div>
                    <Text variant='bold'>Overview</Text>
                    <Text>Deploy the Prompt Security browser extension using Google Admin console.</Text>
                </div>

                <Text inline>Prerequisites: Complete the steps required for browsers in your organization to be managed via the Google Admin console. For more information on enrolling browsers, refer to the
                    <a href='https://admin.google.com/ac/chrome/guides/' target='_blank' rel="noreferrer"><Text inline onClick={() => { }}> Set up Chrome Browsers guide from Google. </Text></a>
                </Text>

                <Text inline>You will also need the role of Super Admin, or you'll need to
                    <a href='https://support.google.com/a/answer/2406043' target='_blank' rel="noreferrer"><Text inline onClick={() => { }}> create a custom role </Text></a>
                    with the minimum required privileges to add the Prompt Security browser extension. To create a custom role in the Google Admin console, go to {`Services > Chrome Management > Settings > Manage User Settings > Manage Application Settings`}.
                </Text>

                <div css={css`display: flex; align-items: center; gap: 10px;`}>
                    <Text inline>1. First, download the policy file by</Text>
                    <Text inline onClick={handleDownloadExtensionPolicy}>clicking here - Download Extension Policy</Text>
                </div>

                <Text variant='header2'>Add the Prompt Security browser extension</Text>

                <div css={css`display: flex; flex-direction: column; gap: 20px;`}>
                    <Text inline>To add the browser extension, log into the
                        <a href='https://admin.google.com/' target='_blank' rel="noreferrer"><Text inline onClick={() => { }}> Google Admin console  </Text></a>
                        with the Super Admin role or a custom role with the privileges to manage application settings.
                    </Text>

                    <div>
                        <Text inline>2. From the Google Admin console, go to the
                            <a href='https://admin.google.com/ac/chrome/apps/user' target='_blank' rel="noreferrer"><Text inline onClick={() => { }}> Apps & extensions  </Text></a>
                            page (Users & browsers tab).
                        </Text>
                        <Text>Select the relevant organizational unit in case you want to do a targeted deployment.</Text>
                    </div>
                    <div>
                        <Text>3. Click the plus sign to add the Prompt Security browser extension to your console.</Text>
                        <Text>Add the Prompt Security extension to the Google Admin Console extensions page.</Text>
                    </div>
                    <Text>4. Select the Add the Chrome app or extension by ID button to open a frame in your browser.</Text>
                    <Text css={css`display: flex; align-items: center; flex-wrap: wrap; gap: 5px;`}>5. Enter the Extension ID <PSCopyText text='iidnankcocecmgpcafggbgbmkbcldmno' toastText='Extenstion ID Copied to clipboard' /></Text>
                    <div>
                        <Text>6. Select Save to add the Prompt Security extension to your environment.</Text>
                        <Text>Add the Prompt Security extension by ID.</Text>
                    </div>
                    <div>
                        <Text>7. Set the installation policy to Force install + pin to browser toolbar.</Text>
                        <Text>Configure the extension installation policy.</Text>
                    </div>
                </div>

                <Text variant='header2'>Apply the extension policy</Text>

                <div css={css`display: flex; flex-direction: column; gap: 20px;`}>
                    <Text>8. With the extension details pane open, upload the downloaded config file (extension_policy.txt) generated by the Prompt Security app using the upload icon next to the Policy for extensions section.</Text>
                    <Text>Configure the Prompt Security extension policy with the generated config.</Text>
                    <Text>9. Select Save in the top right.</Text>
                    <Text>The Prompt Security browser extension will now start rolling out to users in the selected organizational unit.</Text>
                </div>
            </div>
        </PSBox>
    )
}

export default EmployeesGoogleWorkspace;