/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { SanitizeFileRequest } from './sanitizeFile';
import { PlaygroundFileFormStyle } from './PlaygroundFileForm.css';
import { Icon, PSButton, Text } from '../../../ui-kit';
import { fileSizeFormatter } from '../../../utils/number';

type IProps = {
    formModel: UseFormReturn<Omit<SanitizeFileRequest, "appId">, any, undefined>;
    isLoading?: boolean;
};

const supportedFileTypes = {
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.oasis.opendocument.text': ['.odt'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : ['.xlsx'],
    'text/csv': ['.csv'],
    'text/tab-separated-values': ['.tsv'],
    'message/rfc822': ['.eml'],
    'application/vnd.ms-outlook': ['.msg'],
    'application/rtf': ['.rtf'],
    'application/epub+zip': ['.epub'],
    'text/html': ['.html'],
    'application/xml': ['.xml'],
    'text/xml': ['.xml'],
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/heic': ['.heic'],
    'image/heif': ['.heic'],
    'text/plain': ['.txt']
} as any;

const isUnder100MB = (file: File): boolean => {
    return file.size <= 100 * 1024 * 1024; // 100MB
};

const PlaygroundFileForm: React.FC<IProps> = (props) => {
    const { formModel, isLoading } = props;
    const { watch, setValue } = formModel;

    const file = watch("file");

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (isLoading) return;

        const file = acceptedFiles[0];
        if (!file) return;
        if (supportedFileTypes[file.type] == null) return;
        if (!isUnder100MB) return;

        setValue("file", file);
    }, [setValue]);

    const { getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive } = useDropzone({
        onDrop,
        accept: supportedFileTypes,
        maxFiles: 1,
        maxSize: 100 * 1024 * 1024,
    });

    return (
        <React.Fragment>
            <div {...getRootProps()} css={[
                PlaygroundFileFormStyle.dropFileContainer,
                isDragAccept && PlaygroundFileFormStyle.draggingOverStyle,
                isDragReject && PlaygroundFileFormStyle.fileNotAllowedStyle
            ]}>
                <input {...getInputProps()} disabled={isLoading} />
                {!isDragActive &&
                    <div css={PlaygroundFileFormStyle.dropTextContainer}>
                        <Icon iconName='PSUploadCloudIcon' color='black-50' iconSize={40} />
                        <div css={PlaygroundFileFormStyle.dropTextExplanation}>
                            <Text color='black-70'>Drop a file here, Or click to upload a file</Text>
                            <Text color='black-70' variant='small'>100MB limit</Text>
                        </div>
                    </div>
                }
                {isDragAccept && <Text color='black-70'>File accepted, drop it!</Text>}
                {isDragReject && <Text color='black-70'>File type not accepted</Text>}
            </div>
            {file &&
                <div css={PlaygroundFileFormStyle.fileDetailsContainer}>
                    <Icon iconName='PSFileIcon' color='black-50' iconSize={40} />
                    <div css={PlaygroundFileFormStyle.fileDetails}>
                        <Text color='black-70'>{file.name}</Text>
                        <Text color='black-70' variant='small'>{fileSizeFormatter.format(file.size)}</Text>
                    </div>
                    {!isLoading && <PSButton
                        variant='flat'
                        onClick={() => setValue("file", null)}
                        css={PlaygroundFileFormStyle.deleteFileButton}
                        iconName='PSRemoveIcon'
                    >
                        Remove File
                    </PSButton>}
                </div>
            }
        </React.Fragment>
    );
}

export default PlaygroundFileForm;