/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { DropdownSelect, ChipsInput, Tooltip, Icon, AutocompleteSelect, PSMuiDateRangePicker } from '../../ui-kit';
import 'react-toastify/dist/ReactToastify.css';
import { css } from "@emotion/react";

import {
    Action,
    OperatorKey,
    FilterOperatorValue,
    Pagination,
    SortDirection,
    SortByFields,
    TextType as TextTypeEnum,
    TextRedactionModes,
    InputMaybe,
    FilterInput,
    FilterValuesFields,
} from '../../gql/generated/graphql';

import AlertsLogGrid from "./AlertsLogGrid";

import { useQuery } from "@tanstack/react-query";
import { graphql, createGraphQLClient } from "../../gql";
import { Controller, useForm } from 'react-hook-form';
import { useStateWithParams } from '../../hooks';
import { dateStoreDefault, DateTypeSelection, TPredefinedDateOrTimeSelection, useDateContext } from '../../contexts';
import ActivityMonitorDrawer from './AcitivityMonitorDrawer/ActivityMonitorDrawer';
import { cleanFilter } from '../../utils';
import { IconButton } from "@mui/material";

const queryConfiguration = graphql(`
    query Configuration {
        configuration {
            id
            textRedactionMode
            protectApiQuota {
                remaining
            }
            loggingFrequencyInSeconds
        }
    }
`);

const logsQuery = graphql(`
    query LogsQuery($filter: FilterInput, $pagination: PaginationInput!, $sort: SortInput) {
        logs(options: {pagination: $pagination, filter: $filter, sort: $sort}) {
            logs {
                action
                id
                isValid
                risk
                text
                textType
                time
                user
                userGroups
                appName
                applicationId
                applicationType
                conversationId
                country
                ipAddress
                latency
                modifiedText
                policyName
                promptResponseId
                systemPrompt
                violatingFindings
                violations
                extensionData
		sourceData
                genAiApplication
                isError
            }
            pagination {
                currentPage
                itemsPerPage
                nextPage
                prevPage
                totalCount
                totalPages
            }
        }
    }
`);

type IProps = {};

const AlertsLogsPage: React.FC<IProps> = (props) => {
    const { } = props;

    const [rowIndexViewTextRequestModal, setRowIndexViewTextRequestModal] = useState<number | null>(null);
    const [textCacheMap, setTextCacheMap] = useState<Map<string, string>>(new Map());

    const { control, watch, getValues: getFiltersValues } = useForm<FilterOperatorValue>()

    const [isFirstTimeFetched, setIsFirstTimeFetched] = useState(false);
    const [rowIndex, setRowIndex] = useState<number | null>(null);

    const [viewMode, setViewMode] = useStateWithParams<'alerts' | 'all'>('alerts', 'all');
    const [currentPage, setCurrentPage] = useStateWithParams<number>('currentPage', 1);
    const [itemsPerPage, setItemsPerPage] = useStateWithParams<number>('itemsPerPage', 50);
    const [sortBy, setSortBy] = useStateWithParams<SortByFields>('sortBy', SortByFields.Time);
    const [sortDirection, setSortDirection] = useStateWithParams<SortDirection>('sortDirection', SortDirection.Descending);

    const [activityId, setActivityId] = useStateWithParams<Array<string>>('activityId', []);

    const [user, setUser] = useStateWithParams<Array<string>>('user', []);
    const [isUserExclude, setIsUserExclude] = useStateWithParams<boolean>('isUserExclude', false);

    const [appName, setAppName] = useStateWithParams<Array<string>>('appName', []);
    const [isAppNameExclude, setIsAppNameExclude] = useStateWithParams<boolean>('isAppNameExclude', false);

    const [genAiApplicationName, setGenAiApplicationName] = useStateWithParams<Array<string>>('genAiApplicationName', []);
    const [isGenAiApplicationNameExclude, setIsGenAiApplicationNameExclude] = useStateWithParams<boolean>('isGenAiApplicationNameExclude', false);

    const [userGroups, setUserGroups] = useStateWithParams<Array<string>>('userGroups', []);
    const [isUserGroupsExclude, setIsUserGroupsExclude] = useStateWithParams<boolean>('isUserGroupsExclude', false);

    const [violations, setViolations] = useStateWithParams<Array<string>>('violations', []);
    const [isViolationsExclude, setIsViolationsExclude] = useStateWithParams<boolean>('isViolationsExclude', false);

    const [actions, setActions] = useStateWithParams<Array<Action>>('action', []);
    const [isActionsExclude, setIsActionsExclude] = useStateWithParams<boolean>('isActionsExclude', false);

    const [textType, setTextType] = useStateWithParams<Array<TextTypeEnum>>('textType', []);
    const [isTextTypeExclude, setIsTextTypeExclude] = useStateWithParams<boolean>('isTextTypeExclude', false);

    const { date, setDate } = useDateContext();
    const [paramsDate, setParamsDates] = useStateWithParams<[Date, Date]>('dates', date.type === 'absolute' ? date.dates : dateStoreDefault.logs.dates);
    const [paramsDatePeriod, setParamsDatePeriod] = useStateWithParams<TPredefinedDateOrTimeSelection | 'custom'>('period', date.type === 'relative' ? date.period : 'last7days');
    const [paramsDateType, setParamsDateType] = useStateWithParams<keyof typeof DateTypeSelection>('dateType', date.type === 'absolute' ? 'absolute' : 'relative');

    useEffect(() => {
        if (date.type === 'absolute') {
            setParamsDates(date.dates);
            setParamsDatePeriod(null as any);
        } else if (date.type === 'relative') {
            setParamsDatePeriod(date.period);
            setParamsDates(null as any);
        }

        setParamsDateType(date.type);
    }, [date, setParamsDates, setParamsDatePeriod, setParamsDateType])

    const [pagination, setPagination] = useState<Pagination>({
        currentPage,
        itemsPerPage,
        nextPage: null,
        prevPage: null,
        totalCount: 0,
        totalPages: 0
    });


    useEffect(() => {
        if (isFirstTimeFetched) setCurrentPage(1);
    }, [date, itemsPerPage, textType, sortBy, sortDirection, viewMode, actions, activityId, user, appName, userGroups, violations, genAiApplicationName, isActionsExclude, isTextTypeExclude, isUserExclude, isAppNameExclude, isUserGroupsExclude, isViolationsExclude, isGenAiApplicationNameExclude])

    const { data: logsData, refetch, status, isFetching } = useQuery({
        queryKey: ['alerts', currentPage, itemsPerPage, sortBy, sortDirection, JSON.stringify(date.dates), viewMode, isActionsExclude, isTextTypeExclude, isUserExclude, isAppNameExclude, isUserGroupsExclude, isViolationsExclude, isGenAiApplicationNameExclude, getFiltersValues()],
        queryFn: async ({ signal }) => {
            const baseFilter: InputMaybe<FilterInput> = {
                key: OperatorKey.And,
                value: [
                    {
                        operator: {
                            key: OperatorKey.And,
                            value: [
                                {
                                    time: {
                                        from: date.dates[0].toISOString(),
                                        to: date.dates[1].toISOString()
                                    },
                                    isValid: viewMode === 'alerts' ? false : undefined,
                                    id: activityId.length > 0 ? activityId : undefined,
                                }
                            ]
                        }
                    },
                    user.length > 0 ? {
                        operator: {
                            key: isUserExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                user: user
                            }]
                        }
                    } : {},
                    appName.length > 0 ? {
                        operator: {
                            key: isAppNameExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                appName: appName
                            }]
                        }
                    } : {},
                    userGroups.length > 0 ? {
                        operator: {
                            key: isUserGroupsExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                userGroups: userGroups
                            }]
                        }
                    } : {},
                    actions.length > 0 ? {
                        operator: {
                            key: isActionsExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                action: actions
                            }]
                        }
                    } : {},
                    textType.length > 0 ? {
                        operator: {
                            key: isTextTypeExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                textType: textType
                            }]
                        }
                    } : {},
                    violations.length > 0 ? {
                        operator: {
                            key: isViolationsExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                violations: violations
                            }]
                        }
                    } : {},
                    genAiApplicationName.length > 0 ? {
                        operator: {
                            key: isGenAiApplicationNameExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                genAiApplicationName: genAiApplicationName
                            }]
                        }
                    } : {}
                ]
            }

            try {
                const client = createGraphQLClient(signal);
                const { logs } = await client.request(logsQuery, {
                    filter: cleanFilter(baseFilter),
                    sort: {
                        by: sortBy,
                        direction: sortDirection
                    },
                    pagination: {
                        pageNumber: currentPage,
                        pageSize: itemsPerPage
                    }
                })

                setPagination(logs.pagination);

                if (!isFirstTimeFetched) {
                    setIsFirstTimeFetched(true);
                }

                return logs.logs;
            } catch (error) {
                throw new Error();
            }
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    const { data: configuration } = useQuery(
        {
            queryKey: ["configuration"],
            queryFn: async ({ signal }) => {
                const client = createGraphQLClient(signal);
                const { configuration } = await client.request(queryConfiguration);
                return configuration
            },
        }
    )

    const handleRefresh = () => {
        if (paramsDateType === 'absolute') {
            setDate(prev => ({
                ...prev,
                type: 'absolute',
                dates: paramsDate,
                period: paramsDatePeriod as 'custom',
            }));
        } else if (paramsDateType === 'relative') {
            setDate(prev => ({
                ...prev,
                type: 'relative',
                dates: paramsDate,
                period: paramsDatePeriod as TPredefinedDateOrTimeSelection,
            }));
        }

        refetch();
    }

    watch();

    const isDrawerOpen = rowIndex !== null;
    const isLoading = status === 'pending' || isFetching;
    const isError = status === 'error';

    const isTextHidden = configuration?.textRedactionMode === TextRedactionModes.HideAll;

    return (
        <div css={css`display: flex; flex-direction: column; height: 100%;`}>
            <div css={css`display: flex; align-items: center; gap: 10px; margin-bottom: 10px; justify-content: space-between;`}>
                <div css={css`display: flex; align-items: center; gap: 5px; flex-wrap: wrap;`}>
                    <DropdownSelect onChange={(newValue) => setViewMode(newValue)} value={viewMode} label='View Mode'
                        options={[
                            { value: 'all', label: 'All' },
                            { value: 'alerts', label: 'Alerts Only' }
                        ]} />
                    <Controller
                        name="textType"
                        control={control}
                        defaultValue={textType}
                        render={({ field }) => (
                            <DropdownSelect
                                multiple
                                showExclude
                                label='Prompt / Response'
                                value={field.value}
                                minWidth={190}
                                options={Object.values(TextTypeEnum).map((value) => ({ value: value, label: value }))}
                                onExcludeChange={setIsTextTypeExclude}
                                isExcludeMode={isTextTypeExclude}
                                onChange={(newValue) => {
                                    field.onChange(newValue);
                                    setTextType(newValue);
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="action"
                        control={control}
                        defaultValue={actions}
                        render={({ field }) => (
                            <DropdownSelect
                                multiple
                                showExclude
                                label='Action'
                                value={field.value}
                                options={Object.values(Action).map((value) => ({ value: value, label: value }))}
                                onExcludeChange={setIsActionsExclude}
                                isExcludeMode={isActionsExclude}
                                onChange={(newValue) => {
                                    field.onChange(newValue);
                                    setActions(newValue);
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="user"
                        control={control}
                        defaultValue={user}
                        render={({ field }) => (
                            <AutocompleteSelect
                                entityType='log'
                                showExclude
                                inputLabel='User'
                                searchField={FilterValuesFields.User}
                                selectedIds={user}
                                onExcludeChange={setIsUserExclude}
                                onChange={(newValue) => {
                                    field.onChange(newValue.map(x => x.value));
                                    setUser(newValue.map(x => x.value));
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="appName"
                        control={control}
                        defaultValue={appName}
                        render={({ field }) => (
                            <AutocompleteSelect
                                entityType='log'
                                showExclude
                                inputLabel='Connector'
                                searchField={FilterValuesFields.AppName}
                                selectedIds={appName}
                                onExcludeChange={setIsAppNameExclude}
                                onChange={(newValue) => {
                                    field.onChange(newValue.map(x => x.value));
                                    setAppName(newValue.map(x => x.value));
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="genAiApplicationName"
                        control={control}
                        defaultValue={genAiApplicationName}
                        render={({ field }) => (
                            <AutocompleteSelect
                                entityType='log'
                                showExclude
                                inputLabel='GenAI Application'
                                searchField={FilterValuesFields.GenAiApplicationName}
                                selectedIds={genAiApplicationName}
                                onExcludeChange={setIsGenAiApplicationNameExclude}
                                onChange={(newValue) => {
                                    field.onChange(newValue.map(x => x.value));
                                    setGenAiApplicationName(newValue.map(x => x.value));
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="userGroups"
                        control={control}
                        defaultValue={userGroups}
                        render={({ field }) => (
                            <AutocompleteSelect
                                entityType='log'
                                showExclude
                                inputLabel='User Group'
                                searchField={FilterValuesFields.UserGroups}
                                selectedIds={userGroups}
                                onExcludeChange={setIsUserGroupsExclude}
                                onChange={(newValue) => {
                                    field.onChange(newValue.map(x => x.value));
                                    setUserGroups(newValue.map(x => x.value));
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="violations"
                        control={control}
                        defaultValue={userGroups}
                        render={({ field }) => (
                            <AutocompleteSelect
                                entityType='log'
                                showExclude
                                inputLabel='Violation'
                                searchField={FilterValuesFields.Violations}
                                selectedIds={violations}
                                onExcludeChange={setIsViolationsExclude}
                                onChange={(newValue) => {
                                    field.onChange(newValue.map(x => x.value));
                                    setViolations(newValue.map(x => x.value));
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="id"
                        control={control}
                        defaultValue={activityId}
                        render={({ field }) => (
                            <ChipsInput
                                label='Activity ID'
                                value={field.value!}
                                onChange={(newValue) => {
                                    field.onChange(newValue);
                                    setActivityId(newValue);
                                }}
                            />
                        )}
                    />

                    <Tooltip title='Refresh'>
                        <IconButton
                            onClick={handleRefresh}
                            size='medium'
                            disabled={isLoading}
                            css={css`border: 1px solid var(--color-black-50); padding: 9px 15px; border-radius: 25px;`}
                        >
                            <Icon
                                iconSize={20}
                                iconName='PSRefreshIcon'
                                color='black-70'
                                css={css`
                                transition: transform 0.5s;
                                transform: ${isLoading ? 'rotate(360deg)' : 'rotate(0deg)'};
                            `}
                            />
                        </IconButton>
                    </Tooltip>
                </div>
                <div css={css`display: flex; align-items: start; height: 100%;`}>
                    <PSMuiDateRangePicker />
                </div>
            </div>
            <AlertsLogGrid
                rowsData={logsData}
                pagination={pagination}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                isLoading={isLoading}
                isError={isError}
                rowIndex={rowIndex}
                sortDirection={sortDirection}
                sortBy={sortBy}
                isTextHidden={isTextHidden}
                logsAlerts={viewMode}
                textCacheMap={textCacheMap}
                setItemsPerPage={setItemsPerPage}
                setSortBy={setSortBy}
                setSortDirection={setSortDirection}
                setRowIndex={setRowIndex}
                setTextCacheMap={setTextCacheMap}
                refetchTableData={refetch}
                rowIndexViewTextRequestModal={rowIndexViewTextRequestModal}
                setRowIndexViewTextRequestModal={setRowIndexViewTextRequestModal}
            />
            {logsData && rowIndex !== null &&
                <ActivityMonitorDrawer
                    isHidden={isTextHidden}
                    isDrawerOpen={isDrawerOpen}
                    onClose={() => setRowIndex(null)}
                    logData={logsData[rowIndex]}
                    rowIndex={rowIndex}
                    textCacheMap={textCacheMap}
                    setRowIndexViewTextRequestModal={setRowIndexViewTextRequestModal}
                />}
        </div>
    )
}

export default AlertsLogsPage;