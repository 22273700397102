import { useQuery } from "@tanstack/react-query";
import { graphql, createGraphQLClient } from "../../gql";
import { IntegrationTypes } from '../../gql/generated/graphql';
import { IntegrationOktaCard } from './IntegrationTypes/IntegrationOkta';
import { IntegrationCard } from './IntegrationCard';
import { IntegrationS3Card } from './IntegrationTypes/IntegrationS3';
import { IntegrationSplunkCard } from './IntegrationTypes/IntegrationSplunk';
import { IntegrationPantherCard } from './IntegrationTypes/IntegrationPanther';
import { IntegrationSlackCard } from './IntegrationTypes/IntegrationSlack';
import { IntegrationLogzIoCard } from './IntegrationTypes/IntegrationLogzIo';
import { IntegrationSlackAppCard } from './IntegrationTypes/IntegrationSlackApp';
import { IntegrationOpensearchCard } from './IntegrationTypes/IntegrationOpensearch';
import { Typography } from "@mui/material";
import { IntegrationMsTeamsCard } from "./IntegrationTypes/IntegrationMsTeams";
import { IntegrationMsEntraIdCard } from "./IntegrationTypes/IntegrationMsEntraId";
import { IntegrationRapid7Card } from "./IntegrationTypes/IntegrationRapid7";
import { IntegrationElasticCard } from "./IntegrationTypes/IntegrationElastic";
import { IntegrationQRadarCard } from "./IntegrationTypes/IntegrationQradar";

const queryIntegration = graphql(`
    query Integration {
        integration {
            id
            isEnabled
            type
        }
    }
`);

export const IntegrationViewer = () => {

    const { data: integrations } = useQuery(
        {
            queryKey: ["integration"],
            queryFn: async ({ signal }) => {
                const client = createGraphQLClient(signal);
                const { integration } = await client.request(queryIntegration);
                return integration
            },
        }
    )

    let typesActivationStatus: Record<string, boolean> = {};
    if (integrations) {
        for (const integration of integrations) {
            if (integration.isEnabled === true) {
                typesActivationStatus[integration.type] = true;
            }
        }
    }
    for (const key of Object.values(IntegrationTypes)) {
        if ((key in typesActivationStatus) === false) {
            typesActivationStatus[key] = false;
        }
    }

    return (
        <div>
            <Typography variant="h6" gutterBottom style={{ marginBottom: "20px" }}>
                Data Retention
            </Typography>
            <IntegrationCard integrationName='Data Retention Policy' helpText='Configure the data retention policy of the Prompt Security platform' helpFile={null} isEnabled={typesActivationStatus[IntegrationTypes.Opensearch]} imgFile="/opensearch.png">
                <IntegrationOpensearchCard inputIsEnabled={typesActivationStatus[IntegrationTypes.Opensearch]} />
            </IntegrationCard>
            <Typography variant="h6" gutterBottom style={{ marginBottom: "20px", marginTop: "40px" }}>
                Identity Platforms
            </Typography>
            <IntegrationCard integrationName='Microsoft Entra ID' helpText='Allows the browser extension to get users and groups from Entra' helpFile='/PromptSecurityEntraIdIntegration.pdf' isEnabled={typesActivationStatus[IntegrationTypes.Entra]} imgFile="/entra.png">
                <IntegrationMsEntraIdCard inputIsEnabled={typesActivationStatus[IntegrationTypes.Entra]} />
            </IntegrationCard>
            <IntegrationCard integrationName='Okta' helpText='Allows the browser extension to get users and groups from Okta' helpFile='/PromptSecurityOktaIntegration.pdf' isEnabled={typesActivationStatus[IntegrationTypes.Okta]} imgFile="/okta.svg">
                <IntegrationOktaCard inputIsEnabled={typesActivationStatus[IntegrationTypes.Okta]} />
            </IntegrationCard>
            <Typography variant="h6" gutterBottom style={{ marginBottom: "20px", marginTop: "40px" }}>
                Communication Platforms
            </Typography>
            <IntegrationCard integrationName='Microsoft Teams' helpText='Allows sending prompt-security incidents to Teams' helpFile={null} isEnabled={null} imgFile="/teams.png">
                <IntegrationMsTeamsCard />
            </IntegrationCard>
            <IntegrationCard integrationName='Slack App' helpText='Download Prompt-Security Slack App' helpFile='/PromptSecuritySlackApp.pdf' isEnabled={null} imgFile="/slack.png">
                <IntegrationSlackAppCard />
            </IntegrationCard>
            <IntegrationCard integrationName='Slack Webhook' helpText='Allows sending prompt-security logs to a slack channel' helpFile={'/PromptSecuritySlackWebhookIntegration.pdf'} isEnabled={typesActivationStatus[IntegrationTypes.Slack]} imgFile="/slack.png">
                <IntegrationSlackCard inputIsEnabled={typesActivationStatus[IntegrationTypes.Slack]} />
            </IntegrationCard>
            <Typography variant="h6" gutterBottom style={{ marginBottom: "20px", marginTop: "40px" }}>
                Log Management Platforms
            </Typography>
            <IntegrationCard integrationName='Elastic' helpText='Allows sending prompt-security logs to Elastic' helpFile={null} isEnabled={null} imgFile="/elastic.svg">
                <IntegrationElasticCard />
            </IntegrationCard>
            <IntegrationCard integrationName='Logz.io' helpText='Allows sending prompt-security logs to Logz.io' helpFile={null} isEnabled={typesActivationStatus[IntegrationTypes.Logzio]} imgFile="/logz.io.png">
                <IntegrationLogzIoCard inputIsEnabled={typesActivationStatus[IntegrationTypes.Logzio]} />
            </IntegrationCard>
            <IntegrationCard integrationName='Panther' helpText='Allows sending prompt-security logs to Panther' helpFile={null} isEnabled={typesActivationStatus[IntegrationTypes.Panther]} imgFile="/panther.png">
                <IntegrationPantherCard inputIsEnabled={typesActivationStatus[IntegrationTypes.Panther]} />
            </IntegrationCard>
            <IntegrationCard integrationName='QRadar' helpText='Allows sending prompt-security logs to QRadar' helpFile={null} isEnabled={null} imgFile="/qradar.jpg">
                <IntegrationQRadarCard />
            </IntegrationCard>
            <IntegrationCard integrationName='Rapid7' helpText='Allows sending prompt-security logs to Rapid7' helpFile={null} isEnabled={null} imgFile="/rapid7.png">
                <IntegrationRapid7Card />
            </IntegrationCard>
            <IntegrationCard integrationName='S3 Bucket' helpText='Allows sending prompt-security logs to a S3' helpFile='/PromptSecurityEventLogsExportToS3.pdf' isEnabled={typesActivationStatus[IntegrationTypes.S3]} imgFile="/s3.svg">
                <IntegrationS3Card inputIsEnabled={typesActivationStatus[IntegrationTypes.S3]} />
            </IntegrationCard>
            <IntegrationCard integrationName='Splunk' helpText='Allows sending prompt-security logs to Splunk' helpFile='/PromptSecuritySplunkIntegration.pdf' isEnabled={typesActivationStatus[IntegrationTypes.Splunk]} imgFile="/splunk.svg">
                <IntegrationSplunkCard inputIsEnabled={typesActivationStatus[IntegrationTypes.Splunk]} />
            </IntegrationCard>
        </div>
    );
};
