/** @jsxImportSource @emotion/react */
import { RadioGroup, Step, StepLabel, Stepper, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Icon, PSButton, PSCheckboxCard, PSDialog, PSImageBox, PSRadioCard, PSStepConnector, PSStepIconComponent, Text } from '../../ui-kit';
import { css } from '@emotion/react';
import { GenAiMgmtOnboardingStyle } from './GenAiMgmtOnboarding.css';
import { useQuery } from '@tanstack/react-query';
import { SecurityApproach } from '../../gql/generated/graphql';

type IProps = {};

type TCustomApp = {
    id: string;
    name: string;
    url: string;
}

const steps = [
    {
        title: 'Security Approach',
        secondary: 'Block all vs. Approve all apps'
    },
    {
        title: 'Exceptions',
        secondary: 'For specific apps'
    },
    {
        title: 'Summary',
        secondary: 'Review and confirm'
    }
];

const GenAiMgmtOnboardingDemo: React.FC<IProps> = (props) => {
    const { } = props;

    const [activeStep, setActiveStep] = useState(0);
    const [securityApproach, setSecurityApproach] = useState<SecurityApproach | null>(null);
    const [selectedAppsIds, setSelectedAppsIds] = useState<Record<string, boolean>>({});
    const [customApps, setCustomApps] = useState<TCustomApp[]>([]);

    const [customAppName, setCustomAppName] = useState('');
    const [customAppUrl, setCustomAppUrl] = useState('');
    const [isAddCustomAppDialogOpen, setIsAddCustomAppDialogOpen] = useState(false);

    const handleNext = () => {
        if (activeStep === steps.length) return;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) return;
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const addCustomApp = (name: TCustomApp['name'], url: TCustomApp['url']) => {
        const newApp: TCustomApp = {
            name,
            url,
            id: Math.random().toString(36).substring(7)
        }
        setCustomApps(prev => [...prev, newApp]);
    }

    const { data, status } = useQuery({
        queryKey: ['getApplications'],
        queryFn: async () => {
            await new Promise(resolve => setTimeout(resolve, 2000));
            const response = await fetch('https://65c3952739055e7482c13158.mockapi.io/api/Applications');
            const responseJson = await response.json();
            setSelectedAppsIds(responseJson.reduce((acc: Record<string, boolean>, item: any) => {
                acc[item.id] = false;
                return acc;
            }, {}));
            return responseJson;
        },
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity
    })

    useEffect(() => {
        setSelectedAppsIds(prev => Object.keys(prev).reduce((acc: Record<string, boolean>, key) => {
            acc[key] = securityApproach === SecurityApproach.InspectAll;
            return acc;
        }, {}))
    }, [securityApproach, status])

    const selectedAppsFiltered = Object.keys(selectedAppsIds).filter(key => selectedAppsIds[key]);

    return (
        <div css={GenAiMgmtOnboardingStyle.self}>
            <Text
                variant='bold'
                textCss={css`
                    position: absolute;
                    left: -100px;
                    top: 32px;
                    transform: rotate(-45deg);
                    background: var(--color-red);
                    z-index: 10;
                    overflow: visible;
                    padding: 2px 100px;
                    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
                `}
                color='white'>
                DEMO
            </Text>
            <Stepper css={GenAiMgmtOnboardingStyle.stepper} activeStep={activeStep} connector={<PSStepConnector />}>
                {steps.map((item, index) => {
                    const stepProps: { completed?: boolean } = {};
                    return (
                        <Step key={item.title} {...stepProps}>
                            <StepLabel StepIconComponent={props => <PSStepIconComponent {...props} index={index + 1} />}>
                                <Text variant='bold'>{item.title}</Text>
                                <Text variant='small'>{item.secondary}</Text>
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {activeStep === 0 && (
                <div css={[GenAiMgmtOnboardingStyle.stepContainer, GenAiMgmtOnboardingStyle.contentContainer]}>
                    <Text variant="header2">Choose your security approach for dealing with genAI apps</Text>
                    <Text textCss={css`margin-top: 15px;`}>Would you prefer to <b>block all</b> and approve selectively, or <b>allow all</b> and block only risky applications?</Text>
                    <Text>Note that <b>you will be able to change these settings any time you want.</b></Text>

                    <RadioGroup
                        row
                        css={GenAiMgmtOnboardingStyle.securityApproachContainer}
                        onChange={(_, value) => setSecurityApproach(value as SecurityApproach)}
                        value={securityApproach}
                    >
                        <PSRadioCard
                            value={SecurityApproach.BlockAll}
                            title='Block All GenAI apps'
                            iconName='PSBlockIcon'
                            selectedBorderColor='red'
                            description='Except a few verified apps you want to approve'
                        />
                        <PSRadioCard
                            value={SecurityApproach.InspectAll}
                            title='Approve All GenAI apps'
                            iconName='PSDoneAllIcon'
                            selectedBorderColor='green'
                            description='Except a few risky apps you want to block'
                        />
                    </RadioGroup>
                </div>
            )}

            {activeStep === 1 && (
                <div css={[GenAiMgmtOnboardingStyle.stepContainer, GenAiMgmtOnboardingStyle.contentContainer]}>
                    {securityApproach === SecurityApproach.BlockAll && (
                        <>
                            <Text variant="header2">Choose Verified Applications to Approve</Text>
                            <Text textCss={css`margin-top: 15px;`}>Would you prefer to <b>block all</b> and approve selectively, or <b>allow all</b> and block only risky applications?</Text>
                            <Text>Note that <b>you will be able to change these settings any time you want.</b></Text>
                            <Text textCss={css`margin-top: 10px;`} color='red'>Please be aware that approving an application will automatically apply</Text>
                            <Text variant='bold' color='red'>to all employees using the Prompt Security extension.</Text>
                        </>
                    )}
                    {securityApproach === SecurityApproach.InspectAll && (
                        <>
                            <Text variant="header2">Choose Risky Applications to Block</Text>
                            <Text textCss={css`margin-top: 15px;`}>You can now actively select and approve a few verified genAI apps for use within your organization.</Text>
                            <Text>This gives you control over the specific generative AI applications your organization and its members can use.</Text>
                            <Text textCss={css`margin-top: 10px;`} color='red'>Please be aware that blocking an application will automatically apply</Text>
                            <Text variant='bold' color='red'>to all employees using the Prompt Security extension.</Text>
                        </>
                    )}

                    <div css={css`max-width: 1456px;`}>
                        <div css={css`margin-top: 65px;`}>
                            <PSButton variant='square' iconName='PSAddIcon' onClick={() => setIsAddCustomAppDialogOpen(true)}>Add Application</PSButton>
                        </div>
                        <div css={css`display: flex; justify-content: center; align-items: center; flex-wrap: wrap; gap: 32px; margin-top: 45px;`}>
                            {status === 'pending' && (
                                Array(8).fill(0).map((_, index) => (
                                    <PSCheckboxCard isLoading key={index} />
                                ))
                            )}
                            {status === 'success' &&
                                <React.Fragment>
                                    {customApps.map((item: TCustomApp) => (
                                        <PSCheckboxCard
                                            key={item.id}
                                            checked={true}
                                            value={item.id}
                                            selectedBorderColor={securityApproach === SecurityApproach.BlockAll ? 'green' : 'red'}
                                            title={item.name}
                                            url={item.url}
                                        />
                                    ))}
                                    {data.map((item: any) => (
                                        <PSCheckboxCard
                                            checked={selectedAppsIds[item.id]}
                                            onChange={(_, checked) => setSelectedAppsIds(prev => ({ ...prev, [item.id]: checked }))}
                                            value={item.id}
                                            selectedBorderColor={securityApproach === SecurityApproach.BlockAll ? 'green' : 'red'}
                                            key={item.id}
                                            iconUrl={item.icon}
                                            title={item.name}
                                            url={item.url}
                                        />
                                    ))}
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            )}

            {(activeStep === 2 || activeStep === steps.length) && (
                <div css={[GenAiMgmtOnboardingStyle.contentContainer]}>

                    {activeStep === steps.length && (
                        <div css={css`margin-top: 50px; margin-inline: auto; width: min(480px, 100%);`}>
                            <Text variant='header'>You’re all set up!</Text>
                            <Text textCss={css`margin-top: 5px;`}>Data was successfully saved.</Text>
                            <Text color='black-70' textCss={css`margin-top: 20px;`} variant='small'>You will be redirected to the extension management page in 3 seconds.</Text>
                        </div>
                    )}

                    <div css={GenAiMgmtOnboardingStyle.summaryContainer}>
                        <Text color='blue' textCss={GenAiMgmtOnboardingStyle.onboardingSummaryText}>Onboarding Summary</Text>
                        <div css={[GenAiMgmtOnboardingStyle.headerSummaryTextContainer, customApps.length === 0 && selectedAppsFiltered.length === 0 ? GenAiMgmtOnboardingStyle.summaryHeaderLine : null]}>
                            <Icon iconName={securityApproach === SecurityApproach.BlockAll ? "PSBlockIcon" : "PSDoneAllIcon"} iconSize={40} color="black-50" />
                            <Text variant='header'>
                                {securityApproach === SecurityApproach.BlockAll ? 'Block' : 'Approve'} All GenAI apps
                            </Text>
                        </div>

                        {customApps.length === 0 && selectedAppsFiltered.length === 0 && (
                            <div css={GenAiMgmtOnboardingStyle.summaryAppsContainer(securityApproach === SecurityApproach.BlockAll ? 'green-5' : 'red-5')}>
                                <Text>
                                    {securityApproach === SecurityApproach.BlockAll
                                        ? activeStep === steps.length ? 'You’ve chosen to block all genAI applications.' :
                                            <>
                                                You’ve chosen to block all genAI applications. If you wish to approve specific verified genAI applications for internal use, please return to the
                                                <Text textCss={css`margin-left: 5px;`} inline onClick={handleBack}>previous page.</Text>
                                            </>
                                        : activeStep === steps.length ? 'No risky applications have been selected for blocking.' :
                                            <>
                                                No risky applications have been selected for blocking. If you wish to block any risky applications, please return to the
                                                <Text textCss={css`margin-left: 5px;`} inline onClick={handleBack}>previous page.</Text>
                                            </>
                                    }
                                </Text>
                            </div>
                        )}


                        {(customApps.length > 0 || selectedAppsFiltered.length > 0) && (
                            <>
                                <Text textCss={GenAiMgmtOnboardingStyle.selectedText}>
                                    Except the following <b>{selectedAppsFiltered.length + customApps.length} {securityApproach === SecurityApproach.BlockAll ? 'verified' : 'risky'} applications.</b>
                                </Text>
                                <div css={GenAiMgmtOnboardingStyle.summaryAppsContainer(securityApproach === SecurityApproach.BlockAll ? 'green-5' : 'red-5')}>
                                    <Text variant='bold' color={securityApproach === SecurityApproach.BlockAll ? 'green' : 'red'}>
                                        {securityApproach === SecurityApproach.BlockAll ? 'Verified applications to approve:' : 'Risky applications to block:'}
                                    </Text>
                                    {customApps.map(app => (
                                        <div css={GenAiMgmtOnboardingStyle.itemSummary} key={app.id}>
                                            <PSImageBox size={50} borderColor={securityApproach === SecurityApproach.BlockAll ? 'green' : 'red'} />
                                            <Text variant='bold' ellipsis>{app.name}</Text>
                                        </div>
                                    ))}
                                    {selectedAppsFiltered.map(appId => {
                                        const app = data.find((item: any) => item.id === appId);
                                        return (
                                            <div css={GenAiMgmtOnboardingStyle.itemSummary} key={appId}>
                                                <PSImageBox size={50} imageUrl={app.icon} borderColor={securityApproach === SecurityApproach.BlockAll ? 'green' : 'red'} />
                                                <Text variant='bold' ellipsis>{app.name}</Text>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}

            <div css={GenAiMgmtOnboardingStyle.stepperButtons}>
                {(activeStep !== 0 && activeStep !== steps.length) && <PSButton onClick={handleBack} variant='flat'>Back</PSButton>}
                {activeStep !== steps.length && <PSButton
                    disabled={!securityApproach} onClick={handleNext} variant='filled'>
                    {activeStep !== steps.length - 1 ? 'Next' : 'Click to Confirm'}
                </PSButton>}
            </div>
            <PSDialog open={isAddCustomAppDialogOpen} onClose={() => {
                setCustomAppName('');
                setCustomAppUrl('');
                setIsAddCustomAppDialogOpen(false);
            }} action={() => addCustomApp(customAppName, customAppUrl)} title='Add Application' actionButtonText='Add'>
                {/* //TODO: extract this to different component */}
                <Text>Add custom genAI application.</Text>
                <div>
                    {/* //TODO Validate URL is valid */}
                    <Text>URL</Text>
                    <TextField value={customAppUrl} onChange={(e) => setCustomAppUrl(e.target.value)} variant='outlined' size='small' placeholder='Url' />
                </div>
                <div>
                    <Text>Name</Text>
                    <TextField value={customAppName} onChange={(e) => setCustomAppName(e.target.value)} variant='outlined' size='small' placeholder='Application Name' />
                </div>
            </PSDialog>
        </div>
    )
}

export default GenAiMgmtOnboardingDemo;