/** @jsxImportSource @emotion/react */
import React, {ReactNode} from "react";
import { KeyValueRowStyle } from "./KeyValueRow.css";
import {Text} from "../../../index";

interface IProps {
    label: string;
    value: string | ReactNode;
}

const KeyValueRow: React.FC<IProps> = (props) => {
    const { label, value } = props;

    return (
        <div key={label} css={KeyValueRowStyle.self}>
            <Text variant='monoSmall' textCss={KeyValueRowStyle.keyMinWidth}>{label}</Text>
            <Text textCss={KeyValueRowStyle.value}>{value}</Text>
        </div>
    );
};

export default KeyValueRow;