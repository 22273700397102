/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const EndAdornmentStyle = {
    self: css`
        display: flex;
        align-items: center;
        gap: 5px;
  `,
    clearButton: css`
        display: none;
    `
}