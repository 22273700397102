/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const ShadowAIAppUsageStyles = {
    self: css``,
    grid: css`
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 20px;
        row-gap: 20px;
    `,
    appContainer: css`
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 10px;
    `,
    appName: (maxWidth: string) => css`
        display: flex;
        align-items: center;
        justify-content: start;
        height: 100%;
        max-width: ${maxWidth};
    `,
    newBadge: css`
        align-self: start;
    `,
};
