/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PSNavigationBuilderStyle = {
    self: css`
    `,

    navCategory: (isCollapsed?: boolean) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: var(--spacing-lg);
        margin-bottom: var(--spacing-sm);
        margin-right: ${isCollapsed ? `var(--spacing-md)` : 'var(--spacing-lg)'};
        cursor: pointer;
        user-select: none;
    `,

    hr: (isCollapsed?: boolean) => css`
        margin-inline: ${isCollapsed ? `var(--spacing-md)` : 'var(--spacing-lg)'};
        margin-block: var(--spacing-md);
        border-top: 1px solid var(--color-white-15);
    `,

    navLinkButton: css`
        position: relative;
        user-select: none;
        display: flex;
        align-items: center;
        gap: var(--spacing-md);
        padding: var(--spacing-xs) var(--spacing-lg);
        padding-right: var(--spacing-xs);
        height: 38px;

        border: 1px solid var(--color-transparent);
        cursor: pointer;

        & .active-indicator {
            background: var(--color-transparent);
            width: 4px;
            height: 100%;
            left: 0;
            position: absolute;
        }
        
        & .text { color: var(--color-white); white-space: nowrap; font-size: 16px; font-weight: 500; }
        & .icon { fill: var(--color-white); }

        &:focus-visible {
            border-color: var(--color-purple-50);
        }

        &:hover {
            background: var(--color-white-15);
        }

        &:active {
            background: var(--color-white-10);

            & .active-indicator {
                background: var(--color-white-15);
            }
        }

        &[aria-current="page"], &.active {
            border-color: var(--color-transparent);
            background: var(--color-white-15);

            & .text {
                font-weight: 700;
            }

            & .active-indicator {
                background: var(--color-purple-50);
            }
        }
    `
}