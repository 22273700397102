/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { graphql } from '../gql';
import { useGraphQL } from '../hooks';
import { PSLoadingScreen } from '../ui-kit';

const dataRetentionDate = graphql(`
    query QueryDataRetentionDate {
        queryOpensearchDataRetentionDate {
            date
        }
    }
`);

type IProps = {
    children: React.ReactNode;
};

const InitialLoader: React.FC<IProps> = (props) => {
    const { children } = props;

    const retentionDate = useGraphQL({
        document: dataRetentionDate,
        onSuccess(data) {
            localStorage.setItem('dataRetention', data.queryOpensearchDataRetentionDate?.date);
        },
    });

    if (!retentionDate.data) return <PSLoadingScreen />;

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

export default InitialLoader;