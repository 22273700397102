import * as cookie from 'cookie';

const cookieOptions = {
    path: '/'
};

export function setCookie(name: string, value: any) {
    const stringValue = typeof value === 'object' ? JSON.stringify(value) : String(value);
    document.cookie = cookie.serialize(name, stringValue, cookieOptions);
}

export function deleteCookie(name: any) {
    document.cookie = cookie.serialize(name, "", { expires: new Date(0), path: '/' });
}

export function getCookie(name: any) {
    const parsedCookies = cookie.parse(document.cookie);
    return parsedCookies[name];
}