/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Tab, TabProps, Skeleton } from "@mui/material";
import { TextStyle } from "../Text/Text.css";
import Text from "../Text/Text";
import { css } from "@emotion/react";

const PSTab = styled(({ isLoading, number, ...props }: TabProps & { isLoading?: boolean; number?: number; to?: string; }) => (
    <>
        {isLoading && <Skeleton
            className="loading"
            variant="text"
            width={typeof props.label === "string" ? props.label.length * 7.5 : 100}
            height={30}
            sx={{
                margin: '12px 15px'
            }}
        />}
        {!isLoading &&
            <Tab disableRipple {...props} label={
                <div css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
            `}>
                    {number !== undefined && <Text className="number-text" variant="bold" color="black-50">{number}</Text>}
                    <div className='tab-label'>
                        <Text variant="small" className='tab-label-text'>
                                {props.label}
                        </Text>
                    </div>
                </div>
            } />
        }
    </>
))`
    
    padding: 0 20px;
    min-height: 50px;
    min-width: fit-content;
    text-transform: none;
    opacity: 1;
    ${TextStyle.text}
    color: var(--color-black);
    
    &:hover {
        color: var(--color-black);
        background: var(--color-black-10);
        .number-text {
            color: var(--color-black);
        }
    };
    
   .tab-label {
       position: relative;
       display: flex;
       align-items: center;

       height: 100%;
       &::before  {
           content: "";
           width: 100%;
           background: var(--color-black);
           position: absolute;
           left: 0;
           bottom: 0;
       }
   }

    &:active {
        color: var(--color-black);
        background: var(--color-white);
        ${TextStyle.bold};
        .number-text {
            color: var(--color-black);
        }
        .tab-label {
            &::before  {
                background: var(--color-blue-25);
                height: 4px;
            }
        }
    }

    &.Mui-selected {
        border: 0;
        color: var(--color-black);
        .number-text {
            color: var(--color-black);
        } 
        .tab-label {
            .tab-label-text {
                font-weight: 700;
            }
            &::before  {
                height: 4px;
            }

        }
        &:hover {
            background: var(--color-transparent);
            cursor: default;
        }
    }

    &.Mui-disabled.Mui-selected .tab-label {
        &::before  {
            background: var(--color-black-50);
        }
    }
    
    &.Mui-disabled {
        color: var(--color-black-50);
    }
`;

export default PSTab;