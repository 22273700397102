/** @jsxImportSource @emotion/react */
import { TextField } from '@mui/material';
import React, { forwardRef, LegacyRef, useMemo } from 'react';
import { Icon, Text } from '../../../ui-kit';
import { AllProtectionKeys } from '../Protections';
import { PromptResponseDetails, ProtectResponse } from './protect';
import { ScanResultsStyle } from './ScanResults.css';

type IProps = {
    protectResponse: ProtectResponse;
    isAdvancedMode: boolean;
    startRequestTime: number;
};

const ScanResults: React.ForwardRefExoticComponent<IProps & React.RefAttributes<HTMLDivElement>> = forwardRef<HTMLDivElement, IProps>((props, ref) => {
    const { protectResponse: logEntry, isAdvancedMode, startRequestTime } = props;

    const analysis = useMemo(() => calculateAnalysis(logEntry, isAdvancedMode, startRequestTime), []);
    const logEntryKey = useMemo(() => {
        if (logEntry.prompt) return 'prompt'
        if (logEntry.response) return 'response'
    }, [logEntry.prompt, logEntry.response]) as 'prompt' | 'response';

    const isValid = logEntry[logEntryKey].passed

    return (
        <div css={ScanResultsStyle.self(isValid)} ref={ref}>
            <div css={ScanResultsStyle.header}>
                <Text inline variant="bold">Results - </Text>
                <Text inline variant="bold" color={isValid ? 'green' : 'red'}>{isValid ? 'Valid' : 'Invalid'}</Text>
            </div>

            {analysis.length > 0 && <table css={ScanResultsStyle.resultTable}>
                <thead>
                    <tr>
                        <th>
                            <Text variant='small' color='black-70'>Protection</Text>
                        </th>
                        <th>
                            <Text variant='small' color='black-70'>Valid</Text>
                        </th>
                        <th>
                            <Text variant='small' color='black-70'>Risk Score</Text>
                        </th>
                        <th>
                            <Text variant='small' color='black-70'>Latency (ms)</Text>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {analysis.map(row => (
                        <tr key={row.protection}>
                            <td><Text>{row.protection}</Text></td>
                            <td>
                                <div css={ScanResultsStyle.isProtectionValidBox(row.isValid)}>
                                    <Icon iconSize={13} iconName={row.isValid ? 'PSDoneIcon' : 'PSCancelIcon'} color={row.isValid ? 'green' : 'red'} />
                                </div>
                            </td>
                            <td><Text>{row.riskScore}</Text></td>
                            <td><Text>{row.latencyMilliseconds}</Text></td>
                        </tr>
                    ))}
                </tbody>
            </table>}

            {analysis.length === 0 && <Text>No protections are enabled</Text>}

            {logEntry[logEntryKey].modified_text && <div css={ScanResultsStyle.sanitizedPromptContainer}>
                <Text pre variant='small' color='black-70'>Sanitized Prompt</Text>
                <TextField css={ScanResultsStyle.sanitizedPromptTextField} fullWidth multiline disabled value={logEntry[logEntryKey].modified_text} />
            </div>}

        </div >
    )
})

export default ScanResults;



const isProtectionAnalysisValid = (protection: typeof AllProtectionKeys[number], resultsScores: PromptResponseDetails['scores'], violations: PromptResponseDetails['violations']): boolean => {
    if (violations.includes(protection)) {
        return false;
    }
    const result = resultsScores[protection];
    if (result && result.score) {
        const score = parseFloat(result.score);
        let threshold = 1.0;
        if (result.threshold) {
            threshold = parseFloat(result.threshold);
        }
        return score < threshold;
    }
    return true;
}

type Analysis = {
    protection: string;
    isValid: boolean;
    riskScore: string;
    latencyMilliseconds: number;
}

const calculateAnalysis = (logEntry: ProtectResponse, isAdvancedMode: boolean, startTime: number): Array<Analysis> => {
    const { prompt, response } = logEntry;

    if (!prompt && !response) return [];

    let latencies = prompt?.latency || response?.latency || {};
    let resultsScore = prompt?.scores || response?.scores || {};
    let violations = prompt?.violations || response?.violations || [];

    let latency = latencies.Total || 0;

    if (latency === 0) {
        latency = Math.floor((Date.now() - startTime) * 1000);
    }

    delete latencies['Total'];

    if (!isAdvancedMode) {
        latency = Math.max(
            latencies["Prompt Injection Similarity"] || 0,
            latencies["Prompt Injection LLM Judger"] || 0,
            latencies["Prompt Injection Classifier"] || 0
        );

        const risk = Math.max(
            parseFloat(resultsScore["Prompt Injection Similarity"]?.score || '0'),
            parseFloat(resultsScore["Prompt Injection LLM Judger"]?.score || '0'),
            parseFloat(resultsScore["Prompt Injection Classifier"]?.score || '0')
        )

        if (latency > 0) {
            latencies["Prompt Injection Engine"] = latency;
        }

        if (risk > 0) {
            resultsScore["Prompt Injection Engine"] = { score: risk.toString() };
        }

        delete latencies["Prompt Injection Similarity"];
        delete latencies["Prompt Injection LLM Judger"];
        delete latencies["Prompt Injection Classifier"];
    }

    return Object.keys(latencies).map(protectionKey => {
        return {
            protection: protectionKey,
            isValid: isProtectionAnalysisValid(protectionKey as typeof AllProtectionKeys[number], resultsScore, violations),
            riskScore: protectionKey in resultsScore ? parseFloat(resultsScore[protectionKey as typeof AllProtectionKeys[number]].score).toFixed(2) : "0",
            latencyMilliseconds: latencies[protectionKey as typeof AllProtectionKeys[number]] || 0,
        }
    });
}