/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { PSBox, PSButton, PSCopyText, PSFormControlLabel, PSTab, PSTabs, Text } from '../../../../ui-kit';
import { HomegrownApplicationsAPIStyle } from './HomegrownApplicationsAPI.css';
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { useConnectorContext } from '../../../../contexts';
import { PromptSecurityAppSecApiPDF } from '../../../../assets/files';
import { Checkbox } from '@mui/material';
import { censorStringEnd } from '../../../../utils';

const APIS = (domain: string, apiKey: string) => ({
    curl: {
        label: 'cURL',
        code: `curl --location '${domain === "localhost" ? "http" : "https"}://${domain}/api/protect' \\
--header 'APP-ID: ${apiKey}' \\
--header 'Content-Type: application/json' \\
--data '{
    "prompt": "your prompt (optional)", 
    "system_prompt": "your system prompt (optional)", 
    "response": "your response (optional)", 
    "user": "your user (optional)"
}'`,
        language: langs.shell()
    },
    python: {
        label: 'Python',
        code: `from typing import Optional
import httpx
import json
import asyncio
import openai


async def ps_protect_api_async(prompt: str, system_prompt: Optional[str] = None, response: Optional[str] = None, user: Optional[str] = None):
    headers = {
        'APP-ID': '${apiKey}',
        'Content-Type': 'application/json'
    }
    payload = {
        'prompt': prompt,
        'system_prompt': system_prompt,
        'response': response,
        'user': user
    }
    async with httpx.AsyncClient() as client:
        ret = await client.post('${domain === "localhost" ? "http" : "https"}://${domain}/api/protect', headers=headers, json=payload)
        return ret.json()


async def main():
    system_prompt = 'system prompt'
    user_prompt = 'user prompt'

    # Option 1: run in prevention mode

    # Scan user prompt with Prompt Security
    ps_ret = await ps_protect_api_async(user_prompt, system_prompt, None, 'user@domain.com')
    ps_action = ps_ret["result"]["prompt"]["action"]
    if ps_action == "block": # block the prompt and return
        return
    if ps_action == "modify": # replace the prompt with the sanitized one
        user_prompt = ps_ret["result"]["prompt"]["modified_text"]

    # Call LLM
    ret = openai.ChatCompletion.create(
        model = "gpt-4",
        messages = [
            { "role": "system", "content": system_prompt },
            { "role": "user", "content": user_prompt }
        ]
    )
    llm_response = ret['choices'][0]['message']['content']

    # Scan LLM response with Prompt Security
    ps_ret = await ps_protect_api_async(None, system_prompt, llm_response, 'user@domain.com')
    ps_action = ps_ret["result"]["response"]["action"]
    if ps_action == "block": # block the response and return
        return
    if ps_action == "modify": # replace the llm response with the sanitized one
        llm_response = ps_ret["result"]["response"]["modified_text"]


    
    # Option 2: run in detect only mode without adding latency

    asyncio.create_task(ps_protect_api_async(user_prompt, system_prompt, llm_response, 'user@domain.com'))


asyncio.run(main())`,
        language: langs.python()
    },
    javascript: {
        label: 'Typescript/Javascript',
        code: `import axios from 'axios';
const { OpenAIApi } = require("openai");
const openai = new OpenAIApi();


async function psProtectApiAsync(prompt: string, systemPrompt?: string, response?: string, user?: string) {
    const headers = {
        'APP-ID': '${apiKey}',
        'Content-Type': 'application/json'
    };
    const payload = {
        'prompt': prompt,
        'system_prompt': systemPrompt,
        'response': response,
        'user': user
    };

    const res = await axios.post('${domain === "localhost" ? "http" : "https"}://${domain}/api/protect', payload, { headers });
    return res.data;
}


async function main() {
    const systemPrompt = 'system prompt';
    let userPrompt = 'user prompt';

    // Option 1: run in prevention mode

    // Scan user prompt with Prompt Security
    let psRet = await psProtectApiAsync(userPrompt, systemPrompt, undefined, 'user@domain.com');
    let psAction = psRet?.result?.prompt?.action;
    if (psAction == "block") { // block the prompt and return
        return;
    }
    if (psAction == "modify") { // replace the prompt with the sanitized one
        userPrompt = psRet?.result?.prompt?.modified_text;
    }

    // Call LLM
    const ret = await openai.createChatCompletion({
        model: "gpt-4",
        messages: [
            { role: "system", content: systemPrompt },
            { role: "user", content: userPrompt }
        ]
    });
    let llmResponse = ret.data.choices[0].message.content;

    // Scan LLM response with Prompt Security
    psRet = await psProtectApiAsync(undefined, systemPrompt, llmResponse, 'user@domain.com');
    psAction = psRet?.result?.response?.action;
    if (psAction == "block") { // block the response and return
        return; 
    }
    if (psAction == "modify") { // replace the response with the sanitized one
        llmResponse = psRet.result.response.modified_text;
    }
    
    
    // Option 2: run in detect only mode without adding latency

    psProtectApiAsync(userPrompt, systemPrompt, llmResponse, 'user@domain.com');
}


main();`,
        language: langs.typescript()
    },
    csharp: {
        label: '.NET/C#',
        code: `using System;
using System.Net.Http;
using System.Text;
using System.Text.Json;
using System.Threading.Tasks;

class Program
{
    private static readonly HttpClient client = new HttpClient();
    
    static async Task Main(string[] args)
    {
        string systemPrompt = "system prompt";
        string userPrompt = "user prompt";

        // Option 1: Run in prevention mode
        var psRet = await PsProtectApiAsync(userPrompt, systemPrompt, null, "user@domain.com");
        
        if (psRet.HasValue)
        {
            var result = psRet.Value;
            string psAction = result.GetProperty("result").GetProperty("prompt").GetProperty("action").GetString();

            if (psAction == "block")
            {
                return; // block the prompt and return
            }
            if (psAction == "modify")
            {
                userPrompt = result.GetProperty("result").GetProperty("prompt").GetProperty("modified_text").GetString();
            }
        }

        // Call OpenAI
        var llmResponse = await CallOpenAiAsync(systemPrompt, userPrompt);

        // Scan LLM response with Prompt Security
        psRet = await PsProtectApiAsync(null, systemPrompt, llmResponse, "user@domain.com");

        if (psRet.HasValue)
        {
            var result = psRet.Value;
            string psAction = result.GetProperty("result").GetProperty("response").GetProperty("action").GetString();

            if (psAction == "block")
            {
                return; // block the response and return
            }
            if (psAction == "modify")
            {
                llmResponse = result.GetProperty("result").GetProperty("response").GetProperty("modified_text").GetString();
            }
        }

        // Option 2: run in detect-only mode without adding latency
        _ = PsProtectApiAsync(userPrompt, systemPrompt, llmResponse, "user@domain.com");
    }

    private static async Task<JsonElement?> PsProtectApiAsync(string prompt, string systemPrompt, string response, string user)
    {
        var requestBody = new
        {
            prompt = prompt,
            system_prompt = systemPrompt,
            response = response,
            user = user
        };
        
        var jsonString = JsonSerializer.Serialize(requestBody);
        var content = new StringContent(jsonString, Encoding.UTF8, "application/json");

        client.DefaultRequestHeaders.Clear();
        client.DefaultRequestHeaders.Add("APP-ID", "${apiKey}");

        var responseMessage = await client.PostAsync("https://${domain}/api/protect", content);
        responseMessage.EnsureSuccessStatusCode();

        var jsonResponse = await responseMessage.Content.ReadAsStringAsync();
        return JsonDocument.Parse(jsonResponse).RootElement;
    }

    private static async Task<string> CallOpenAiAsync(string systemPrompt, string userPrompt)
    {
        var requestBody = new
        {
            model = "gpt-4",
            messages = new[]
            {
                new { role = "system", content = systemPrompt },
                new { role = "user", content = userPrompt }
            }
        };

        var jsonString = JsonSerializer.Serialize(requestBody);
        var content = new StringContent(jsonString, Encoding.UTF8, "application/json");

        client.DefaultRequestHeaders.Clear();
        client.DefaultRequestHeaders.Add("Authorization", "Bearer YOUR_OPENAI_API_KEY");

        var responseMessage = await client.PostAsync("https://api.openai.com/v1/chat/completions", content);
        responseMessage.EnsureSuccessStatusCode();

        var jsonResponse = await responseMessage.Content.ReadAsStringAsync();
        var jsonDocument = JsonDocument.Parse(jsonResponse);
        
        return jsonDocument.RootElement.GetProperty("choices")[0].GetProperty("message").GetProperty("content").GetString();
    }
}`,
        language: langs.csharp()
    }
})

type IProps = {};

const HomegrownApplicationsAPI: React.FC<IProps> = (props) => {
    const { } = props;

    const { connector } = useConnectorContext();
    const connectorIdCensored = censorStringEnd(connector.id, 12);

    const [showApiKey, setShowApiKey] = useState(false);
    const apiKey = showApiKey ? connector.id : connectorIdCensored;

    const [selectedTabValue, setSelectedTabValue] = useState<keyof ReturnType<typeof APIS>>('curl');
    const selectedTab = APIS(window.location.hostname, apiKey)[selectedTabValue];

    const [isCopied, setIsCopied] = useState(false);

    const handleCopyText = () => {
        navigator.clipboard.writeText(selectedTab.code);
        setIsCopied(true);
    }

    useEffect(() => {
        if (!isCopied) return;
        const timeout = setTimeout(() => setIsCopied(false), 2000);
        return () => clearTimeout(timeout);
    }, [isCopied])

    return (
        <PSBox padding={30}>
            <div css={HomegrownApplicationsAPIStyle.titleContainer}>
                <Text variant='header2'>API</Text>
                <Text inline><a href={PromptSecurityAppSecApiPDF} download="Prompt_Security_AppSec_API.pdf"><Text inline onClick={() => { }}>Click here</Text></a> to download the API user guide</Text>
            </div>


            <div css={HomegrownApplicationsAPIStyle.apiKey}>
                <PSFormControlLabel label='Display API Key:' control={
                    <Checkbox
                        checked={showApiKey}
                        onChange={(e) => setShowApiKey(e.target.checked)}
                        color='primary'
                    />
                } />
                <PSCopyText label={apiKey} text={connector.id} />
            </div>


            <PSTabs
                value={selectedTabValue}
                onChange={(_, value) => setSelectedTabValue(value)}
            >
                {Object.entries(APIS(window.location.hostname, apiKey)).map(([key, value]) => (
                    <PSTab key={key} value={key} label={value.label} />
                ))}
            </PSTabs>

            <div css={HomegrownApplicationsAPIStyle.codeViewerContainer}>
                <CodeMirror
                    readOnly
                    value={selectedTab.code}
                    theme={'dark'}
                    extensions={[selectedTab.language]}
                />
                <div css={HomegrownApplicationsAPIStyle.copyButton}>
                    <PSButton variant='square' onClick={handleCopyText}>{isCopied ? 'Copied' : 'Copy'}</PSButton>
                </div>
            </div>
        </PSBox>
    )
}

export default HomegrownApplicationsAPI;
