/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const HomegrownApplicationsStyle = {
    outletContainer: css`
        padding: 20px 25px;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
    `,
}