/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const PercentageBarStyles = {
    self: css`
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
    `,
    bar: (width: number) => css`
        background: var(--color-blue-light);
        height: 8px;
        min-width: 10px;
        width: ${width}%;
        border-radius: 15px;
    `,
};
