import { SENSITIVE_DATA_TYPES } from "./protections_static_values";
import { TConnectorsTypes, TDevelopers, THomeGrownApplications, TEmployees, TProtectionTextType } from "./protections_types";

type TSensitiveDataTypes = keyof typeof SENSITIVE_DATA_TYPES;

export const fixDataEntityDataTypes = <TConnector extends TConnectorsTypes>(
    connector: THomeGrownApplications & TEmployees & TDevelopers
): TConnector => {

    const processDataTypes = (dataKey: TProtectionTextType) => {
        const data = connector[dataKey];
        if (!data || !('Sensitive Data' in data)) return;

        const entityTypes = data['Sensitive Data']!.entity_types;
        const predefinedEntityTypes = [...entityTypes].filter((type) => SENSITIVE_DATA_TYPES[type as TSensitiveDataTypes] !== undefined);
        const customEntityTypes = [...entityTypes].filter((type) => SENSITIVE_DATA_TYPES[type as TSensitiveDataTypes] === undefined);

        data['Sensitive Data']!.entity_types = predefinedEntityTypes as string[];
        data['Sensitive Data']!.custom_entity_types = [...new Set([...customEntityTypes, ...(data['Sensitive Data']!.custom_entity_types || [])])] as string[];
    };

    processDataTypes('prompt');
    processDataTypes('response');

    return connector as TConnector;
};

export const removeTop10FromProtections = <TConnector extends TConnectorsTypes>(
    connector: THomeGrownApplications & TEmployees & TDevelopers
): TConnector => {

    Object.keys(connector.prompt).forEach((key) => {
        delete (connector.prompt as any)[key].top10;
    });

    if ("response" in connector) {
        Object.keys(connector.response).forEach((key) => {
            delete (connector.response as any)[key].top10;
        });   
    }

    return connector as TConnector;
};