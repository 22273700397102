import { createGraphQLClient, graphql } from "../../../../gql";
import { AuditLogFilterValuesFields, FilterInput, InputMaybe, OperatorKey, SearchType } from "../../../../gql/generated/graphql";
import { Entity, TFilterQueryProperties, TSelectedOptionsQueryProperties } from "./types";

const queryAuditLogsFilter = graphql(`
    query auditLogFilterValuesEntity($fields: [AuditLogFilterValuesFields!]!, $filter: AuditLogFilterInput, $size: Int!) {
        auditLogFilterValues(options: {fields: $fields, size: $size, filter: $filter}) {
            filterValues {
                field
                values {
                    count
                    value
                }
            }
        }
    }
`);

type TGeneratedAuditLogFilterValuesFields = keyof typeof AuditLogFilterValuesFields;

export class AuditEntity extends Entity<typeof queryAuditLogsFilter, TGeneratedAuditLogFilterValuesFields> {
    private static readonly FIELDS_MAP: Record<TGeneratedAuditLogFilterValuesFields, string> = {
        User: "user",
        Id: "id",
        Resource: "resource",
    } as const;

    constructor() {
        super('audit', AuditEntity.FIELDS_MAP, queryAuditLogsFilter);
    }

    async filterQuery({ signal, searchField, initialFetchCount, debounceValue, date }: TFilterQueryProperties) {
        const client = createGraphQLClient(signal);

        const variables: {
            fields: AuditLogFilterValuesFields | AuditLogFilterValuesFields[];
            filter?: InputMaybe<FilterInput> | undefined;
            size: number;
        } = {
            fields: [searchField as AuditLogFilterValuesFields],
            size: initialFetchCount,
            filter: {
                key: OperatorKey.And,
                value: [
                    {
                        time: {
                            from: date.dates[0].toISOString(),
                            to: date.dates[1].toISOString(),
                        }
                    },
                ],
            }
        };

        if (debounceValue !== '') {
            variables.filter?.value.push({
                search: {
                    value: debounceValue,
                    fields: [AuditEntity.FIELDS_MAP[searchField as AuditLogFilterValuesFields]],
                    type: SearchType.Contains,
                },
            })
        }

        const { auditLogFilterValues } = await client.request(this.query, variables);
        return auditLogFilterValues.filterValues.flatMap(fv =>
            fv.values.map(v => ({ label: v.value, value: v.value, count: v.count }))
        );
    }

    async selectedOptionsQuery({ signal, selectedIds, searchField, date }: TSelectedOptionsQueryProperties) {
        const client = createGraphQLClient(signal);

        const variables: {
            fields: AuditLogFilterValuesFields | AuditLogFilterValuesFields[];
            filter?: InputMaybe<FilterInput> | undefined;
            size: number;
        } = {
            fields: [searchField as AuditLogFilterValuesFields],
            size: selectedIds.length,
            filter: {
                key: OperatorKey.And,
                value: [
                    {
                        [searchField as AuditLogFilterValuesFields]: selectedIds,
                        time: {
                            from: date.dates[0].toISOString(),
                            to: date.dates[1].toISOString(),
                        }
                    },
                ],
            }
        };

        const { auditLogFilterValues } = await client.request(this.query, variables);
        return auditLogFilterValues.filterValues.flatMap(fv =>
            fv.values.map(v => ({ label: v.value, value: v.value, count: v.count }))
        );
    }
}

export const auditEntity = new AuditEntity();
