/** @jsxImportSource @emotion/react */
import { Checkbox, FormControl, InputLabel, Select } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NoData, PSDialog, PSFormControlLabel, PSInput, PSMenuItem, Text } from '../../../../ui-kit';
import { IProtectionFormProps, TSecretsProtection, REDACT_MODES, SECRET_PLUGINS } from '../Common';
import { SecretsFormStyle } from './SecretsForm.css';

const SecretsForm: React.FC<IProtectionFormProps<TSecretsProtection>> = (props) => {
    const { control, formName, protection } = props;

    const { setValue } = useFormContext();
    const [isSecretDetectorsListModalOpen, setIsSecretDetectorsListModalOpen] = useState(false);
    const [filterType, setFilterType] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');

    const [ignoredRules, setIgnoredRules] = useState(_.without(Object.keys(SECRET_PLUGINS), ...protection.ignored_rules));

    const [tempIgnoredRules, setTempIgnoredRules] = useState<string[]>([]);

    useEffect(() => {
        setIgnoredRules(_.without(Object.keys(SECRET_PLUGINS), ...protection.ignored_rules));
    }, [protection.ignored_rules]);

    useEffect(() => {
        if (isSecretDetectorsListModalOpen) {
            setTempIgnoredRules(ignoredRules as string[]);
        }
    }, [isSecretDetectorsListModalOpen, ignoredRules]);

    const handleDialogClose = useCallback(() => {
        setIsSecretDetectorsListModalOpen(false);
        setTempIgnoredRules(ignoredRules as string[]);
    }, [ignoredRules]);

    const handleDialogAction = useCallback(() => {
        setIgnoredRules(tempIgnoredRules);
        setValue(
            `${formName}.ignored_rules`,
            _.difference(Object.keys(SECRET_PLUGINS), tempIgnoredRules),
            { shouldDirty: true, shouldTouch: true }
        );
        setIsSecretDetectorsListModalOpen(false);
    }, [tempIgnoredRules, formName, setValue]);

    const handleTempSelectAll = useCallback((checked: boolean) => {
        if (checked) {
            setTempIgnoredRules(Object.keys(SECRET_PLUGINS));
        } else {
            setTempIgnoredRules([]);
        }
    }, []);

    const handleTempCheckboxChange = useCallback((key: string, checked: boolean) => {
        setTempIgnoredRules(prev =>
            checked ? [...prev, key] : prev.filter(rule => rule !== key)
        );
    }, []);

    const filteredSecrets = useMemo(() => {
        return Object.entries(SECRET_PLUGINS)
            .filter(([key, value]) => {
                const matchesSearch = value.toLowerCase().includes(searchQuery.toLowerCase());
                const isIgnored = tempIgnoredRules.includes(key);

                switch (filterType) {
                    case 'selected':
                        return isIgnored && matchesSearch;
                    case 'unselected':
                        return !isIgnored && matchesSearch;
                    default:
                        return matchesSearch;
                }
            });
    }, [searchQuery, filterType, tempIgnoredRules]);

    return (
        <div css={SecretsFormStyle.self}>
            <Controller
                name={`${formName}.redact_mode`}
                control={control}
                defaultValue={protection.redact_mode}
                render={({ field }) => (
                    <FormControl>
                        <InputLabel id="label">Redact Mode</InputLabel>
                        <Select
                            {...field}
                            labelId="label"
                            size='small'
                            label="Redact Mode"
                            css={SecretsFormStyle.select}
                        >
                            {Object.entries(REDACT_MODES).map(([key, value]) => {
                                let label: string = value;
                                switch(value) {
                                    case 'None':
                                        label = 'Block'
                                }

                                return (<PSMenuItem key={key} value={key}>{label}</PSMenuItem>)
                            })}
                        </Select>
                    </FormControl>
                )}
            />

            <Text><b>{ignoredRules.length} / {Object.keys(SECRET_PLUGINS).length}</b> Active secret detectors</Text>
            <Text onClick={() => setIsSecretDetectorsListModalOpen(true)}>View</Text>

            {isSecretDetectorsListModalOpen && (
                <PSDialog
                    title='Secret Detector'
                    secondaryTitle={`${tempIgnoredRules.length} / ${Object.keys(SECRET_PLUGINS).length} Active secret detectors for ${formName.includes('prompt') ? 'prompt' : 'response'} inspection`}
                    open={isSecretDetectorsListModalOpen}
                    onClose={handleDialogClose}
                    action={handleDialogAction}
                    actionButtonText='Done'
                >
                    <div css={SecretsFormStyle.dialogControls}>
                        <div css={SecretsFormStyle.controlsLeft}>
                            <PSFormControlLabel
                                control={
                                    <Checkbox
                                        size='small'
                                        checked={tempIgnoredRules.length === Object.keys(SECRET_PLUGINS).length}
                                        onChange={(e) => handleTempSelectAll(e.target.checked)}
                                        indeterminate={tempIgnoredRules.length > 0 && tempIgnoredRules.length < Object.keys(SECRET_PLUGINS).length}
                                    />
                                }
                                label={`Select all (${Object.keys(SECRET_PLUGINS).length})`}
                                sx={{ marginInline: 0 }}
                            />

                            <div className='divider' />

                            <Select
                                size='small'
                                value={filterType}
                                onChange={(e) => setFilterType(e.target.value)}
                                sx={{ minWidth: 135 }}
                            >
                                <PSMenuItem value='all'>All</PSMenuItem>
                                <PSMenuItem value='selected'>Selected</PSMenuItem>
                                <PSMenuItem value='unselected'>Unselected</PSMenuItem>
                            </Select>
                        </div>

                        <PSInput
                            includeClearButton
                            includeRightIcon
                            isDebounce
                            debounceTimeout={150}
                            rightIconName='PSSearchIcon'
                            minWidth={200}
                            maxWidth={200}
                            placeholder='Search'
                            value={searchQuery}
                            onChange={(value) => setSearchQuery(value)}
                        />
                    </div>

                    <div css={SecretsFormStyle.secretsList}>
                        {filteredSecrets.length > 0 ? (
                            filteredSecrets.map(([key, value]) => (
                                <div key={key}>
                                    <PSFormControlLabel
                                        control={
                                            <Checkbox
                                                size='small'
                                                checked={tempIgnoredRules.includes(key)}
                                                onChange={(e) => handleTempCheckboxChange(key, e.target.checked)}
                                            />
                                        }
                                        label={value}
                                        css={SecretsFormStyle.secretItem}
                                    />
                                </div>
                            ))
                        ) : (
                            <div css={SecretsFormStyle.noData}>
                                <NoData header='No secret detectors found' message='try searching for another secret detector' />
                            </div>
                        )}
                    </div>
                </PSDialog>
            )}

        </div>
    )
}

export default SecretsForm;