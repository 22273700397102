import { useEffect, useState } from 'react';
import { IntegrationItem } from '../IntegrationComponents/IntegrationItem';
import { Box } from '@mui/material';
import { graphql, createGraphQLClient } from "../../../gql";
import { useQuery } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LogsTypes, OverrideSplunkIntegrationInput } from '../../../gql/generated/graphql';
import { EditButtons } from '../../../components/EditButtons';
import { StatusRadio } from '../IntegrationComponents/StatusRadio';
import { PopupMessage } from '../../../components/PopupMessage';
import { TextInput } from '../IntegrationComponents/TextInput';
import { TypeRadio, typeDisplay } from '../IntegrationComponents/TypeRadio';

const HIDDEN_TEXT = '*****';

const querySplunkIntegration = graphql(`
    query SplunkIntegration {
        splunkIntegration {
            id
            logsType
            hecEndpointUrl
            hecAuthTokenExists
            hecIndexName
        }
    }
`);

const mutationOverrideSplunkIntegration = graphql(`
    mutation OverrideSplunkIntegration($logsType: LogsTypes, $hecEndpointUrl: String, $hecAuthToken: String, $hecIndexName: String, $isEnabled: Boolean) {
        overrideSplunkIntegration(
            input: {
                logsType: $logsType
                hecEndpointUrl: $hecEndpointUrl
                hecAuthToken: $hecAuthToken,
                hecIndexName: $hecIndexName,
                isEnabled: $isEnabled
            }
        ) {
            id
        }
    }
`);

type IntegrationSplunkCardProps = {
    inputIsEnabled: boolean
};


export const IntegrationSplunkCard = ({ inputIsEnabled }: IntegrationSplunkCardProps) => {
    const { data: splunkIntegration } = useQuery(
        {
            queryKey: ["splunkIntegration"],
            queryFn: async ({ signal }) => {
                const client = createGraphQLClient(signal);
                const { splunkIntegration } = await client.request(querySplunkIntegration);
                return splunkIntegration
            },
        }
    )

    useEffect(() => {
        if (splunkIntegration !== undefined) {
            setHecEndpointUrlToDisplay(splunkIntegration.hecEndpointUrl);
            setHecAuthTokenToDisplay(splunkIntegration?.hecAuthTokenExists ? HIDDEN_TEXT : null);
            setHecIndexNameToDisplay(splunkIntegration.hecIndexName);
            setLogsTypeToDisplay(splunkIntegration.logsType);
            setInputLogsType(splunkIntegration.logsType);
        } else {
            setInputLogsType(LogsTypes.Full);
            setLogsTypeToDisplay(LogsTypes.Full);
            setHecIndexNameToDisplay("main");
        }
    }, [splunkIntegration]);

    const queryClient = useQueryClient();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [isEnabledToDisplay, setIsEnabledToDisplay] = useState<boolean>(inputIsEnabled);
    const [hecEndpointUrlToDisplay, setHecEndpointUrlToDisplay] = useState<string | undefined | null>();
    const [hecAuthTokenToDisplay, setHecAuthTokenToDisplay] = useState<string | undefined | null>();
    const [hecIndexNameToDisplay, setHecIndexNameToDisplay] = useState<string | undefined | null>();
    const [logsTypeToDisplay, setLogsTypeToDisplay] = useState<LogsTypes | undefined | null>();
    const [inputLogsType, setInputLogsType] = useState<LogsTypes | undefined | null>();
    const [openSavePopup, setOpenSavePopup] = useState<boolean>(false);
    const [popupText, setPopupText] = useState<string>('');
    const [popupTitle, setPopupTitle] = useState<string>('');

    const overrideSplunkIntegration = useMutation({
        mutationFn: async (variables: any) => {
            const client = createGraphQLClient();
            await client.request(mutationOverrideSplunkIntegration, variables);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["splunkIntegration"] });
            queryClient.invalidateQueries({ queryKey: ["integration"] });

            setHecAuthTokenToDisplay(splunkIntegration?.hecAuthTokenExists ? HIDDEN_TEXT : null);
            setPopupText('Your changes have been successfully saved');
            setPopupTitle('Splunk Integration Updated');
            setOpenSavePopup(true);
            setEditMode(false);
        },
        onError: (error) => {
            const errorMessage = 'Failed to update Splunk Integration';
            setPopupText(errorMessage);
            setPopupTitle('Splunk Integration Update Failed');
            setOpenSavePopup(true);
        }
    });

    const checkTextValidity = (value: string | undefined | null, varName: string, required: boolean): boolean => {
        if ((value !== null && value !== undefined && value.trim() === '') ||
            (required === true && (value === null || value === undefined))
        ) {
            setPopupText(`${varName} can't be empty`);
            setPopupTitle('Splunk Integration Update Failed');
            setOpenSavePopup(true);
            return false;
        }
        return true;
    }

    const handleSave = () => {
        const isEnabled = (isEnabledToDisplay === true || (isEnabledToDisplay === null && inputIsEnabled === true));
        let overrideData: OverrideSplunkIntegrationInput = {
            isEnabled: isEnabledToDisplay
        }

        if (isEnabled) {
            if (!checkTextValidity(hecEndpointUrlToDisplay, 'HEC Endpoint URL', true) ||
                !checkTextValidity(hecAuthTokenToDisplay, 'HEC Authentication Token', true) ||
                !checkTextValidity(hecIndexNameToDisplay, 'HEC Index Name', true)
            ) {
                return;
            }

            overrideData.hecEndpointUrl = hecEndpointUrlToDisplay;
            if (hecAuthTokenToDisplay && hecAuthTokenToDisplay !== HIDDEN_TEXT) {
                overrideData.hecAuthToken = hecAuthTokenToDisplay;
            }
            overrideData.hecIndexName = hecIndexNameToDisplay;
            overrideData.logsType = logsTypeToDisplay;
        }

        overrideSplunkIntegration.mutate(overrideData);
    };

    const handleCancel = () => {
        setIsEnabledToDisplay(inputIsEnabled);
        setHecEndpointUrlToDisplay(splunkIntegration?.hecEndpointUrl);
        setHecAuthTokenToDisplay(splunkIntegration?.hecAuthTokenExists ? HIDDEN_TEXT : null);
        setHecIndexNameToDisplay(splunkIntegration?.hecIndexName);
        setLogsTypeToDisplay(inputLogsType);
        setEditMode(false);
    };

    const handleEmptyValues = (value: string | undefined | null) => {
        if (value === '') {
            return null;
        }
        return value;
    }

    const handleIntegrationChange = (
        isEnabled: boolean,
        hecEndpointUrl: string | null | undefined,
        hecAuthToken: string | null | undefined,
        hecIndexName: string | null | undefined,
        logsType: LogsTypes | null | undefined,
    ) => {
        hecEndpointUrl = handleEmptyValues(hecEndpointUrl);
        hecAuthToken = handleEmptyValues(hecAuthToken);
        hecIndexName = handleEmptyValues(hecIndexName);

        if (isEnabled !== isEnabledToDisplay) {
            setIsEnabledToDisplay(isEnabled);
        }
        if (hecEndpointUrl !== hecEndpointUrlToDisplay) {
            setHecEndpointUrlToDisplay(hecEndpointUrl);
        }
        if (hecAuthToken !== hecAuthTokenToDisplay) {
            setHecAuthTokenToDisplay(hecAuthToken);
        }
        if (hecIndexName !== hecIndexNameToDisplay) {
            setHecIndexNameToDisplay(hecIndexName);
        }
        if (logsType !== logsTypeToDisplay) {
            setLogsTypeToDisplay(logsType);
        }

        if (isEnabled !== inputIsEnabled ||
            (hecEndpointUrl !== splunkIntegration?.hecEndpointUrl && !(hecEndpointUrl === null && splunkIntegration?.hecEndpointUrl === undefined)) ||
            (hecAuthToken !== HIDDEN_TEXT && !(hecAuthToken === null && splunkIntegration?.hecAuthTokenExists === undefined && splunkIntegration === undefined)) ||
            (hecIndexName !== splunkIntegration?.hecIndexName && !(hecIndexName === null && splunkIntegration?.hecIndexName === undefined)) ||
            logsType !== inputLogsType
        ) {
            setEditMode(true);
        } else {
            setEditMode(false);
        }
    }

    const handleSavePopupClose = () => {
        setOpenSavePopup(false);
    };

    if (!editMode) {
        if (isEnabledToDisplay !== inputIsEnabled) {
            setIsEnabledToDisplay(inputIsEnabled);
        }
    }

    const handleEnableOnChange = (value: boolean) => {
        setIsEnabledToDisplay(value);
        handleIntegrationChange(value, hecEndpointUrlToDisplay, hecAuthTokenToDisplay, hecIndexNameToDisplay, logsTypeToDisplay);
    };

    const handleHecEndpointUrlOnChange = (value: string) => {
        setHecEndpointUrlToDisplay(value);
        handleIntegrationChange(isEnabledToDisplay, value, hecAuthTokenToDisplay, hecIndexNameToDisplay, logsTypeToDisplay);
    };

    const handleHecAuthTokenOnChange = (value: string) => {
        setHecAuthTokenToDisplay(value);
        handleIntegrationChange(isEnabledToDisplay, hecEndpointUrlToDisplay, value, hecIndexNameToDisplay, logsTypeToDisplay);
    };

    const handleHecIndexNameOnChange = (value: string) => {
        setHecIndexNameToDisplay(value);
        handleIntegrationChange(isEnabledToDisplay, hecEndpointUrlToDisplay, hecAuthTokenToDisplay, value, logsTypeToDisplay);
    };

    const handleLogsTypeOnChange = (value: string) => {
        setLogsTypeToDisplay(value as LogsTypes);
        handleIntegrationChange(isEnabledToDisplay, hecEndpointUrlToDisplay, hecAuthTokenToDisplay, hecIndexNameToDisplay, value as LogsTypes);
    };

    const logsTypeDisplayText = (type: string): typeDisplay => {
        if (type === LogsTypes.Full) {
            return { text: 'Send Full Log', helpText: 'The full log will be sent to Splunk' };
        } else {
            return { text: 'Send Only Metadata', helpText: 'Only metadata info will be sent to Splunk' };
        }
    }

    return (
        <div>
            <IntegrationItem>
                <Box>
                    <StatusRadio value={isEnabledToDisplay} handleOnChange={handleEnableOnChange} text='Status' />
                    <TextInput value={hecEndpointUrlToDisplay} title='HEC Endpoint URL' help='HEC Endpoint URL' handleOnChange={handleHecEndpointUrlOnChange} disable={isEnabledToDisplay === false} multiline={false} />
                    <TextInput value={hecAuthTokenToDisplay} title='HEC Authentication Token' help='HEC Authentication Token' handleOnChange={handleHecAuthTokenOnChange} disable={isEnabledToDisplay === false} multiline={false} />
                    <TextInput value={hecIndexNameToDisplay} title='Splunk Index Name' help='Splunk index name to send the logs' handleOnChange={handleHecIndexNameOnChange} disable={isEnabledToDisplay === false} multiline={false} />
                    <TypeRadio value={logsTypeToDisplay} title='Logs Type' help='The type of logs that will be sent' handleOnChange={handleLogsTypeOnChange} disable={isEnabledToDisplay === false} types={Object.values(LogsTypes)} typeDisplayText={logsTypeDisplayText} />
                </Box>
            </IntegrationItem>
            <EditButtons sx={{ mt: 6, ml: 3.5, marginBottom: 1 }} editMode={editMode} handleSaveClick={handleSave} handleCancelClick={handleCancel} />
            <PopupMessage title={popupTitle} text={popupText} open={openSavePopup} handlePopupMessageClose={handleSavePopupClose} />
        </div>
    )
};