/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const PSPopperStyles = {
    self: css``,
    popper: css`
        background: var(--color-black);
        color: var(--color-white);
        padding: 10px;
        border-radius: 5px;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        position: relative;
    `,
    popperSibling: css`
        width: fit-content;
    `,
};
