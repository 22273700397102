import { Variables } from 'graphql-request';
import { type TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useMutation, type UseMutationResult, type UseMutationOptions } from '@tanstack/react-query';
import { createGraphQLClient } from '../gql';

type UseGraphQLMutationOptions<TResult, TVariables extends Variables> = {
    document: TypedDocumentNode<TResult, TVariables>;
} & Omit<UseMutationOptions<TResult, Error, TVariables>, 'mutationFn'>;

export const useGraphQLMutation = <TResult, TVariables extends Variables>(
    { document, ...useMutationOptions }: UseGraphQLMutationOptions<TResult, TVariables>
): UseMutationResult<TResult, Error, TVariables> => {
    return useMutation<TResult, Error, TVariables>({
        mutationFn: async (variables: TVariables) => {
            try {
                const client = createGraphQLClient();
                const response = await client.request<TResult>(document, variables);
                return response;
            }
            catch (error: any) {
                throw new Error(error.response.errors[0].message);
            }
        },
        ...useMutationOptions
    });
};
