/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { createGraphQLClient, graphql } from "../../gql";
import { useQuery } from "@tanstack/react-query";
import {
    AuditAction,
    AuditActionType,
    AuditLogFilterInput,
    AuditLogFilterOperatorValue,
    InputMaybe,
    OperatorKey,
    Pagination,
    SortByFields,
    SortDirection,
    AuditLogFilterValuesFields,
} from "../../gql/generated/graphql";
import {
    DropdownSelect,
    PSInput,
    PSIconButton,
    Text,
    Icon,
    Tooltip,
    AutocompleteSelect,
    PSMuiDateRangePicker,
} from '../../ui-kit';
import { IconButton } from "@mui/material";
import { css } from '@emotion/react';

import { cleanFilter } from "../../utils";
import { dateStoreDefault, DateTypeSelection, TPredefinedDateOrTimeSelection, useDateContext } from "../../contexts";
import { useStateWithParams } from "../../hooks";

import AuditGrid from "./AuditGrid";
import { Controller, useForm } from "react-hook-form";
import AuditLogsDrawer from "./AuditLogsDrawer";
import { AuditPageStyles } from './AuditPage.css';

const actionValueToDisplayedValueMap: Record<typeof AuditAction[keyof typeof AuditAction], string> = {
    APPROVED_CUSTOM_GENAI_APP: 'Approved Custom GenAI App',
    APPROVED_VERIFIED_GENAI_APP: 'Approved Verified GenAI App',
    BLOCKED_CUSTOM_GENAI_APP: 'Blocked Custom GenAI App',
    BLOCKED_VERIFIED_GENAI_APP: 'Blocked Verified GenAI App',
    BYPASS_BLOCKED_DOMAIN: 'Bypass Blocked Domain',
    CREATE_APP: 'Create Connector',
    CREATE_POLICY: 'Create Policy',
    DEL_APP: 'Delete Connector',
    DEL_CUSTOM_GENAI_APP_RULES: 'Delete Custom GenAI App Rules',
    DEL_GENAI_APP_RULES: 'Delete GenAI App Rules',
    DEL_POLICY: 'Delete Policy',
    DEL_RULE: 'Delete Rule',
    DISPLAY_LOG: 'Display Log text',
    GLOBAL_SETTINGS_COACH_AWARENESS_GENAI_APP: 'Global Settings Coach Awareness GenAI App',
    GLOBAL_SETTINGS_GENAI_APP: 'Global Settings GenAI App',
    GLOBAL_SETTINGS_RULE_MODE: 'Global Settings Rule Mode',
    IGNORED_GENAI_APP: 'Ignored GenAI App',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    MODIFIED_GENAI_APP_RULES_BYPASS_BLOCK: 'Modified GenAI App Rules Bypass Block',
    MODIFIED_GENAI_APP_RULES_BYPASS_INSPECTION: 'Modified GenAI App Rules Bypass Inspection',
    MODIFIED_GENAI_APP_RULES_GROUPS: 'Modified GenAI App Rules Groups',
    MODIFIED_GENAI_APP_RULES_INSPECTION_ACTION: 'Modified GenAI App Rules Inspection Action',
    MODIFIED_GENAI_APP_RULES_LOG_ACTION: 'Modified GenAI App Rules Log Action',
    MODIFIED_GENAI_APP_RULES_USERS: 'Modified GenAI App Rules Users',
    MODIFY_APP: 'Modify Connector',
    MODIFY_CONFIGURATION: 'Modify Configuration',
    MODIFY_POLICY: 'Modify Policy',
    ONBOARDING: 'Onboarding',
    OVERRIDE_INTEGRATION: 'Override Integration',
    OVERRIDE_RULE: 'Override Rule',
    PROTECTION_DISABLE_DOMAIN: 'Protection Disable Domain',
    PROTECTION_DISABLE_DOMAIN_APPROVAL: 'Protection Disable Domain Approval',
    PROTECTION_DISABLE_DOMAIN_RESPONSE: 'Protection Disable Domain Response',
    PROTECTION_ENABLE_DOMAIN: 'Protection Enable Domain',
    SECURITY_APPROACH_GENAI_APP: 'Security Approach GenAI App',
    UPDATE_CUSTOM_APP: 'Update Custom Connector'
};

const auditLogsQuery = graphql(`
    query AuditLogsQuery($filter: AuditLogFilterInput, $pagination: PaginationInput!, $sort: SortInput) {
        auditLogs(options: {pagination: $pagination, filter: $filter, sort: $sort}) {
            auditLogs {
                actionStatus
                actionType
                id
                auditAction
                reason
                ip
                logId
                resource
                policyChanges
                time
                user
                isError
            }
            pagination {
                currentPage
                itemsPerPage
                nextPage
                prevPage
                totalCount
                totalPages
            }
        }
    }
`);

type TAuditLogForm = Omit<AuditLogFilterOperatorValue, "search"> & {
    search: string;
}

const AuditPage = () => {
    const { control, watch, getValues: getFiltersValues } = useForm<TAuditLogForm>()
    watch();

    const auditLogFieldsList = ["actionStatus", "actionType", "auditAction", "ip", "logId", "resource", "user"];

    const { date, setDate } = useDateContext();
    const [paramsDate, setParamsDates] = useStateWithParams<[Date, Date]>('dates', date.type === 'absolute' ? date.dates : dateStoreDefault.audit.dates);
    const [paramsDatePeriod, setParamsDatePeriod] = useStateWithParams<TPredefinedDateOrTimeSelection | 'custom'>('period', date.type === 'relative' ? date.period : 'last7days');
    const [paramsDateType, setParamsDateType] = useStateWithParams<keyof typeof DateTypeSelection>('dateType', date.type === 'absolute' ? 'absolute' : 'relative');

    const [currentPage, setCurrentPage] = useStateWithParams<number>('currentPage', 1);
    const [itemsPerPage, setItemsPerPage] = useStateWithParams<number>('itemsPerPage', 50);
    const [isFirstTimeFetched, setIsFirstTimeFetched] = useState(false);

    const [user, setUser] = useStateWithParams<Array<string>>('user', []);
    const [isUserExclude, setIsUserExclude] = useStateWithParams<boolean>('isUserExclude', false);

    const [actionType, setActionType] = useStateWithParams<Array<AuditActionType>>('actionType', []);
    const [isActionTypeExclude, setActionTypeExclude] = useStateWithParams<boolean>('isActionTypeExclude', false);

    const [auditAction, setAuditAction] = useStateWithParams<Array<AuditAction>>('auditAction', []);
    const [isAuditActionExclude, setIsAuditActionExclude] = useStateWithParams<boolean>('isAuditActionExclude', false);

    const [resource, setResource] = useStateWithParams<Array<string>>('resource', []);
    const [isResourceExclude, setIsResourceExclude] = useStateWithParams<boolean>('isResourceExclude', false);

    const [id, setId] = useStateWithParams<Array<string>>('id', []);

    const [search, setSearch] = useStateWithParams<string | null>('search', null);

    const [sortBy, setSortBy] = useStateWithParams<SortByFields>('sortBy', SortByFields.Time);
    const [sortDirection, setSortDirection] = useStateWithParams<SortDirection>('sortDirection', SortDirection.Descending);
    const [rowIndex, setRowIndex] = useState<number | null>(null);

    useEffect(() => {
        if (date.type === 'absolute') {
            setParamsDates(date.dates);
            setParamsDatePeriod(null as any);
        } else if (date.type === 'relative') {
            setParamsDatePeriod(date.period);
            setParamsDates(null as any);
        }

        setParamsDateType(date.type);
    }, [date, setParamsDates, setParamsDatePeriod, setParamsDateType])

    const [pagination, setPagination] = useState<Pagination>({
        currentPage,
        itemsPerPage,
        nextPage: null,
        prevPage: null,
        totalCount: 0,
        totalPages: 0
    });

    useEffect(() => {
        if (isFirstTimeFetched) setCurrentPage(1);
    }, [date, itemsPerPage, sortBy, sortDirection, user, actionType, auditAction, resource, isUserExclude, isAuditActionExclude, isActionTypeExclude, isResourceExclude])

    const { data: auditLogsData, refetch, isError, status, isFetching } = useQuery({
        queryKey: ['auditLogs', currentPage, itemsPerPage, sortBy, sortDirection, JSON.stringify(date.dates), getFiltersValues(), isUserExclude, isAuditActionExclude, isActionTypeExclude, isResourceExclude],
        queryFn: async ({ signal }) => {
            const client = createGraphQLClient(signal);

            const baseFilter: InputMaybe<AuditLogFilterInput> = {
                key: OperatorKey.And,
                value: [
                    {
                        operator: {
                            key: OperatorKey.And,
                            value: [
                                {
                                    time: {
                                        from: date.dates[0].toISOString(),
                                        to: date.dates[1].toISOString()
                                    },
                                }
                            ]
                        }
                    },
                    user.length > 0 ? {
                        operator: {
                            key: isUserExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                user: user
                            }]
                        }
                    } : {},
                    actionType.length > 0 ? {
                        operator: {
                            key: isActionTypeExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                actionType: actionType
                            }]
                        }
                    } : {},
                    auditAction.length > 0 ? {
                        operator: {
                            key: isAuditActionExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                auditAction: auditAction
                            }]
                        }
                    } : {},
                    resource.length > 0 ? {
                        operator: {
                            key: isResourceExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                resource: resource
                            }]
                        }
                    } : {},
                    id.length > 0 ? {
                        operator: {
                            key: OperatorKey.And,
                            value: [{
                                id: id
                            }]
                        }
                    } : {},

                    search !== null ? {
                        operator: {
                            key: OperatorKey.And,
                            value: [{
                                search: {
                                    fields: auditLogFieldsList,
                                    value: search
                                }
                            }]
                        }
                    } : {},

                ]
            }

            try {
                const { auditLogs } = await client.request(auditLogsQuery, {
                    filter: cleanFilter(baseFilter),
                    sort: {
                        by: sortBy,
                        direction: sortDirection
                    },
                    pagination: {
                        pageNumber: currentPage,
                        pageSize: itemsPerPage
                    }
                })

                setPagination(auditLogs.pagination);

                if (!isFirstTimeFetched) {
                    setIsFirstTimeFetched(true);
                }

                return auditLogs.auditLogs;
            } catch (error) {
                throw new Error();
            }
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    const isLoading = status === 'pending' || isFetching;

    const handleRefresh = () => {
        if (paramsDateType === 'absolute') {
            setDate(prev => ({
                ...prev,
                type: 'absolute',
                dates: paramsDate,
                period: paramsDatePeriod as 'custom',
            }));
        } else if (paramsDateType === 'relative') {
            setDate(prev => ({
                ...prev,
                type: 'relative',
                dates: paramsDate,
                period: paramsDatePeriod as TPredefinedDateOrTimeSelection,
            }));
        }

        refetch();
    }

    const Filters = (<div css={AuditPageStyles.filtersContainer}>
        <div css={css`display: flex; align-items: center; gap: 5px; flex-wrap: wrap;`}>
            <Controller
                name="user"
                control={control}
                defaultValue={user}
                render={({ field }) => (
                    <AutocompleteSelect
                        entityType='audit'
                        showExclude
                        inputLabel='User'
                        searchField={AuditLogFilterValuesFields.User}
                        selectedIds={user}
                        onExcludeChange={setIsUserExclude}
                        onChange={(newValue) => {
                            field.onChange(newValue.map(x => x.value));
                            setUser(newValue.map(x => x.value));
                        }}
                    />
                )}
            />

            <Controller
                name="actionType"
                control={control}
                defaultValue={actionType}
                render={({ field }) => (
                    <DropdownSelect
                        multiple
                        showExclude
                        label='Action Type'
                        minWidth={145}
                        value={field.value}
                        options={Object.values(AuditActionType).map((value) => ({ value: value, label: value }))}
                        onExcludeChange={setActionTypeExclude}
                        isExcludeMode={isActionTypeExclude}
                        onChange={(newValue) => {
                            field.onChange(newValue);
                            setActionType(newValue);
                        }}
                    />
                )}
            />

            <Controller
                name="auditAction"
                control={control}
                defaultValue={auditAction}
                render={({ field }) => (
                    <DropdownSelect
                        multiple
                        showExclude
                        label='Action'
                        value={field.value}
                        options={Object.values(AuditAction).map((value) => ({
                            value: value,
                            label: actionValueToDisplayedValueMap[value]
                        })
                        )}
                        maxWidth={245}
                        onExcludeChange={setIsAuditActionExclude}
                        isExcludeMode={isAuditActionExclude}
                        onChange={(newValue) => {
                            field.onChange(newValue);
                            setAuditAction(newValue);
                        }}
                    />
                )}
            />
            <Controller
                name="resource"
                control={control}
                defaultValue={resource}
                render={({ field }) => (
                    <AutocompleteSelect
                        entityType='audit'
                        showExclude
                        inputLabel='Resource'
                        searchField={AuditLogFilterValuesFields.Resource}
                        selectedIds={resource}
                        onExcludeChange={setIsResourceExclude}
                        onChange={(newValue) => {
                            field.onChange(newValue.map(x => x.value));
                            setResource(newValue.map(x => x.value));
                        }}
                    />
                )}
            />

            <Tooltip title='Refresh'>
                <IconButton
                    onClick={handleRefresh}
                    size='medium'
                    disabled={isLoading}
                    css={css`border: 1px solid var(--color-black-50); padding: 9px 15px; border-radius: 25px;`}
                >
                    <Icon
                        iconSize={20}
                        iconName='PSRefreshIcon'
                        color='black-70'
                        css={css`
                                transition: transform 0.5s;
                                transform: ${isLoading ? 'rotate(360deg)' : 'rotate(0deg)'};
                            `}
                    />
                </IconButton>
            </Tooltip>
        </div>

        <div css={css`display: flex; align-items: start; gap: 5px; height: 100%;`}>
            <Controller
                name="search"
                control={control}
                defaultValue={search || ''}
                render={({ field }) => (
                    <PSInput
                        value={search || ''}
                        inputLabel='Search'
                        includeRightIcon
                        rightIconName='PSSearchIcon'
                        isLoading={isLoading}
                        isDebounce
                        debounceTimeout={200}
                        minWidth={175}
                        maxWidth={175}
                        onChange={(newValue) => {
                            field.onChange(newValue);
                            setSearch(newValue as string);
                        }}
                    />
                )}
            />
            <PSMuiDateRangePicker />
        </div>
    </div>)

    const ClearId = (
        <div css={AuditPageStyles.clearIdContainer}>
            <Text variant='text'>ID: {id}</Text>
            <PSIconButton
                variant='circle'
                variantType='secondary'
                onClick={() => setId([])}
                iconName='PSRemoveIcon'
                iconSize={20}
                tooltipLabel='Clear'
            />
        </div>
    )

    return (
        <div css={css`display: flex;
            flex-direction: column;
            height: 100%;`}>
            {id.length > 0 ? ClearId : Filters}
            <AuditGrid
                rowsData={auditLogsData}
                pagination={pagination}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                sortBy={sortBy}
                setSortBy={setSortBy}
                rowIndex={rowIndex}
                setRowIndex={setRowIndex}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                isError={isError}
                isLoading={isLoading}
                refetchTableData={refetch}
            />
            {auditLogsData && rowIndex !== null &&
                <AuditLogsDrawer
                    rowData={auditLogsData[rowIndex]}
                    onClose={() => setRowIndex(null)}
                />}
        </div>)

}

export default AuditPage;
