import React, { useEffect, useState } from 'react';
import { handleCustomButtons } from './CustomButtons';
import { useQuery } from '@tanstack/react-query';
import { graphql, createGraphQLClient } from "../gql";

const queryConfiguration = graphql(`
    query Configuration {
        configuration {
            id
            textRedactionMode
            protectApiQuota {
              remaining
            }
            loggingFrequencyInSeconds
        }
    }   
`);

type KibanaWrapperProps = {
  src: any;
  title: any;
  style: any;
  frameBorder: any;
  allowFullScreen: any;
  dashboardType: any;
  urlChangeHandler?: any;
}

const KibanaWrapper = ({
  src,
  title,
  style,
  frameBorder,
  allowFullScreen,
  dashboardType,
  urlChangeHandler,
}: KibanaWrapperProps) => {
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const [textRedactionMode, setTextRedactionMode] = useState<string | undefined>(undefined);

  useQuery(
    {
      queryKey: ["configuration"],
      queryFn: async ({ signal }) => {
        const client = createGraphQLClient(signal);
        const { configuration } = await client.request(queryConfiguration);
        setTextRedactionMode(configuration.textRedactionMode);
      },
    }
  )

  useEffect(() => {
    const handleLoad = () => {

      setInterval(function () {
        const iframeDocument = iframeRef?.current?.contentDocument
        if (!iframeDocument) return

        const discoverTable = iframeDocument.querySelector('div[data-test-subj="discoverTable"]');
        const dashboardViewport = iframeDocument.getElementById("dashboardViewport")
        if (discoverTable && discoverTable.firstChild && dashboardViewport) {
          (discoverTable.firstChild as any).style.height = `${dashboardViewport.offsetHeight - 17}px`;
          (discoverTable.firstChild as any).style.width = `${dashboardViewport.offsetWidth - 17}px`;
          const dashboardPanel = iframeDocument.querySelector('div[data-test-subj="dashboardPanel"]');
          if (dashboardPanel && dashboardViewport) {
            (dashboardPanel as any).style.height = `${dashboardViewport.offsetHeight - 15}px`;
            (dashboardPanel as any).style.width = `${dashboardViewport.offsetWidth - 15}px`;
          }
        }

        const documentDetailFlyOut = iframeDocument.querySelector('div[data-test-subj="documentDetailFlyOut"]');
        if (documentDetailFlyOut) {
          (documentDetailFlyOut as any).style.width = '70vw';
          const euiFlexItem = documentDetailFlyOut.querySelector('div[class="euiFlexItem"]');
          if (euiFlexItem) {
            (euiFlexItem as any).style.display = 'none';
          }
        }


        const buttons = iframeDocument.querySelectorAll('button[data-test-subj="embeddablePanelToggleMenuIcon"]');
        buttons.forEach(button => {
          if (button) {
            (button as any).style.display = 'none';
          }
        });

        let cells = iframeDocument.querySelectorAll('.euiDataGridHeaderCell__content');
        cells.forEach(cell => {
          if ((cell as any).innerText === "log.text") {
            (cell as any).innerText = "Text";
          }
          else if ((cell as any).innerText === "log.result.scanners.violating_findings") {
            (cell as any).innerText = "Violating Findings";
          }
        });

        if (discoverTable !== null && textRedactionMode !== undefined) {
          handleCustomButtons(iframeDocument, dashboardType, textRedactionMode);
        }

      }, 200) as any

    }

    iframeRef.current?.addEventListener('load', handleLoad)
  }, [urlChangeHandler, dashboardType, textRedactionMode]);

  return (
    <iframe
      ref={iframeRef}
      src={src}
      title={title}
      style={style}
      frameBorder={frameBorder}
      allowFullScreen={allowFullScreen}
    />
  )
}

export default KibanaWrapper;
