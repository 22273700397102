import { GraphQLClient } from 'graphql-request';
import { getUserTokenLocalStorage } from '../utils/fronteggAuth';
import { graphqlEndpoint } from './index';

export const createGraphQLClient = (signal?: AbortSignal) => {
    const userToken = getUserTokenLocalStorage();

    return new GraphQLClient(graphqlEndpoint, {
        signal,
        headers: {
            Authorization: userToken ? `Bearer ${userToken}` : '',
        },
    });
};