/** @jsxImportSource @emotion/react */
import { StepConnector, StepIconProps } from "@mui/material";
import { css } from "@emotion/react";
import Circle from '../Circle/Circle';
import Text from '../Text/Text';
import Icon from "../Icon/Icon";
import styled from "@emotion/styled";

export const PSStepIconComponent = (props: StepIconProps & { index: number }) => {
    const { active, completed, index } = props;

    if (active) {
        return <Circle
            circleCss={css`
                background: var(--color-transparent);
                color: var(--color-black);
                border: 2px solid var(--color-blue);
            `}
            size={42}>
            <Text variant="bold">{index}</Text>
        </Circle>;
    }

    if (completed) {
        return <Circle
            size={42}
            color="blue">
            <Icon iconName="PSCheckmarkIcon" iconSize={24} color="white" />
        </Circle>;
    }

    return <Circle size={42} color="black-10">
        <Text variant="bold">{index}</Text>
    </Circle>;
}

export const PSStepConnector = styled(StepConnector)`
    &.Mui-completed, &.Mui-active {
        .MuiStepConnector-line {
            border-color: var(--color-blue);
        }
    }

    .MuiStepConnector-line {
        border-width: 2px;
    }
`