/** @jsxImportSource @emotion/react */
import {PSSegmentsSwitch, Text} from '../../../../ui-kit';
import {TableSectionStyle} from './TableSection.css';
import FakeTable from "./FakeTable";


const TableOptions = [
    {
        id: 'Applications',
        label: 'Applications',
    },
    {
        id: 'Violations',
        label: 'Violations',
    },
    {
        id: 'Users',
        label: 'Users',
    },
]

const TableSection = () => {
    return (
        <div css={TableSectionStyle.self}>
            <div css={TableSectionStyle.header}>
                <Text variant='bold'>Placeholder text</Text>
                <PSSegmentsSwitch
                    options={TableOptions}
                />
            </div>

            <div css={TableSectionStyle.gridContainer}>
                <FakeTable />
            </div>
        </div>
    )
}

export default TableSection;