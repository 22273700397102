type FetchInterceptor = {
    request?: (url: string, config: RequestInit) => Promise<RequestInit>;
    response?: (response: Response) => Promise<Response>;
    responseError?: (error: any) => Promise<any>;
}

let interceptors: FetchInterceptor[] = [];

const originalFetch = window.fetch;

window.fetch = async function (url: string | URL | Request, config: RequestInit = {}) {
    try {
        let modifiedConfig = { ...config };

        for (const interceptor of interceptors) {
            if (interceptor.request) {
                modifiedConfig = await interceptor.request(url.toString(), modifiedConfig);
            }
        }

        const response = await originalFetch(url, modifiedConfig);

        let modifiedResponse = response;
        for (const interceptor of interceptors) {
            if (interceptor.response) {
                modifiedResponse = await interceptor.response(modifiedResponse.clone());
            }
        }

        return modifiedResponse;
    } catch (error) {
        let modifiedError = error;
        for (const interceptor of interceptors) {
            if (interceptor.responseError) {
                modifiedError = await interceptor.responseError(modifiedError);
            }
        }
        throw modifiedError;
    }
};

export const addInterceptor = (interceptor: FetchInterceptor) => {
    interceptors.push(interceptor);
};

export const removeInterceptor = (interceptor: FetchInterceptor) => {
    interceptors = interceptors.filter(i => i !== interceptor);
}; 