/** @jsxImportSource @emotion/react */
import { IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { graphql } from '../../gql';
import { RulesMode, SecurityApproach } from '../../gql/generated/graphql';
import { useGraphQL, useGraphQLMutation } from '../../hooks';
import { Icon, PSDialog, PSMenu, PSMenuItem, Text } from '../../ui-kit';
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';

const mutateGenAiModifySecurityApproach = graphql(`
    mutation GenAiModifySecurityApproach($input: RulesMode!) {
        genAiAppMgmtModifySecurityApproach(input: {rulesMode: $input})
    }
`)

const getOnboardingStatus = graphql(`
    query GetOnboardingSecurityApproachStatus {
        getOnboardingSecurityApproachStatus
    }
`)


type IProps = {};

const SwitchGenAiMangeMode: React.FC<IProps> = (props) => {

    const query = useQueryClient();

    const [simpleAdvancedAnchorEl, setSimpleAdvancedAnchorEl] = useState<null | HTMLElement>(null);
    const [isValidationDialogOpen, setIsValidationDialogOpen] = useState(false);
    const [validationValue, setValidationValue] = useState('');

    const securityStatus = useGraphQL({
        document: getOnboardingStatus,
        select: (data) => data.getOnboardingSecurityApproachStatus
    })

    const changeToAdvancedOrSimple = useGraphQLMutation({
        document: mutateGenAiModifySecurityApproach,
        onSuccess: () => {
            query.invalidateQueries({ queryKey: ['onboarding-status'] });
            toast.success('Security approach changed successfully');
        }
    })

    const isSimpleMode = securityStatus.data === SecurityApproach.BlockAll || securityStatus.data === SecurityApproach.InspectAll;
    const isAdvancedMode = securityStatus.data === SecurityApproach.Customized;

    const handleOpenValidationDialog = () => {
        setSimpleAdvancedAnchorEl(null);
        setIsValidationDialogOpen(true);
    }

    const changeSimpleAdvancedMode = async () => {
        const mode = isSimpleMode ? RulesMode.Advanced : RulesMode.Simple;
        await changeToAdvancedOrSimple.mutateAsync({ input: mode });
    }

    if (securityStatus.isLoading) return null;

    return (
        <React.Fragment>
            <IconButton
                onClick={(e) => setSimpleAdvancedAnchorEl(e.currentTarget)}
            >
                <Icon iconName='PSSettingsIcon' iconSize={24} />
            </IconButton>

            <PSMenu
                open={Boolean(simpleAdvancedAnchorEl)}
                onClose={() => setSimpleAdvancedAnchorEl(null)}
                anchorEl={simpleAdvancedAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <PSMenuItem
                    selected={isSimpleMode}
                    onClick={() => isSimpleMode ? null : handleOpenValidationDialog()}
                >
                    Quick setup
                </PSMenuItem>

                <PSMenuItem
                    selected={isAdvancedMode}
                    onClick={() => isAdvancedMode ? null : handleOpenValidationDialog()}
                >
                    Custom configuration
                </PSMenuItem>
            </PSMenu>

            <PSDialog
                open={isValidationDialogOpen}
                onClose={() => setIsValidationDialogOpen(false)}
                title={`Warning!`}
                action={changeSimpleAdvancedMode}
                actionButtonText='Switch mode'
                actionButtonVariantType='critical'
                isActionDisabled={validationValue !== 'SWITCH'}
            >
                <div css={css`display: flex; flex-direction: column; gap: 20px;`}>
                    {isAdvancedMode &&
                        <div>
                            <Text>You are about to switch to standard mode for managing GenAI applications.</Text>
                            <Text>Doing this will <b>erase all your existing rules.</b> We suggest documenting any rules you may currently have before proceeding.</Text>
                        </div>
                    }

                    {isSimpleMode &&
                        <>
                            <div>
                                <Text>You are about to switch to advanced mode for managing GenAI applications.</Text>
                                <Text>Your existing rules for GenAI applications will remain, but switching to advanced mode will be <b>more complex to manage</b> because it allows for more granular definitions.</Text>
                            </div>
                            <Text>Please note that if you wish to revert in the future to standard mode, you will have to re-configure your settings.</Text>
                        </>
                    }

                    <Text>Type "<b>SWITCH</b>" to continue</Text>

                    <TextField
                        value={validationValue}
                        onChange={(e) => setValidationValue(e.target.value)}
                        autoFocus
                        variant='outlined'
                        size='small'
                        fullWidth
                        placeholder='Enter...'
                    />
                </div>
            </PSDialog>
        </React.Fragment>
    )
}

export default SwitchGenAiMangeMode;