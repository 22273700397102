/** @jsxImportSource @emotion/react */
import {FC} from 'react';
import {PercentageBarStyles} from './PercentageBar.css';

type IProps = {
    percentage: number;
    width: number;
}
const PercentageBar: FC<IProps> = (props) =>{
    const {percentage, width} = props;
    const percentageString = `${percentage}%`
    return (
        <div css={PercentageBarStyles.self}>
            <div css={PercentageBarStyles.bar(width)}/>
            <div>{percentageString}</div>
        </div>
    )
}

export default PercentageBar;