/** @jsxImportSource @emotion/react */
import React from 'react';
import { PSScrollable, PSError, NoData } from '../../../ui-kit';
import { useQuery } from '@tanstack/react-query';
import { graphql, createGraphQLClient } from '../../../gql';
import { useDateContext } from '../../../contexts';
import RiskyCard from './RiskyCard';
import { ApplicationTypes, RiskyGroups, RiskyUser } from '../../../gql/generated/graphql';
import { useNavigate } from 'react-router-dom';
import { RiskyUsersOrGroupsStyle } from './RiskyUsersOrGroups.css';

const getRiskyUsers = graphql(`
    query GetRiskyUsers($input: QueryRiskyUsersInput!) {
        queryRiskyUsers(input: $input) {
            userGroups
            violationsCount
            userEmail
            risk
        }
    },
`);

const getRiskyGroups = graphql(`
    query GetRiskyGroups($input: QueryRiskyGroupsInput!) {
        queryRiskyGroups(input: $input) {
            groupName
            usersCount
            violationsCount
            risk
        }
    }
`);

type IProps = {
    type: 'user' | 'group';
}

const RiskyUsersOrGroups: React.FC<IProps> = (props) => {
    const { type } = props;


    const navigate = useNavigate();

    const { date } = useDateContext();
    const { data: UsersData, isLoading: isUsersLoading, isError: isUsersError } = useQuery({
        queryKey: ['risky-users', JSON.stringify(date)],
        queryFn: async ({ signal }) => {
            const client = createGraphQLClient(signal);

            const { queryRiskyUsers } = await client.request(getRiskyUsers, {
                input: {
                    time: {
                        from: date.dates[0].toISOString(),
                        to: date.dates[1].toISOString(),
                    },
                    applicationType: [ApplicationTypes.Extension]
                }
            });
            return queryRiskyUsers;
        },
    })

    const { data: GroupsData, isLoading: isGroupsLoading, isError: isGroupsError } = useQuery({
        queryKey: ['risky-groups', JSON.stringify(date)],
        queryFn: async ({ signal }) => {
            const client = createGraphQLClient(signal);
            const { queryRiskyGroups } = await client.request(getRiskyGroups, {
                input: {
                    time: {
                        from: date.dates[0].toISOString(),
                        to: date.dates[1].toISOString(),
                    },
                    applicationType: [ApplicationTypes.Extension]
                }
            });
            return queryRiskyGroups;
        },
    })

    const onUserClick = (user: RiskyUser) => {
        navigate(`/activity-monitor/?user=${user.userEmail}&alerts=alerts`);
    }

    const onGroupClick = (group: RiskyGroups) => {
        navigate(`/activity-monitor/?userGroups=${group.groupName}&alerts=alerts`);
    }

    const isLoading = type === 'user' ? isUsersLoading : isGroupsLoading;
    const isError = type === 'user' ? isUsersError : isGroupsError;
    const data = type === 'user' ? UsersData : GroupsData;

    if (isError) return <div css={RiskyUsersOrGroupsStyle.stateContainer}>
        <PSError />
    </div>;

    if (isLoading) return <PSScrollable isDisableScroll={isLoading}>
        {Array(15).fill(undefined).map((_, index) => (
            <RiskyCard key={index} isLoading={isLoading} type={type} />
        ))}
    </PSScrollable>;

    if (data?.length === 0) return (
        <div css={RiskyUsersOrGroupsStyle.stateContainer}>
            <NoData />
        </div>
    );

    return (
        <PSScrollable isDisableScroll={isLoading}>
            {data?.map((userOrGroup, index) => {
                if (type === 'group') {
                    const group = userOrGroup as RiskyGroups;
                    return <RiskyCard key={index} group={group} type='group' onClick={() => onGroupClick(group)} />
                } else {
                    const user = userOrGroup as RiskyUser;
                    return <RiskyCard key={index} user={user} type='user' onClick={() => onUserClick(user)} />
                }
            })}
        </PSScrollable>
    )
}

export default RiskyUsersOrGroups;