import { DateTime } from 'luxon';
import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { useLocalStorage, useSearchParam } from 'react-use';
import { predefinedToDates } from '../ui-kit';

export type TDateSelection = (TAbsoluteDateSelection | TRelativeDateSelection) & {
    lastResetTimestamp: number | null;
};

const now = DateTime.now().toMillis();

export const dateStoreDefault = {
    logs: {
        resetAfterMinutes: 60 * 24 * 7,
        dates: predefinedToDates('last-month'),
        period: 'last-month',
        type: 'relative',
        lastResetTimestamp: now,
    },
    audit: {
        resetAfterMinutes: 60 * 24 * 7,
        dates: predefinedToDates('last-month'),
        period: 'last-month',
        type: 'relative',
        lastResetTimestamp: now,
    },
    dashboard: {
        resetAfterMinutes: 60 * 24 * 7,
        dates: predefinedToDates('last7days'),
        period: 'last7days',
        type: 'relative',
        lastResetTimestamp: now,
    },
} as const satisfies Record<string, { resetAfterMinutes: number } & TDateSelection>;

export type TPredefinedPageDate = keyof typeof dateStoreDefault;

export const DateTypeSelection = {
    absolute: "absolute",
    relative: "relative",
} as const;

export type TDateTypeSelection<T extends keyof typeof DateTypeSelection> =
    (typeof DateTypeSelection)[T];

export type TAbsoluteDateSelection = {
    type: TDateTypeSelection<"absolute">;
    period: 'custom';
    dates: [Date, Date];
};

export type TRelativeDateSelection = {
    type: TDateTypeSelection<"relative">;
    period: TPredefinedDateOrTimeSelection;
    dates: [Date, Date];
};

export const predefinedDateSelections = ['today', 'yesterday', 'last7days', 'last-month', 'last-year', 'data-retention'] as const;
export const predefinedTimeSelections = ['last5minutes', 'last15minutes', 'last30minutes', 'last1hour', 'last3hours', 'last6hours', 'last12hours'] as const;
export const predefinedDateOrTimeSelections = [...predefinedDateSelections, ...predefinedTimeSelections] as const;

export type TPredefinedDateSelection = typeof predefinedDateSelections[number];
export type TPredefinedTimeSelection = typeof predefinedTimeSelections[number];
export type TPredefinedDateOrTimeSelection = TPredefinedDateSelection | TPredefinedTimeSelection;

export type DateContextType = {
    date: TDateSelection;
    setDate: React.Dispatch<React.SetStateAction<TDateSelection>>;
};

export const DateContext = createContext<DateContextType | null>(null);

type IProps = {
    children: React.ReactNode;
    page: TPredefinedPageDate;
};

export const DateContextProvider: React.FC<IProps> = ({ children, page }) => {

    const searchParamsDateType = useSearchParam('dateType') as keyof typeof DateTypeSelection;
    const searchParamsDates = useSearchParam('dates');
    const searchParamsPeriod = useSearchParam('period') as TPredefinedDateOrTimeSelection;

    const isDateTypeValid = useCallback((dateType: keyof typeof DateTypeSelection | null) => {
        if (dateType !== 'absolute' && dateType !== 'relative') return false;
        return true;
    }, []);

    const isPeriodValid = useCallback((period: TPredefinedDateOrTimeSelection | 'custom' | null) => {
        if (!period) return false;
        if (!['custom', ...predefinedDateOrTimeSelections].includes(period)) return false;
        return true;
    }, [])

    const isDatesValid = useCallback((dates: string | null) => {
        if (!dates) return false;
        const splitDates = dates.split(',');
        try {
            return splitDates.every((x) => DateTime.fromISO(x).isValid);
        } catch {
            return false;
        }
    }, []);

    const isParamsValid = useCallback(() => {
        if (!isDateTypeValid(searchParamsDateType)) return false;
        if (!isPeriodValid(searchParamsPeriod)) return false;
        if (!isDatesValid(searchParamsDates)) return false;

        return true;
    }, [isDateTypeValid, isPeriodValid, isDatesValid]);

    const [storedDateType, setStoredDateType] = useLocalStorage<TDateSelection>(
        `dateStore_${page}`,
        dateStoreDefault[page],
        {
            raw: false,
            serializer: (value) => {
                const base = { ...value };

                if (value.type === 'absolute') {
                    return JSON.stringify({
                        ...base,
                        dates: [value.dates[0].toISOString(), value.dates[1].toISOString()],
                    });
                }

                return JSON.stringify({
                    ...base,
                    dates: predefinedToDates(value.period).map((x) => x.toISOString()),
                });
            },
            deserializer: (value) => {
                const parsed = JSON.parse(value);
                const base = { ...parsed };
                if (parsed.type === 'absolute') {
                    const from = DateTime.fromISO(parsed.dates[0]);
                    const to = DateTime.fromISO(parsed.dates[1]);

                    return {
                        ...base,
                        dates: [from.toJSDate(), to.toJSDate()],
                    };
                }

                return {
                    ...base,
                    dates: predefinedToDates(parsed.period),
                };
            },
        }
    );

    useEffect(() => {
        if (!storedDateType) return;

        const timeStamp = JSON.parse(localStorage.getItem(`dateStore_${page}`) ?? '{}').lastResetTimestamp;
        if (!timeStamp) return;

        const resetAfterMinutes = dateStoreDefault[page].resetAfterMinutes;
        const lastReset = DateTime.fromMillis(timeStamp);
        const minutesSinceReset = DateTime.now().diff(lastReset, 'minutes').minutes;

        if (minutesSinceReset >= resetAfterMinutes) {
            setStoredDateType({
                ...storedDateType,
                lastResetTimestamp: DateTime.now().toMillis(),
            });
        }
    }, [page, storedDateType?.lastResetTimestamp, setStoredDateType]);

    return (
        <DateContext.Provider value={{ date: storedDateType!, setDate: setStoredDateType as React.Dispatch<React.SetStateAction<TDateSelection>> }}>
            {children}
        </DateContext.Provider>
    );
};

export const useDateContext = () => {
    const context = React.useContext(DateContext);
    if (!context) {
        throw new Error('useDateContext must be used within a DateContextProvider');
    }

    return context;
};