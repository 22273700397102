/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ColorThemeKeys } from '../../../styles';

export const PSPercentageBarStyle = {
    barContainer: (gap: number) => css`
        display: flex;
        gap: ${gap}px;
        overflow: hidden;
        width: 100%;
    `,

    singleBarContainer: (width: number) => css`
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        overflow: hidden;
        width: ${width}%;

        &:first-of-type .single-bar {
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
        }

        &:last-of-type .single-bar {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
        }
    `,

    singleBar: (color: ColorThemeKeys) => css`
        height: 6px;
        background: var(--color-${color});
        width: 100%;
        
    `,

    singleBarLabel: css`
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: inherit;
        width: 100%;
        text-align: center;
    `,
};