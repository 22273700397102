/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const EmployeesStyle = {
    self: css`
        padding: 20px 30px;
    `,
    header: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-column: span 4;
    `,
    grid: (shouldMinimizeGap: boolean) => css`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto auto auto 1fr;
        gap: ${shouldMinimizeGap ? '15px' : '25px'};
        width: 100%;
    `,
    doubleColumn: css`
        grid-column: span 2;
    `,
};