/** @jsxImportSource @emotion/react */
import {FC, ReactNode, useRef, useState} from "react";
import Popper, {PopperProps} from '@mui/material/Popper';
import {PSPopperStyles} from './PSPopper.css';
import {SerializedStyles} from "@emotion/react";

type IProps = {
    children: ReactNode;
    tooltip: ReactNode;
    siblingContainerCss?: SerializedStyles;
    childrenContainerCss?: SerializedStyles;
} & Omit<PopperProps, "open">

const PSPopper: FC<IProps> = (props) => {
    const {children, tooltip, siblingContainerCss, childrenContainerCss, ...RestPopperProps} = props;
    const [isOpen, setIsOpen] = useState(false);

    const elementRef = useRef<null | HTMLDivElement>(null);

    return (
        <div onMouseOver={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
            <div ref={elementRef} css={[PSPopperStyles.popperSibling, siblingContainerCss]}>
                {children}
            </div>
            <Popper placement='top' anchorEl={elementRef.current} {...RestPopperProps} open={isOpen}>
                <div css={[PSPopperStyles.popper, childrenContainerCss]}>
                    {tooltip}
                </div>
            </Popper>
        </div>
    )
}

export default PSPopper;