/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { ManageApplicationRules } from '../ManageApplicationRules';
import { ManagePolicies } from '../ManagePolicies';
import { PSMenuItem, Text } from '../../ui-kit';
import { useGraphQL } from '../../hooks';
import { useParams } from 'react-router-dom';
import { getConnectorByName } from '../../routes-new/Manage/ConnectorsCommon';
import { Select } from '@mui/material';
import { css } from '@emotion/react';
import SwitchGenAiMangeMode from '../../routes-new/GenAiMgmt/SwitchGenAiMangeMode';
import { ApplicationTypes } from '../../gql/generated/graphql';


type IProps = {
    applicationName?: string;
};

const AdvancedRules: React.FC<IProps> = (props) => {
    const { } = props;
    const [tab, setTab] = useState<'application-rules' | 'manage-policies'>('application-rules')
    const { applicationName, connectorName } = useParams();

    const applicationType = useGraphQL({
        document: getConnectorByName,
        variables: { name: applicationName! || connectorName! },
        select: (data) => data.applications[0].applicationType,
        enabled: !!applicationName || !!connectorName,
    })

    if (applicationType.isLoading) return null;

    if ((!applicationName && !connectorName) || !applicationType.data) {
        return <div>Application not found</div>
    }

    return (
        <React.Fragment>
            <div css={css`display: flex; align-items: center; gap: 15px; margin-bottom: 20px;`}>
                <Text variant="bold">{`Custom configuration ${tab === 'application-rules' ? '' : '> Manage Policies'}`}</Text>
                <div css={css`display: flex; align-items: center; gap: 10px; margin-left: auto;`}>
                    <Select
                        variant='outlined'
                        size='small'
                        value={tab}
                        onChange={(e) => setTab(e.target.value as any)}
                    >
                        <PSMenuItem value='application-rules'>Manage Advanced Rules</PSMenuItem>
                        <PSMenuItem value='manage-policies'>Manage Policies</PSMenuItem>
                    </Select>
                    {applicationType.data === ApplicationTypes.Extension && <SwitchGenAiMangeMode />}
                </div>
            </div>

            {tab === 'application-rules' && <ManageApplicationRules />}
            {tab === 'manage-policies' && <ManagePolicies policyType={applicationType.data as any} />}
        </React.Fragment >
    )
}

export default AdvancedRules;