/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const ViolationsPreventedStyles = {
    self: css``,
    sensitiveData: css`
        display: flex;
        flex-direction: column;
        gap: 10px;
    `,
    sensitiveDataTitle: css`
        display: flex;
        align-items: center;
        gap: 5px;
    `,
    sensitiveDataGrid: css`
        display: grid;
        grid-template-columns: auto auto;
        width: fit-content;
        column-gap: 10px;
    `,
    sensitiveDataRow: (isFirstRow: boolean) => css`
        margin-bottom: ${isFirstRow ? '5px' : '0'};
    `,
};
