/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const TableSectionStyle = {
    self: css`
        width: 100%;
        grid-column: span 4;
        height: calc(100vh - 125px);
    `,
    header: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    `,
    gridContainer: css`
        height: 100%;
        padding-bottom: 25px;
    `,
};