/** @jsxImportSource @emotion/react */
import React, {Fragment, useMemo} from 'react';
import WidgetBox from '../WidgetBox/WidgetBox';
import PercentageBar from "./PercentageBar";
import {Icon, Text, PSBadge} from '../../../../ui-kit';
import { useDateContext } from '../../../../contexts';
import {ShadowAIAppUsageStyles} from './ShadowAIAppUsage.css';
import {Skeleton} from "@mui/material";
import useBreakpoints from "../../../../hooks/useBreakpoints";

const dummyData2 = [
    { application: "ChatGPT ChatGPT ChatGPT", percentage: 4, image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=" },
    { application: "Stable Diffusion", percentage: 8, isNew: true, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAhUlEQVR4nO2WUQ6AIAxDO+5/5/pnJlJF2DAx9o+E9dEaA0YSPTJDcyMJu529gihjJQUsUYCrmVOSEfOWfKpDkihA7SXritQOiUxRe5YsgAetrStTBnT+8hP6Tl0/5Bmk5z6YhmQD1kIyKyNhxS8yAMAb3yQyjfeSr5XRO6Z1UFnXSCo1swEgtzQfv1MvKAAAAABJRU5ErkJggg==' },
    { application: "MidJourney MidJourney", percentage: 15 },
    { application: "FormX", percentage: 16 },
    { application: "Other", percentage: 23 },
    { application: "Claude", percentage: 42 , image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=" }
]

const ShadowAIAppUsage = () => {
    const isLoading = false;

    const {isSm, isMd, isLg} = useBreakpoints();

    const responsiveMaxWidth = useMemo(() => {
        if (isLg) return '150px';
        if (isMd) return '130px';
        if (isSm) return '100px';
        return 'unset'
    }, [isMd, isSm, isLg])


    const newArray = JSON.parse(JSON.stringify(dummyData2));
    newArray.sort((a: any, b: any) => b.percentage - a.percentage); // todo - type

    let otherApps: any = null;

    const finalArray = newArray.filter((app: any) => { // todo - type
        if (app.application === 'Other') {
            otherApps = app;
            return false;
        }
        return true;
    })
    if (otherApps) finalArray.push(otherApps)

    const maxPercentage = newArray[0].percentage;

    const { date } = useDateContext();

    const getAppIcon = (app: any) => { // todo - type
        const {application, image} = app;
        if (application === 'Other') return <Icon iconSize={20} iconName='PSSecurityApproachIcon' color='blue-light'/>
        if (image) return <img src={image} alt={`${application}-logo`}/>
        return <Icon iconSize={20} iconName='PSDefaultAiApplicationIcon' color='blue-light'/>
    }

    if (isLoading) return (
        <WidgetBox
            title={<Skeleton variant='text' animation='pulse' height={20} width={220} className='loading-skeleton'/>}
            widgetPadding='30px 30px 30px 35px'
        >
            <div css={ShadowAIAppUsageStyles.grid}>
                {[100, 80, 60, 40, 20, 10].map((width) => {
                   const relativeWidth = (width / 100) * 150;
                    return (
                        <Fragment key={relativeWidth}>
                            <Skeleton variant='text' animation='pulse' height={20} width={150} className='loading-skeleton'/>
                            <Skeleton variant='text' animation='pulse' height={20} width={relativeWidth} className='loading-skeleton'/>
                        </Fragment>
                    )
                })}
            </div>
        </WidgetBox>
    )

    return (
        <WidgetBox title="Shadow AI Applications Usage" widgetPadding='30px 30px 30px 35px'>
                <div css={ShadowAIAppUsageStyles.grid}>
                    {/* @ts-ignore // todo */}
                    {finalArray.map((app) => {
                        const {percentage, application, isNew} = app;
                        const relativeWidth = (percentage / maxPercentage) * 100;
                        return (
                            <Fragment key={percentage}>
                                <div css={ShadowAIAppUsageStyles.appContainer}>
                                    {getAppIcon(app)}
                                    <div css={ShadowAIAppUsageStyles.appName(responsiveMaxWidth)}>
                                        <Text variant='text' ellipsis>{application}</Text>
                                        {/*// todo - #FFD15C instead of orange*/}
                                        {isNew && (
                                            <div css={ShadowAIAppUsageStyles.newBadge}>
                                                <PSBadge label='new' size='small' backgroundColor='orange-light' />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                    <PercentageBar key={percentage} percentage={percentage} width={relativeWidth}/>
                            </Fragment>
                        )
                    })}
                </div>
            </WidgetBox>
        )
}

export default ShadowAIAppUsage;