/** @jsxImportSource @emotion/react */
import React, { FC, ReactNode, useMemo } from 'react';
import { PSOverviewStyle } from "./PSOverview.css";
import {TOverviewVariants} from "./types";
import {Icon, Circle} from '../index'
import {PSIconNames} from "../Icon/Icon";
import {Text} from '../../ui-kit'
import {Skeleton} from "@mui/material";
import {formatNumberWithCommas} from '../../utils'

const maximumNumericValue = 99999;

type IProps = {
    variant: TOverviewVariants;
    iconName: PSIconNames;
    textContent: string;
    numberContent: number;
    isLoading?: boolean;
    topBadge?: ReactNode;
    badge?: ReactNode;
    footer?: ReactNode;
    minWidth?: number;
    maxWidth?: number;
    maxContentWidth?: number;
}

const PSOverview: FC<IProps> = (props) => {
    const { variant, iconName, textContent, numberContent, isLoading, badge, topBadge, footer, minWidth, maxWidth, maxContentWidth } = props;

    const displayedNumber = useMemo(() => (
        numberContent > maximumNumericValue ? `${formatNumberWithCommas(maximumNumericValue)}+` : formatNumberWithCommas(numberContent)
    ),[numberContent]);

    const styles = PSOverviewStyle(variant);

    return (
        <div css={styles.self(minWidth, maxWidth)}>
            <style>
                {`
                    .number {
                       font-size: 45px;
                    }
                
                    @container (max-width: 370px){
                         .OverviewIcon {
                             width: 50px;
                             height: 50px;
                             svg {
                                width: 30px;
                                height: 30px;
                             }
                         }
                         
                         .textWrapper {
                            justify-content: center
                         }
                         
                         .number {
                            font-size: 40px;
                            line-height: 35px;
                         }
                         
                         .title {
                            font-size: 15px;
                         }
                    }
                    
                    @container (max-width: 340px){
                         .OverviewIcon {
                             width: 50px;
                             height: 50px;
                             svg {
                                width: 30px;
                                height: 30px;
                             }
                         }
                         
                         .number {
                            font-size: 35px;
                            line-height: 30px;
                         }
                    }
                    
                    @container (max-width: 315px){
                         .OverviewIcon {
                            display: none; 
                         }
                         
                         .contentWrapper {
                            gap: 0;
                         }
                         
                        .badgeContainer {
                            justify-self: end;
                            margin-left: auto;
                        }
                    }
                `}
            </style>
            <Circle color={'red'} size={215} circleCss={styles.glow}/>

            <div css={styles.topContainer}>
                <div css={styles.contentWrapper(maxContentWidth)} className='contentWrapper'>
                    <div css={styles.iconWrapper}>
                        <div css={styles.iconContainer} className='OverviewIcon'>
                            <Icon iconSize={35} iconName={iconName} color={variant === 'dark' ? 'blue-dark' : 'blue-light'} />
                        </div>
                    </div>

                    <div css={styles.contentTextContainer} className='textWrapper'>
                        {isLoading ?
                            (<div css={styles.skeletonContainer}>
                                <Skeleton variant='text' animation='pulse' height={20} width={textContent.length * 6} className='loading-skeleton' css={styles.skeleton}/>
                                 <div css={styles.numberSkeletonContainer}>
                                    <Skeleton variant='text' animation='pulse' height={20} width={textContent.length * 6} className='loading-skeleton' css={styles.skeleton}/>
                                     <Skeleton variant='text' animation='pulse' height={20} width={65} className='loading-skeleton' css={styles.skeleton}/>
                                 </div>
                            </div>
                            )
                            :
                            (<>
                                {topBadge}
                                <Text variant='text' css={styles.textContent} className='title'>{textContent}</Text>
                                <div css={styles.numberAndBadgeContainer}>
                                    <Text css={styles.numberContent} className='number'>{displayedNumber}</Text>
                                    {badge &&
                                        <div css={styles.badgeContainer} className='badgeContainer'>
                                            {badge}
                                        </div>
                                    }
                                </div>
                             </>
                            )
                        }
                    </div>

                </div>

            </div>

            {isLoading &&
                <div css={styles.footerContainer}>
                        <Skeleton variant='text' animation='pulse' height={20} width={140} className='loading-skeleton' css={styles.skeleton}/>
                </div>
            }
            {!isLoading && footer &&
                <div css={styles.footerContainer}>
                    {footer}
                </div>
            }
        </div>
    )
}

export default PSOverview;