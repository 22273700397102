/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const PSEmptyStateStyles = {
    self: css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    `,
    header: css`
        font-size: 35px;
    `,
    textContainer: css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        margin-top: 65px;
    `,
};