/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const SecretsFormStyle = {
    self: css`
        display: flex;
        align-items: center;
        gap: 15px;
    `,
    select: css`
        min-width: 200px;
    `,
    dialogControls: css`
        display: flex; 
        align-items: center; 
        justify-content: space-between; 
        gap: 10px;
    `,
    controlsLeft: css`
        display: flex; 
        align-items: center; 
        gap: 10px;
    `,
    secretsList: css`
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        margin-top: 10px;
        height: min(600px, calc(100vh - 305px));
        border: 1px solid var(--color-black-25);
        border-radius: 10px;
    `,
    secretItem: css`
        margin-inline: 0;
        border-bottom: 1px solid var(--color-black-25);
        width: 100%;
    `,
    noData: css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    `
}