/** @jsxImportSource @emotion/react */
import {FC, ReactNode} from 'react';
import {Drawer} from "@mui/material";
import {PSRightOverDrawerStyle} from './PSRightOverDrawer.css'

interface Props {
    children: ReactNode;
    isOpen?: boolean;
    onClose: () => void;
}

export const PSRightOverDrawer: FC<Props> = (props) => {
    const {children, isOpen, onClose} = props;

    return (
      <Drawer
        open={isOpen}
        transitionDuration={100}
        anchor='right'
        onClose={onClose}
        css={PSRightOverDrawerStyle.self}
    >
          {children}
    </Drawer>
    )
}