/** @jsxImportSource @emotion/react */
import React from 'react';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import { NoDataStyle } from './NoData.css';

type IProps = {
    header?: string;
    message?: string;
};

const NoData: React.FC<IProps> = (props) => {
    const { header = 'No Data', message = 'No data to show, come back later' } = props;

    return (
        <div css={NoDataStyle.self}>
            <div css={NoDataStyle.iconContainer}>
                <Icon iconName='PSInfoIcon' color='black-70' iconSize={20} />
            </div>
            {header && <Text textCss={NoDataStyle.noDataHeader} color='black'>{header}</Text>}
            {message && <Text textCss={NoDataStyle.noDataMessage} variant='small' color='black-50'>{message}</Text>}
        </div>
    )
}

export default NoData;