/** @jsxImportSource @emotion/react */
import _ from 'lodash';
import React, { useCallback, useLayoutEffect } from 'react';
import Icon from '../Icon/Icon';
import { PSScrollableStyle } from './PSScrollable.css';

type IProps = {
    children: React.ReactNode;
    isDisableScroll?: boolean;
};

const PSScrollable: React.FC<IProps> = (props) => {
    const { children, isDisableScroll } = props;

    const scrollContainerRef = React.useRef<HTMLDivElement>(null);
    const [isScrollableLeft, setIsScrollableLeft] = React.useState(false);
    const [isScrollableRight, setIsScrollableRight] = React.useState(false);

    const checkScrollability = useCallback(() => {
        if (scrollContainerRef.current) {
            const TOLERANCE = 5;
            const { scrollWidth, clientWidth, scrollLeft } = scrollContainerRef.current;
            setIsScrollableLeft(scrollLeft >= TOLERANCE);
            setIsScrollableRight(scrollLeft <= scrollWidth - clientWidth - TOLERANCE);
        }
    }, []);

    useLayoutEffect(() => {
        const containerRef = scrollContainerRef.current
        if (!containerRef) return;


        checkScrollability();
        window.addEventListener('resize', checkScrollability);
        containerRef.addEventListener('scroll', checkScrollability);
        const observer = new IntersectionObserver(checkScrollability);
        observer.observe(containerRef?.children[0]);

        return () => {
            window.removeEventListener('resize', checkScrollability);
            containerRef?.removeEventListener('scroll', checkScrollability);
            observer.disconnect();
        }
    }, [children, scrollContainerRef, checkScrollability]);

    const scroll = (direction: 'right' | 'left') => {
        const containerRef = scrollContainerRef.current
        if (!containerRef) return;

        const childFirstElement = containerRef.children[0] as HTMLElement;
        const childFirstElementWidth = childFirstElement.getBoundingClientRect().width;

        const containerWidth = containerRef.getBoundingClientRect().width - (childFirstElementWidth * 2)
        containerRef.scrollBy({
            left: direction === 'right' ? containerWidth : -containerWidth,
            behavior: 'smooth'
        });

        checkScrollability();
    }

    return (
        <div css={PSScrollableStyle.self}>
            {isScrollableRight && !isDisableScroll &&
                <div
                    onClick={() => scroll('right')}
                    css={[PSScrollableStyle.scrollableGradient, PSScrollableStyle.scrollableGradientRight]}
                >
                    <Icon iconName='PSArrowRightIcon' color='black-70' iconSize={25} />
                </div>
            }

            {isScrollableLeft && !isDisableScroll &&
                <div
                    onClick={() => scroll('left')}
                    css={[PSScrollableStyle.scrollableGradient, PSScrollableStyle.scrollableGradientLeft]}
                >
                    <Icon iconName='PSArrowLeftIcon' color='black-70' iconSize={25} />
                </div>
            }

            <div
                ref={scrollContainerRef}
                css={PSScrollableStyle.scrollContainer(isDisableScroll ? 'hidden' : 'auto')}
            >
                {children}
            </div>
        </div>
    )
}

export default PSScrollable;