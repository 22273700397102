/** @jsxImportSource @emotion/react */
import React from 'react';
import { Circle, PSError, LineChart, PSBox, Text } from '../../../ui-kit';
import { css } from '@emotion/react';
import { Layer, CustomLayerProps, SliceTooltipProps, Point } from '@nivo/line'
import { useQuery } from '@tanstack/react-query';
import { graphql, createGraphQLClient } from '../../../gql';
import { useDateContext } from '../../../contexts';
import { ApplicationTypes, DailyViolations } from '../../../gql/generated/graphql';
import { DataItem } from '../../../ui-kit/@Charts/LineChart/LineChart';


const queryAlertsByViolation = graphql(`
    query QueryAlertsByViolation($input: QueryAlertsByViolationInput!) {
    queryAlertsByViolation(input: $input) {
        date
        violations{
        count
        key
        }
    }
    }
`);

type IProps = {};

const AlertsByViolation: React.FC<IProps> = (props) => {
    const { } = props;

    const { date } = useDateContext();
    const { data, isLoading, isError } = useQuery({
        queryKey: ['daily-violation', date.dates.map(d => d.toISOString())],
        queryFn: async ({ signal }) => {
            const client = createGraphQLClient(signal);
            const { queryAlertsByViolation: response } = await client.request(queryAlertsByViolation, {
                input: {
                    time: {
                        from: date.dates[0].toISOString(),
                        to: date.dates[1].toISOString(),
                    },
                    applicationType: [ApplicationTypes.Extension]
                }
            });

            const dataTransformed = transformViolationDataToChartData(response);
            return dataTransformed;
        }
    })


    const colors = ['var(--color-blue)', 'var(--color-green)', 'var(--color-orange)', 'var(--color-red)', 'var(--color-graph-purple)'];

    return (
        <>
            <PSBox padding={"30px 30px 35px 40px"}>
                <Text variant="monoSmall">ALERTS BY VIOLATION TYPE</Text>
                {isError &&
                    <div css={css`margin-top: 30px;`}>
                        <PSError />
                    </div>
                }
                {!isError && (
                    <LineChart
                        isLoading={isLoading}
                        containerCss={css`flex: 1;`}
                        data={data || []}
                        maxLines={5}
                        customColors={colors}
                        margin={{ left: 20, bottom: 20, top: 30 }}
                        colors={(d) => d.color}
                        height={150}
                        minWidth={400}

                        enableGridY={false}
                        enableGridX={false}

                        xFormat="time:%Y-%m-%d"

                        axisBottom={{
                            tickSize: 0,
                            tickPadding: 5,
                            format: '%b %d',
                            tickValues: 3,
                        }}

                        axisLeft={{
                            tickSize: 0,
                            tickPadding: 5,
                            tickValues: 2,
                        }}

                        xScale={{
                            type: 'time',
                            format: '%Y-%m-%d',
                            useUTC: false,
                            precision: 'day',
                        }}

                        yScale={{
                            type: "linear",
                            min: "auto",
                            max: "auto",
                            stacked: false,
                            reverse: false,
                        }}

                        lineWidth={3}
                        pointColor={'var(--color-white)'}
                        pointBorderWidth={3}
                        pointBorderColor={{ from: "serieColor" }}

                        enableSlices="x"
                        enablePoints={false}
                        crosshairType="x"
                        useMesh={true}
                        legends={[]}
                        curve="monotoneX"
                        layers={
                            [
                                'grid',
                                'axes',
                                'areas',
                                'lines',
                                'crosshair',
                                'slices',
                                'mesh',
                                'legends',
                                ActivePoint,
                            ] as Layer[]
                        }
                        animate={false}
                        motionConfig="stiff"


                        tooltip={({ point }) => {
                            return (
                                <PSBox
                                    isBoxShadow
                                    borderRadius={6}
                                    padding={12}
                                >
                                    <div css={css`display: flex; align-items: center; gap: 10px;`}>
                                        <Circle size={10} nativeColor={point.serieColor} />
                                        <Text variant='small' color='black-70'>{point.serieId === '_others_' ? 'Others' : point.serieId}</Text>
                                    </div>
                                    <div css={css`display: flex; align-items: center; gap: 10px; margin-top: 5px;`}>
                                        <Text variant='bold' color='black-70'>{point.data.yFormatted}</Text>
                                        <Text variant='small' color='black-70'>{point.data.xFormatted}</Text>
                                    </div>
                                </PSBox>
                            )
                        }}

                        sliceTooltip={({ slice }) => {
                            return (
                                <PSBox
                                    isBoxShadow
                                    borderRadius={6}
                                    padding={12}
                                >
                                    <div css={css`padding: 10px;`}>
                                        <Text variant='bold' color='black-70'>{slice.points[0].data.xFormatted}</Text>
                                        <div css={css`display: flex; align-items: center; gap: 5px;`}>
                                            <Text variant='small' color='black-70'>Total</Text>
                                            <Text variant='smallBold' color='black-70'>{slice.points.reduce((acc, curr) => acc + (curr.data.y as number), 0)}</Text>
                                        </div>
                                    </div>
                                    {slice.points.map(x => (
                                        <div key={x.id} css={css`
                                            padding: 5px;
                                            &:not(:nth-of-type(2)) {
                                                border-top: 1px solid var(--color-black-25);
                                            }
                                        `}>
                                            <div css={css`display: flex; align-items: center; gap: 10px;`}>
                                                <Circle size={10} nativeColor={x.serieColor} />
                                                <Text variant='small' color='black-70'>{x.serieId === '_others_' ? 'Others' : x.serieId}</Text>
                                                <Text variant='bold' color='black-70'>{x.data.yFormatted}</Text>
                                            </div>
                                        </div>
                                    ))}
                                </PSBox>
                            )
                        }}
                    />
                )}
            </PSBox>

            {/* <Dialog
                open={isOthersVisible}
                onClose={() => setIsOthersVisible(false)}
            >
                <div css={css`
                    padding: 20px;
                    max-width: 500px;
                    width: 100%;
                `}>
                    <Text variant='header2'>Others</Text>
                    <div css={css`display: flex; flex-direction: column; gap: 10px; flex-wrap: wrap;`}>
                        {othersData.map(x => (
                            <div key={x.id}>
                                <div>
                                    <Text>{x.id}</Text>
                                    <Text>{x.data.reduce((acc, curr) => acc + curr.y, 0)}</Text>
                                </div>
                                {x.data.map(y => (
                                    <div css={css`display: flex; align-items: center; gap: 10px;`}>
                                        <Text>{y.x}</Text>
                                        <Text>{y.y}</Text>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </Dialog> */}
        </>
    )
}

export default AlertsByViolation;


type CurrentSlice = {
    currentSlice: SliceTooltipProps['slice']
}

type CurrentPoint = {
    currentPoint: Point

}

function ActivePoint({
    currentSlice,
    currentPoint,
}: CustomLayerProps & CurrentSlice & CurrentPoint) {

    if (currentSlice) return (
        <g>
            {currentSlice?.points.map((point) => (
                <circle
                    key={point.id}
                    cx={point.x}
                    cy={point.y}
                    r={6}
                    fill={point.color}
                    stroke={point.borderColor}
                    strokeWidth={3}
                    css={css`pointer-events: none;`}
                />
            ))}
        </g>
    )

    if (currentPoint) return (
        <g>
            <circle
                cx={currentPoint.x}
                cy={currentPoint.y}
                r={6}
                fill={currentPoint.color}
                stroke={currentPoint.borderColor}
                strokeWidth={3}
                css={css`pointer-events: none;`}
            />
        </g>
    )
}

function transformViolationDataToChartData(dailyViolations: DailyViolations[]): DataItem[] {
    const violationDataMap: Record<string, DataItem> = {};

    dailyViolations.forEach(day => {
        day.violations.forEach(violation => {
            if (!violationDataMap[violation.key]) {
                violationDataMap[violation.key] = {
                    id: violation.key,
                    data: []
                };
            }
            violationDataMap[violation.key].data.push({
                x: day.date.split('T')[0],
                y: violation.count
            });
        });
    });

    return Object.values(violationDataMap);
}