/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const PSPercentageChangeBadgeStyles = {
    self: css`
        display: flex;
        align-items: center;
        gap: 2px;
    `,
    icon: css`
        height: 100%;
    `,
};