/** @jsxImportSource @emotion/react */
import {PSOverview, PSBadge, PSPercentageChangeBadge, PSMuiDateRangePicker, Text} from "../../../ui-kit";
import { EmployeesStyle } from "./Employees.css";
import {ViolationsPrevented, ShadowAIAppUsage, TableSection} from "../Widgets";
import WidgetBox from "../Widgets/WidgetBox/WidgetBox";
import useBreakpoints from "../../../hooks/useBreakpoints";

const Employees = () => {
    const isLoading = false;

    const {screenWidth} = useBreakpoints();

    return (
        <div css={EmployeesStyle.self}>
            <div css={EmployeesStyle.grid(screenWidth <= 1440)}>
                <div css={EmployeesStyle.header}>
                    <Text variant='header2'>GenAI usage in your organization</Text>
                    <PSMuiDateRangePicker />
                </div>
                <ViolationsPrevented />
                <PSOverview
                    minWidth={277}
                    maxContentWidth={395}
                    variant='light'
                    iconName='PSEducateIcon'
                    textContent={'Total Prompts'}
                    numberContent={10000000}
                    isLoading={isLoading}
                    badge={<PSPercentageChangeBadge direction='down' number={12} theme='dark'/>}
                />
                <PSOverview
                    minWidth={277}
                    maxContentWidth={395}
                    variant='light'
                    iconName='PSUserGenAiIcon'
                    textContent={'Active GenAI Users'}
                    numberContent={1800}
                    isLoading={isLoading}
                    badge={<PSPercentageChangeBadge direction='down' number={5} theme='default'/>}
                />
                <PSOverview
                    minWidth={277}
                    maxContentWidth={395}
                    variant='light'
                    iconName='PSSecurityApproachIcon'
                    textContent={'Shadow AI applications'}
                    numberContent={88}
                    isLoading={isLoading}
                    // todo - #FFD15C instead of orange
                    badge={<PSBadge label="5 New" size={'large'} backgroundColor={"orange-light"}/>}
                />

                <WidgetBox title='Total prompts and violations placeholder' widgetCss={EmployeesStyle.doubleColumn}>
                    <div />
                </WidgetBox>

                <WidgetBox title='Violation types placeholder'>
                    <div />
                </WidgetBox>
                <ShadowAIAppUsage/>
                <TableSection />
            </div>
        </div>
    )
}

export default Employees;
