/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const DropdownSelectStyle = {
  self: css``,
  valuesContainer: css`
    display: flex;
    gap: 4px;
    overflow: hidden;
  `,
};
