/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { DropdownSelect, ChipsInput, Tooltip, Icon, AutocompleteSelect, PSMuiDateRangePicker } from '../../../../ui-kit';
import 'react-toastify/dist/ReactToastify.css';
import { css } from "@emotion/react";

import {
    Action,
    OperatorKey,
    FilterOperatorValue,
    Pagination,
    SortDirection,
    SortByFields,
    TextType as TextTypeEnum,
    TextRedactionModes,
    InputMaybe,
    FilterInput,
    FilterValuesFields,
} from '../../../../gql/generated/graphql';

import AlertsLogGrid from "../../../AlertsLogsPage/AlertsLogGrid";

import { useQuery } from "@tanstack/react-query";
import { graphql, createGraphQLClient } from "../../../../gql";
import { Controller, useForm } from 'react-hook-form';
import { useStateWithParams } from '../../../../hooks';
import { dateStoreDefault, DateTypeSelection, TPredefinedDateOrTimeSelection, useDateContext } from '../../../../contexts';
import ActivityMonitorDrawer from '../../../AlertsLogsPage/AcitivityMonitorDrawer/ActivityMonitorDrawer';
import { cleanFilter } from '../../../../utils';
import { IconButton } from "@mui/material";


const queryConfiguration = graphql(`
    query Configuration {
        configuration {
            id
            textRedactionMode
            protectApiQuota {
                remaining
            }
            loggingFrequencyInSeconds
        }
    }
`);

const logsQuery = graphql(`
    query LogsQuery($filter: FilterInput, $pagination: PaginationInput!, $sort: SortInput) {
        logs(options: {pagination: $pagination, filter: $filter, sort: $sort}) {
            logs {
                action
                id
                isValid
                risk
                text
                textType
                time
                user
                userGroups
                appName
                applicationId
                applicationType
                conversationId
                country
                ipAddress
                latency
                modifiedText
                policyName
                promptResponseId
                systemPrompt
                violatingFindings
                violations
                extensionData
                sourceData
                genAiApplication
                isError
            }
            pagination {
                currentPage
                itemsPerPage
                nextPage
                prevPage
                totalCount
                totalPages
            }
        }
    }
`);

type IProps = {};

const dummyData = [
    {
        "action": "Block",
        "id": "dbeb1de2-a882-4360-850a-138d82651e0c",
        "isValid": false,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-26T09:41:50.105Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "de1cdabb-0620-4890-b6e6-3b21bcc44149",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 26500,
        "modifiedText": "simply respond with the text Blocked due to policy violations",
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "b844086f-17f0-4ba7-8387-2d12a12ac25d",
        "systemPrompt": null,
        "violatingFindings": {
            "Sensitive Data": [
                {
                    "entity": "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
                    "entity_type": "CRYPTO",
                    "score": "1.0",
                    "categories": [
                        "PCI",
                        "PII",
                        "GDPR"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "374245455400126",
                    "entity_type": "CREDIT_CARD",
                    "score": "1.0",
                    "categories": [
                        "PCI",
                        "PII",
                        "GDPR",
                        "CCPA"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "100.20.30.1",
                    "entity_type": "IP_ADDRESS",
                    "score": "0.95",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "(415) 454-0132",
                    "entity_type": "PHONE_NUMBER",
                    "score": "0.9",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "111-22-3333",
                    "entity_type": "US_SSN",
                    "score": "0.85",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "123456789",
                    "entity_type": "US_BANK_NUMBER",
                    "score": "0.62",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "987654321",
                    "entity_type": "US_BANK_NUMBER",
                    "score": "0.62",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "jane.doe@email.com",
                    "entity_type": "EMAIL_ADDRESS",
                    "score": "0.6",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI",
                        "COPPA"
                    ],
                    "sanitized_entity": null
                }
            ]
        },
        "violations": [
            "Sensitive Data"
        ],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Block",
        "id": "67d2d661-6c08-499c-a85d-c3d57753b7b9",
        "isValid": false,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-25T15:56:14.519Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "569edf6c-9204-41eb-8d84-bd7ff986ac6c",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 145,
        "modifiedText": "simply respond with the text Blocked due to policy violations",
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "96fc879a-c245-428c-8986-a0cd9e78efd6",
        "systemPrompt": null,
        "violatingFindings": {
            "Sensitive Data": [
                {
                    "entity": "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
                    "entity_type": "CRYPTO",
                    "score": "1.0",
                    "categories": [
                        "PCI",
                        "PII",
                        "GDPR"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "374245455400126",
                    "entity_type": "CREDIT_CARD",
                    "score": "1.0",
                    "categories": [
                        "PCI",
                        "PII",
                        "GDPR",
                        "CCPA"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "100.20.30.1",
                    "entity_type": "IP_ADDRESS",
                    "score": "0.95",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "(415) 454-0132",
                    "entity_type": "PHONE_NUMBER",
                    "score": "0.9",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "111-22-3333",
                    "entity_type": "US_SSN",
                    "score": "0.85",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "123456789",
                    "entity_type": "US_BANK_NUMBER",
                    "score": "0.62",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "987654321",
                    "entity_type": "US_BANK_NUMBER",
                    "score": "0.62",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "jane.doe@email.com",
                    "entity_type": "EMAIL_ADDRESS",
                    "score": "0.6",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI",
                        "COPPA"
                    ],
                    "sanitized_entity": null
                }
            ]
        },
        "violations": [
            "Sensitive Data"
        ],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Block",
        "id": "9e85f60b-ea86-4fff-92cf-57eb719b41e9",
        "isValid": false,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-25T15:55:57.670Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "2d39f7eb-833f-4007-9527-ca256a8bbc18",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 99137,
        "modifiedText": "simply respond with the text Blocked due to policy violations",
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "48bffa94-d57b-497b-a1b3-698d823bdc49",
        "systemPrompt": null,
        "violatingFindings": {
            "Sensitive Data": [
                {
                    "entity": "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
                    "entity_type": "CRYPTO",
                    "score": "1.0",
                    "categories": [
                        "PCI",
                        "PII",
                        "GDPR"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "374245455400126",
                    "entity_type": "CREDIT_CARD",
                    "score": "1.0",
                    "categories": [
                        "PCI",
                        "PII",
                        "GDPR",
                        "CCPA"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "100.20.30.1",
                    "entity_type": "IP_ADDRESS",
                    "score": "0.95",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "(415) 454-0132",
                    "entity_type": "PHONE_NUMBER",
                    "score": "0.9",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "111-22-3333",
                    "entity_type": "US_SSN",
                    "score": "0.85",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "123456789",
                    "entity_type": "US_BANK_NUMBER",
                    "score": "0.62",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "987654321",
                    "entity_type": "US_BANK_NUMBER",
                    "score": "0.62",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "jane.doe@email.com",
                    "entity_type": "EMAIL_ADDRESS",
                    "score": "0.6",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI",
                        "COPPA"
                    ],
                    "sanitized_entity": null
                }
            ]
        },
        "violations": [
            "Sensitive Data"
        ],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "48f94c54-cc61-4b4c-9c12-3216638af5e5",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-25T15:46:12.491Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "01ace99c-e1d9-443d-87dd-8cf4c8f971c0",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 2865,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "2278cc1b-3149-43a7-b81c-704c12d34430",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "4df4d172-994b-438d-829e-ea0771cafb75",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-25T14:50:48.432Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "18cf0cc1-4bc7-4882-a947-828a8565fe64",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 4851,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "bf66a22c-5ada-4b5b-8b0b-6a29e155791c",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "71a8bf52-b789-43c0-ac40-8ab04e8291d5",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:39:35.630Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "bdedce4d-3ac9-4958-b9ab-77fdd6ad0dfa",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 37189,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "4ce2b175-842e-4297-b3bc-14589fe9cd07",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "47228c66-1328-4840-8635-18990e09b784",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:39:33.060Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "3f023571-63cc-4f6e-9047-0de33675f1f3",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 102095,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "818b5f1b-6027-4176-95cd-36b9c637ba4b",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "a005a7ae-1097-441c-a116-36a76d8df92e",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:35:53.021Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "1c24b09e-c79f-485f-87c4-b498f72e922b",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 10481,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "05dd016e-72c2-483a-8ffd-1f2a99e326ce",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "b226dabe-a8e6-4d02-8256-c5a538f05c6e",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:32:27.925Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "f3d99048-3c64-4cc4-8eb6-89b3690c26ce",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 20227,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "8ddc5509-8f96-4193-9392-3b0bdf6cc1d8",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "75e5b48a-1b50-4a64-b6a7-54b398d9e9d6",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:32:27.904Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "b73d76d5-5fb0-4885-8985-ff96b300e43a",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 85896,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "efa5d174-9446-4aa3-a20d-5ed25e6617eb",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "94035e4e-7325-499b-a3f0-39ddd5b6d669",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:30:07.177Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "77c77c84-a868-4f1e-b6ea-b42318431c67",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 753,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "9a8257cd-cd3b-46b0-b818-ff1aba313c1e",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "52948acd-d5ac-41f0-8d79-c033e7c92f34",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:30:02.070Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "0c9db473-7169-441f-820b-2601308b907b",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 42925,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "423f2296-3aca-49e2-861c-4011df580cb5",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "119b0363-c8ca-422a-af6f-34ad2d02092c",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:30:01.157Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "f9e8da29-15b9-4845-a24b-186792504025",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 106975,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "2936fbdc-bdee-4d0c-971f-e2660cc88487",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "e0ffb265-13fc-4ebe-a5b0-8053b2bfd345",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:26:19.937Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "06b7f8f1-5c84-450d-a3eb-9a0414ec0465",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 211,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "69460713-1211-48bc-84b0-61c7ad1ab48f",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "ec543419-6d3f-4ec1-8d1d-9b58a908e0fb",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:26:11.612Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "0e55fe3f-5c5c-45a8-aa22-5b1de90eb4ea",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 213,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "97fffaaf-2051-46f3-b277-6d9667accae1",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "e9f4c63f-3e09-4732-bb16-408ecb4697f7",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:23:56.279Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "f947d654-e437-4040-8166-b6ad22d9e869",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 763,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "b9cda453-bef4-4710-8e08-0fa18a577247",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "795507dc-53c0-4ff3-9969-193b068d3c5f",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:23:17.726Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "ff562422-8908-487f-9ac6-48a97915eaeb",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 1693,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "6846f428-843d-476c-8692-4b428f872d14",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "d2337682-1713-43bb-9ac0-f34292f8a98f",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:21:01.084Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "789cf618-5632-4050-8d5c-d537ff918b33",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 840,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "512d725a-e441-4194-be6c-790e83b5e735",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Block",
        "id": "49237ea1-a022-4b88-a426-c77b746aff06",
        "isValid": false,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:20:54.851Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "5508c342-47f7-4310-836d-c3cc1c27784d",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 1138,
        "modifiedText": "simply respond with the text Blocked due to policy violations",
        "policyName": "Default Homegrown Apps Policy [test]",
        "promptResponseId": "83f7fe46-8685-407e-adcd-497ff7cdb0a3",
        "systemPrompt": "You are a cyber security specialist, providing guidance on securing LLM based apps according to the OWASP Top 10 for LLMs.",
        "violatingFindings": {},
        "violations": [
            "Prompt Injection Engine"
        ],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "88e66eed-bff0-420a-a815-ed18c9e67859",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:19:40.382Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "574ff480-c161-4d84-b473-1b56eb688bb3",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 753,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "6e85c0c6-2e2d-4bc2-b09d-c8963f88fcf4",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "e39be8a9-594e-40b1-8e5b-3297292fb82f",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:17:43.163Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "b57ba570-6ba1-4d26-a27c-999f6f171a39",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 1050,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "0940131a-6769-46b3-abc7-bcf28ad1efdc",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "d872f88a-a219-44c5-b6ca-dd47a2192481",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:11:57.907Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "a8e5f432-09e4-47aa-9c89-5e0fe505f893",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 1503,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "3a861ade-d431-4b12-bdca-73133bd3f712",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Block",
        "id": "ae1cf8fb-0d68-4f5e-b00d-b2c2dfb29446",
        "isValid": false,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:11:56.587Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "f34d98b3-9a76-48c1-88db-901d7ec7a7e3",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 232185,
        "modifiedText": "simply respond with the text Blocked due to policy violations",
        "policyName": "Default Homegrown Apps Policy [test]",
        "promptResponseId": "84b368a2-dc10-42c8-94cd-590b5ce9c333",
        "systemPrompt": "You are a cyber security specialist, providing guidance on securing LLM based apps according to the OWASP Top 10 for LLMs.",
        "violatingFindings": {},
        "violations": [
            "Prompt Injection Engine"
        ],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "9cfb6486-6119-48ee-9b0a-20d91fb9736d",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:11:56.301Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "96ebe0e4-3ead-4988-b532-76a80e66c115",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 264405,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "c53e4c94-4335-4937-b4db-636fcc16dfc3",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Block",
        "id": "dbeb1de2-a882-4360-850a-138d82651e0c",
        "isValid": false,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-26T09:41:50.105Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "de1cdabb-0620-4890-b6e6-3b21bcc44149",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 26500,
        "modifiedText": "simply respond with the text Blocked due to policy violations",
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "b844086f-17f0-4ba7-8387-2d12a12ac25d",
        "systemPrompt": null,
        "violatingFindings": {
            "Sensitive Data": [
                {
                    "entity": "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
                    "entity_type": "CRYPTO",
                    "score": "1.0",
                    "categories": [
                        "PCI",
                        "PII",
                        "GDPR"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "374245455400126",
                    "entity_type": "CREDIT_CARD",
                    "score": "1.0",
                    "categories": [
                        "PCI",
                        "PII",
                        "GDPR",
                        "CCPA"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "100.20.30.1",
                    "entity_type": "IP_ADDRESS",
                    "score": "0.95",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "(415) 454-0132",
                    "entity_type": "PHONE_NUMBER",
                    "score": "0.9",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "111-22-3333",
                    "entity_type": "US_SSN",
                    "score": "0.85",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "123456789",
                    "entity_type": "US_BANK_NUMBER",
                    "score": "0.62",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "987654321",
                    "entity_type": "US_BANK_NUMBER",
                    "score": "0.62",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "jane.doe@email.com",
                    "entity_type": "EMAIL_ADDRESS",
                    "score": "0.6",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI",
                        "COPPA"
                    ],
                    "sanitized_entity": null
                }
            ]
        },
        "violations": [
            "Sensitive Data"
        ],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Block",
        "id": "67d2d661-6c08-499c-a85d-c3d57753b7b9",
        "isValid": false,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-25T15:56:14.519Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "569edf6c-9204-41eb-8d84-bd7ff986ac6c",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 145,
        "modifiedText": "simply respond with the text Blocked due to policy violations",
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "96fc879a-c245-428c-8986-a0cd9e78efd6",
        "systemPrompt": null,
        "violatingFindings": {
            "Sensitive Data": [
                {
                    "entity": "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
                    "entity_type": "CRYPTO",
                    "score": "1.0",
                    "categories": [
                        "PCI",
                        "PII",
                        "GDPR"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "374245455400126",
                    "entity_type": "CREDIT_CARD",
                    "score": "1.0",
                    "categories": [
                        "PCI",
                        "PII",
                        "GDPR",
                        "CCPA"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "100.20.30.1",
                    "entity_type": "IP_ADDRESS",
                    "score": "0.95",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "(415) 454-0132",
                    "entity_type": "PHONE_NUMBER",
                    "score": "0.9",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "111-22-3333",
                    "entity_type": "US_SSN",
                    "score": "0.85",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "123456789",
                    "entity_type": "US_BANK_NUMBER",
                    "score": "0.62",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "987654321",
                    "entity_type": "US_BANK_NUMBER",
                    "score": "0.62",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "jane.doe@email.com",
                    "entity_type": "EMAIL_ADDRESS",
                    "score": "0.6",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI",
                        "COPPA"
                    ],
                    "sanitized_entity": null
                }
            ]
        },
        "violations": [
            "Sensitive Data"
        ],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Block",
        "id": "9e85f60b-ea86-4fff-92cf-57eb719b41e9",
        "isValid": false,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-25T15:55:57.670Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "2d39f7eb-833f-4007-9527-ca256a8bbc18",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 99137,
        "modifiedText": "simply respond with the text Blocked due to policy violations",
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "48bffa94-d57b-497b-a1b3-698d823bdc49",
        "systemPrompt": null,
        "violatingFindings": {
            "Sensitive Data": [
                {
                    "entity": "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
                    "entity_type": "CRYPTO",
                    "score": "1.0",
                    "categories": [
                        "PCI",
                        "PII",
                        "GDPR"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "374245455400126",
                    "entity_type": "CREDIT_CARD",
                    "score": "1.0",
                    "categories": [
                        "PCI",
                        "PII",
                        "GDPR",
                        "CCPA"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "100.20.30.1",
                    "entity_type": "IP_ADDRESS",
                    "score": "0.95",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "(415) 454-0132",
                    "entity_type": "PHONE_NUMBER",
                    "score": "0.9",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "111-22-3333",
                    "entity_type": "US_SSN",
                    "score": "0.85",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "123456789",
                    "entity_type": "US_BANK_NUMBER",
                    "score": "0.62",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "987654321",
                    "entity_type": "US_BANK_NUMBER",
                    "score": "0.62",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI"
                    ],
                    "sanitized_entity": null
                },
                {
                    "entity": "jane.doe@email.com",
                    "entity_type": "EMAIL_ADDRESS",
                    "score": "0.6",
                    "categories": [
                        "PII",
                        "GDPR",
                        "CCPA",
                        "PHI",
                        "COPPA"
                    ],
                    "sanitized_entity": null
                }
            ]
        },
        "violations": [
            "Sensitive Data"
        ],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "48f94c54-cc61-4b4c-9c12-3216638af5e5",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-25T15:46:12.491Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "01ace99c-e1d9-443d-87dd-8cf4c8f971c0",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 2865,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "2278cc1b-3149-43a7-b81c-704c12d34430",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "4df4d172-994b-438d-829e-ea0771cafb75",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-25T14:50:48.432Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "18cf0cc1-4bc7-4882-a947-828a8565fe64",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 4851,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "bf66a22c-5ada-4b5b-8b0b-6a29e155791c",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "71a8bf52-b789-43c0-ac40-8ab04e8291d5",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:39:35.630Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "bdedce4d-3ac9-4958-b9ab-77fdd6ad0dfa",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 37189,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "4ce2b175-842e-4297-b3bc-14589fe9cd07",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "47228c66-1328-4840-8635-18990e09b784",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:39:33.060Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "3f023571-63cc-4f6e-9047-0de33675f1f3",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 102095,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "818b5f1b-6027-4176-95cd-36b9c637ba4b",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "a005a7ae-1097-441c-a116-36a76d8df92e",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:35:53.021Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "1c24b09e-c79f-485f-87c4-b498f72e922b",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 10481,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "05dd016e-72c2-483a-8ffd-1f2a99e326ce",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "b226dabe-a8e6-4d02-8256-c5a538f05c6e",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:32:27.925Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "f3d99048-3c64-4cc4-8eb6-89b3690c26ce",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 20227,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "8ddc5509-8f96-4193-9392-3b0bdf6cc1d8",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "75e5b48a-1b50-4a64-b6a7-54b398d9e9d6",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:32:27.904Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "b73d76d5-5fb0-4885-8985-ff96b300e43a",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 85896,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "efa5d174-9446-4aa3-a20d-5ed25e6617eb",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "94035e4e-7325-499b-a3f0-39ddd5b6d669",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:30:07.177Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "77c77c84-a868-4f1e-b6ea-b42318431c67",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 753,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "9a8257cd-cd3b-46b0-b818-ff1aba313c1e",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "52948acd-d5ac-41f0-8d79-c033e7c92f34",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:30:02.070Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "0c9db473-7169-441f-820b-2601308b907b",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 42925,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "423f2296-3aca-49e2-861c-4011df580cb5",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "119b0363-c8ca-422a-af6f-34ad2d02092c",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:30:01.157Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "f9e8da29-15b9-4845-a24b-186792504025",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 106975,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "2936fbdc-bdee-4d0c-971f-e2660cc88487",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "e0ffb265-13fc-4ebe-a5b0-8053b2bfd345",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:26:19.937Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "06b7f8f1-5c84-450d-a3eb-9a0414ec0465",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 211,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "69460713-1211-48bc-84b0-61c7ad1ab48f",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "ec543419-6d3f-4ec1-8d1d-9b58a908e0fb",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:26:11.612Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "0e55fe3f-5c5c-45a8-aa22-5b1de90eb4ea",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 213,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "97fffaaf-2051-46f3-b277-6d9667accae1",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "e9f4c63f-3e09-4732-bb16-408ecb4697f7",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:23:56.279Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "f947d654-e437-4040-8166-b6ad22d9e869",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 763,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "b9cda453-bef4-4710-8e08-0fa18a577247",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "795507dc-53c0-4ff3-9969-193b068d3c5f",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:23:17.726Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "ff562422-8908-487f-9ac6-48a97915eaeb",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 1693,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "6846f428-843d-476c-8692-4b428f872d14",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "d2337682-1713-43bb-9ac0-f34292f8a98f",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:21:01.084Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "789cf618-5632-4050-8d5c-d537ff918b33",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 840,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "512d725a-e441-4194-be6c-790e83b5e735",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Block",
        "id": "49237ea1-a022-4b88-a426-c77b746aff06",
        "isValid": false,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:20:54.851Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "5508c342-47f7-4310-836d-c3cc1c27784d",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 1138,
        "modifiedText": "simply respond with the text Blocked due to policy violations",
        "policyName": "Default Homegrown Apps Policy [test]",
        "promptResponseId": "83f7fe46-8685-407e-adcd-497ff7cdb0a3",
        "systemPrompt": "You are a cyber security specialist, providing guidance on securing LLM based apps according to the OWASP Top 10 for LLMs.",
        "violatingFindings": {},
        "violations": [
            "Prompt Injection Engine"
        ],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "88e66eed-bff0-420a-a815-ed18c9e67859",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:19:40.382Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "574ff480-c161-4d84-b473-1b56eb688bb3",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 753,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "6e85c0c6-2e2d-4bc2-b09d-c8963f88fcf4",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "e39be8a9-594e-40b1-8e5b-3297292fb82f",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:17:43.163Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "b57ba570-6ba1-4d26-a27c-999f6f171a39",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 1050,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "0940131a-6769-46b3-abc7-bcf28ad1efdc",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "d872f88a-a219-44c5-b6ca-dd47a2192481",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:11:57.907Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "a8e5f432-09e4-47aa-9c89-5e0fe505f893",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 1503,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "3a861ade-d431-4b12-bdca-73133bd3f712",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Block",
        "id": "ae1cf8fb-0d68-4f5e-b00d-b2c2dfb29446",
        "isValid": false,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:11:56.587Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "f34d98b3-9a76-48c1-88db-901d7ec7a7e3",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 232185,
        "modifiedText": "simply respond with the text Blocked due to policy violations",
        "policyName": "Default Homegrown Apps Policy [test]",
        "promptResponseId": "84b368a2-dc10-42c8-94cd-590b5ce9c333",
        "systemPrompt": "You are a cyber security specialist, providing guidance on securing LLM based apps according to the OWASP Top 10 for LLMs.",
        "violatingFindings": {},
        "violations": [
            "Prompt Injection Engine"
        ],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    },
    {
        "action": "Log",
        "id": "9cfb6486-6119-48ee-9b0a-20d91fb9736d",
        "isValid": true,
        "risk": "Low",
        "text": null,
        "textType": "Prompt",
        "time": "2024-11-20T16:11:56.301Z",
        "user": "adam@prompt.security",
        "userGroups": null,
        "appName": "Test",
        "applicationId": "51c65f40-b8b2-4b02-b689-fb3da6a1cc24",
        "applicationType": "SHADOW",
        "conversationId": "96ebe0e4-3ead-4988-b532-76a80e66c115",
        "country": null,
        "ipAddress": "172.18.0.1",
        "latency": 264405,
        "modifiedText": null,
        "policyName": "Default Employees Policy [test]",
        "promptResponseId": "c53e4c94-4335-4937-b4db-636fcc16dfc3",
        "systemPrompt": null,
        "violatingFindings": {},
        "violations": [],
        "extensionData": null,
        "sourceData": null,
        "genAiApplication": null,
        "isError": false
    }
]

const dummyPagination = {
    "currentPage": 1,
    "itemsPerPage": 50,
    "nextPage": null,
    "prevPage": null,
    "totalCount": 24,
    "totalPages": 1
}

const FakeTable: React.FC<IProps> = (props) => {
    const { } = props;

    const [rowIndexViewTextRequestModal, setRowIndexViewTextRequestModal] = useState<number | null>(null);
    const [textCacheMap, setTextCacheMap] = useState<Map<string, string>>(new Map());

    const { control, watch, getValues: getFiltersValues } = useForm<FilterOperatorValue>()

    const [isFirstTimeFetched, setIsFirstTimeFetched] = useState(false);
    const [rowIndex, setRowIndex] = useState<number | null>(null);

    const [viewMode, setViewMode] = useStateWithParams<'alerts' | 'all'>('alerts', 'all');
    const [currentPage, setCurrentPage] = useStateWithParams<number>('currentPage', 1);
    const [itemsPerPage, setItemsPerPage] = useStateWithParams<number>('itemsPerPage', 50);
    const [sortBy, setSortBy] = useStateWithParams<SortByFields>('sortBy', SortByFields.Time);
    const [sortDirection, setSortDirection] = useStateWithParams<SortDirection>('sortDirection', SortDirection.Descending);

    const [activityId, setActivityId] = useStateWithParams<Array<string>>('activityId', []);

    const [user, setUser] = useStateWithParams<Array<string>>('user', []);
    const [isUserExclude, setIsUserExclude] = useStateWithParams<boolean>('isUserExclude', false);

    const [appName, setAppName] = useStateWithParams<Array<string>>('appName', []);
    const [isAppNameExclude, setIsAppNameExclude] = useStateWithParams<boolean>('isAppNameExclude', false);

    const [genAiApplicationName, setGenAiApplicationName] = useStateWithParams<Array<string>>('genAiApplicationName', []);
    const [isGenAiApplicationNameExclude, setIsGenAiApplicationNameExclude] = useStateWithParams<boolean>('isGenAiApplicationNameExclude', false);

    const [userGroups, setUserGroups] = useStateWithParams<Array<string>>('userGroups', []);
    const [isUserGroupsExclude, setIsUserGroupsExclude] = useStateWithParams<boolean>('isUserGroupsExclude', false);

    const [violations, setViolations] = useStateWithParams<Array<string>>('violations', []);
    const [isViolationsExclude, setIsViolationsExclude] = useStateWithParams<boolean>('isViolationsExclude', false);

    const [actions, setActions] = useStateWithParams<Array<Action>>('action', []);
    const [isActionsExclude, setIsActionsExclude] = useStateWithParams<boolean>('isActionsExclude', false);

    const [textType, setTextType] = useStateWithParams<Array<TextTypeEnum>>('textType', []);
    const [isTextTypeExclude, setIsTextTypeExclude] = useStateWithParams<boolean>('isTextTypeExclude', false);

    const { date, setDate } = useDateContext();
    const [paramsDate, setParamsDates] = useStateWithParams<[Date, Date]>('dates', date.type === 'absolute' ? date.dates : dateStoreDefault.logs.dates);
    const [paramsDatePeriod, setParamsDatePeriod] = useStateWithParams<TPredefinedDateOrTimeSelection | 'custom'>('period', date.type === 'relative' ? date.period : 'last7days');
    const [paramsDateType, setParamsDateType] = useStateWithParams<keyof typeof DateTypeSelection>('dateType', date.type === 'absolute' ? 'absolute' : 'relative');

    useEffect(() => {
        if (date.type === 'absolute') {
            setParamsDates(date.dates);
            setParamsDatePeriod(null as any);
        } else if (date.type === 'relative') {
            setParamsDatePeriod(date.period);
            setParamsDates(null as any);
        }

        setParamsDateType(date.type);
    }, [date, setParamsDates, setParamsDatePeriod, setParamsDateType])

    const [pagination, setPagination] = useState<Pagination>({
        currentPage,
        itemsPerPage,
        nextPage: null,
        prevPage: null,
        totalCount: 0,
        totalPages: 0
    });


    useEffect(() => {
        if (isFirstTimeFetched) setCurrentPage(1);
    }, [date, itemsPerPage, textType, sortBy, sortDirection, viewMode, actions, activityId, user, appName, userGroups, violations, genAiApplicationName, isActionsExclude, isTextTypeExclude, isUserExclude, isAppNameExclude, isUserGroupsExclude, isViolationsExclude, isGenAiApplicationNameExclude])

    const { data: logsData, refetch, status, isFetching } = useQuery({
        queryKey: ['lies'],
        queryFn: async ({ signal }) => {
            const baseFilter: InputMaybe<FilterInput> = {
                key: OperatorKey.And,
                value: [
                    {
                        operator: {
                            key: OperatorKey.And,
                            value: [
                                {
                                    time: {
                                        from: date.dates[0].toISOString(),
                                        to: date.dates[1].toISOString()
                                    },
                                    isValid: viewMode === 'alerts' ? false : undefined,
                                    id: activityId.length > 0 ? activityId : undefined,
                                }
                            ]
                        }
                    },
                ]
            }

            try {
                const client = createGraphQLClient(signal); // @ts-ignore
                const { logs } = await client.request(logsQuery, {
                    // filter: cleanFilter(baseFilter),
                    sort: {
                        by: sortBy,
                        direction: sortDirection
                    },
                    pagination: {
                        pageNumber: currentPage,
                        pageSize: itemsPerPage
                    }
                })

                setPagination(logs.pagination);

                if (!isFirstTimeFetched) {
                    setIsFirstTimeFetched(true);
                }

                return logs.logs;
            } catch (error) {
                console.log('-----', error)
                throw new Error();
            }
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    const { data: configuration } = useQuery(
        {
            queryKey: ["configuration"],
            queryFn: async ({ signal }) => {
                const client = createGraphQLClient(signal);
                const { configuration } = await client.request(queryConfiguration);
                return configuration
            },
        }
    )

    const handleRefresh = () => {
        if (paramsDateType === 'absolute') {
            setDate(prev => ({
                ...prev,
                type: 'absolute',
                dates: paramsDate,
                period: paramsDatePeriod as 'custom',
            }));
        } else if (paramsDateType === 'relative') {
            setDate(prev => ({
                ...prev,
                type: 'relative',
                dates: paramsDate,
                period: paramsDatePeriod as TPredefinedDateOrTimeSelection,
            }));
        }

        refetch();
    }

    watch();

    const isDrawerOpen = rowIndex !== null;
    const isLoading = status === 'pending' || isFetching;
    const isError = status === 'error';

    const isTextHidden = configuration?.textRedactionMode === TextRedactionModes.HideAll;

    return (
        <div css={css`display: flex; flex-direction: column; height: 100%;`}>
            <AlertsLogGrid // @ts-ignore
                rowsData={dummyData}
                pagination={dummyPagination}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                isLoading={false}
                isError={isError}
                rowIndex={rowIndex}
                sortDirection={sortDirection}
                sortBy={sortBy}
                isTextHidden={isTextHidden}
                logsAlerts={viewMode}
                textCacheMap={textCacheMap}
                setItemsPerPage={setItemsPerPage}
                setSortBy={setSortBy}
                setSortDirection={setSortDirection}
                setRowIndex={setRowIndex}
                setTextCacheMap={setTextCacheMap}
                refetchTableData={refetch}
                rowIndexViewTextRequestModal={rowIndexViewTextRequestModal}
                setRowIndexViewTextRequestModal={setRowIndexViewTextRequestModal}
            />
        </div>
    )
}

export default FakeTable;