import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Text } from '../../../ui-kit';
import { css } from '@emotion/react';

type ConfigurationItemProps = {
  configurationTitle: string;
  configurationHelpText: string;
  children: JSX.Element;
};

export const ConfigurationItem = ({ configurationTitle, configurationHelpText, children }: ConfigurationItemProps) => {

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Text variant='bold'>{configurationTitle}</Text>
        <Text textCss={css`max-width: 85%;`}>{configurationHelpText}</Text>
      </Box>
      <Box>
        <Grid container alignItems="center">
          <Grid item xs>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}