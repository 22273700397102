interface ProgressCallback {
  (progress: number): void;
}

export const downloadFileWithProgress = async (
  url: string,
  filename: string,
  onProgress?: ProgressCallback
) => {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

    const contentLength = response.headers.get('content-length');
    const total = parseInt(contentLength || '0', 10);
    const reader = response.body!.getReader();
    const chunks: Uint8Array[] = [];

    let receivedLength = 0;

    while(true) {
      const {done, value} = await reader.read();

      if (done) break;

      chunks.push(value);
      receivedLength += value.length;

      if (onProgress) {
        const progress = (receivedLength / total) * 100;
        onProgress(progress);
      }
    }

    const blob = new Blob(chunks);

    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(a);

    return blob;
  } catch (error) {
    throw error;
  }
};

