/** @jsxImportSource @emotion/react */
import React from 'react';
import { EmployeesExtensionSettingsStyle } from './EmployeesExtensionSettings.css';
import { FileUploadInput, Icon, PSBox, PSButton, PSError, PSFormControlLabel, Text } from '../../../ui-kit';
import { graphql } from '../../../gql';
import { useGraphQL, useGraphQLMutation } from '../../../hooks';
import { Checkbox, IconButton, Radio, RadioGroup, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { CoachAwareness, ExtensionConfiguration, SendPromptsModes } from '../../../gql/generated/graphql';
import { toast } from 'react-toastify';

const getExtensionSettingsQuery = graphql(`
    query GetExtensionSettings {
        extensionConfiguration {
            id
            blockChatGPTTraining
            blockChatGPTMemory
            blockChatGPTFiles
            blockPersonalAccounts
            blockChatGPTShare
            sendPromptsMode
            workProfileDomain
            customGuidelinesLink
            customImage
            customMessageBlockPage
            customMessageCoachAwareness
            customMessageDomainUsageWarning
            enforceChatGPTWorkspace
            inspectFiles
            inspectResponses
            enforceChatGPTTempChat
            isExtensionDisabled
            failClose
            coachAwareness
        }
    }
`)

const updateExtensionSettingsMutation = graphql(`
        mutation UpdateExtensionConfiguration($input: updateExtensionConfigurationInput!) {
            updateExtensionConfiguration(input: $input) {
                id
            }
        }
`)
type IProps = {};

const EmployeesExtensionSettings: React.FC<IProps> = (props) => {
    const { } = props;

    const { control, formState, reset, watch } = useForm<ExtensionConfiguration>({
        mode: 'onChange'
    });
    const values = watch();

    const extensionSettings = useGraphQL({
        document: getExtensionSettingsQuery,
        onSuccess: (data) => {
            reset(data.extensionConfiguration)
        },
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchInterval: false,
    })

    const updateExtensionSettings = useGraphQLMutation({
        document: updateExtensionSettingsMutation,
        onSuccess: () => {
            extensionSettings.refetch();
            toast.success('Settings updated successfully');
        }
    })

    const handleCancel = () => {
        reset()
    }

    const handleSave = async () => {
        await updateExtensionSettings.mutateAsync({ input: { ...values } })
    }

    if (extensionSettings.isLoading || extensionSettings.isRefetching) return null;

    if (extensionSettings.isError) return <PSError message='Failed to fetch extension settings' />

    return (
        <React.Fragment>
            <div css={EmployeesExtensionSettingsStyle.self}>
                <div css={EmployeesExtensionSettingsStyle.headerContainer}>
                    <div>
                        <Text variant='header2'>Extension settings</Text>
                        <Text variant='small' color='black-70'>Configurable setting for managing the browser extension</Text>
                    </div>
                </div>

                <PSBox padding={30}>
                    <div css={EmployeesExtensionSettingsStyle.titleContainer}>
                        <Icon iconName='PSShadowAiIcon' color='black-70' />
                        <Text variant='bold'>Shadow AI Protection</Text>
                    </div>
                    <div css={EmployeesExtensionSettingsStyle.formContainer}>
                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <Text variant='bold'>Fail Open/Close</Text>
                                <Text variant='small' color='black-70'>Block (Fail Closed) or Allow (Fail Open) prompts in case of extension inspection errors.</Text>
                            </div>
                            <Controller
                                control={control}
                                defaultValue={values.failClose}
                                name='failClose'
                                render={({ field }) => (
                                    <RadioGroup
                                        row
                                        {...field}
                                        value={field.value ? 1 : 0}
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            field.onChange(value === 1);
                                        }}
                                    >
                                        <PSFormControlLabel value={0} control={<Radio size='small' />} label="Fail Open" />
                                        <PSFormControlLabel value={1} control={<Radio size='small' />} label="Fail Close" />
                                    </RadioGroup>
                                )}
                            />
                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <Text variant='bold'>Send Prompts Mode</Text>
                                <Text variant='small' color='black-70'>Specify when to send prompts to the user → Specify when to send user prompts to the GenAI app.</Text>
                            </div>
                            <Controller
                                name={'sendPromptsMode'}
                                defaultValue={values.sendPromptsMode}
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        row
                                        {...field}
                                        value={field.value}
                                    >
                                        <PSFormControlLabel checked={field.value === SendPromptsModes.Always} value={SendPromptsModes.Always} control={<Radio size='small' />} label="Send Always" />
                                        <PSFormControlLabel checked={field.value === SendPromptsModes.PassSso} value={SendPromptsModes.PassSso} control={<Radio size='small' />} label="Send Only If User Pass SSO" />
                                    </RadioGroup>
                                )}
                            />
                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <Text variant='bold'>Bypass inspection for non-work browser profiles</Text>
                                <Text variant='small' color='black-70'>Specify the work domain whose profile should only be inspected. Leave empty to enable inspection for all profiles</Text>
                            </div>
                            <Controller
                                control={control}
                                defaultValue={values.workProfileDomain}
                                name='workProfileDomain'
                                rules={{ pattern: { value: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,6}$/, message: 'Invalid domain' } }}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        {...field}
                                        sx={{ flex: 1, minWidth: 300 }}
                                        size='small'
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                    />
                                )}
                            />
                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <Text variant='bold'>Browser Extension</Text>
                                <Text variant='small' color='black-70'>Enable or disable the browser extension</Text>
                            </div>
                            <Controller
                                control={control}
                                defaultValue={values.isExtensionDisabled}
                                name='isExtensionDisabled'
                                render={({ field }) => (
                                    <RadioGroup
                                        row
                                        {...field}
                                        value={field.value ? 1 : 0}
                                        onChange={(event) => {
                                            const value = Number(event.target.value);
                                            field.onChange(value === 1);
                                        }}
                                    >
                                        <PSFormControlLabel value={0} control={<Radio size='small' />} label="Enable" />
                                        <PSFormControlLabel value={1} control={<Radio size='small' />} label="Disable" />
                                    </RadioGroup>
                                )}
                            />
                        </div>
                    </div>
                </PSBox>

                <PSBox padding={30}>
                    <div css={EmployeesExtensionSettingsStyle.titleContainer}>
                        <Icon iconName='PSChatgptIcon' color='black-70' />
                        <Text variant='bold'>ChatGPT Protection</Text>
                    </div>
                    <div css={EmployeesExtensionSettingsStyle.formContainer}>


                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <div css={EmployeesExtensionSettingsStyle.rowCheckbox}>
                                    <Controller
                                        control={control}
                                        defaultValue={values.blockChatGPTTraining}
                                        name='blockChatGPTTraining'
                                        render={({ field }) => (
                                            <Checkbox
                                                size='small'
                                                checked={field.value} onChange={field.onChange}
                                            />
                                        )}
                                    />
                                    <Text variant='bold'>Block Chat Training</Text>
                                </div>
                                <Text variant='small' color='black-70'>Block employees from enabling ChatGPT training on their chats</Text>

                            </div>
                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <div css={EmployeesExtensionSettingsStyle.rowCheckbox}>
                                    <Controller
                                        control={control}
                                        defaultValue={values.blockChatGPTMemory}
                                        name='blockChatGPTMemory'
                                        render={({ field }) => (
                                            <Checkbox
                                                size='small'
                                                checked={field.value} onChange={field.onChange}
                                            />
                                        )}
                                    />
                                    <Text variant='bold'>Block Chat Memory</Text>
                                </div>
                                <Text variant='small' color='black-70'>Block employees from enabling the “chat memory” feature</Text>

                            </div>
                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <div css={EmployeesExtensionSettingsStyle.rowCheckbox}>
                                    <Controller
                                        control={control}
                                        defaultValue={values.enforceChatGPTTempChat}
                                        name='enforceChatGPTTempChat'
                                        render={({ field }) => (
                                            <Checkbox
                                                size='small'
                                                checked={field.value} onChange={field.onChange}
                                            />
                                        )}
                                    />
                                    <Text variant='bold'>Enforce Temporary Chat</Text>
                                </div>
                                <Text variant='small' color='black-70'>Force usage of temporary chats, which won't appear in history, won't be used for model training, and won't create “memories”.</Text>
                            </div>
                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <Text variant='bold'>Enforce controls over file upload to ChatGPT</Text>
                                <Text variant='small' color='black-70'>Enforce controls over file upload to ChatGPT.</Text>
                            </div>
                            <Controller
                                name={'blockChatGPTFiles'}
                                defaultValue={values.blockChatGPTFiles}
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        row
                                        {...field}
                                        value={field.value}
                                        sx={{ flex: 1 }}
                                    >
                                        <PSFormControlLabel checked={field.value === "off"} value={"off"} control={<Radio size='small' />} label="Allow both file uploads and file integrations" />
                                        <PSFormControlLabel checked={field.value === "integrations"} value={"integrations"} control={<Radio size='small' />} label="Allow file uploads but block file integrations" />
                                        <PSFormControlLabel checked={field.value === "all"} value={"all"} control={<Radio size='small' />} label="Block both file uploads and file integrations" />
                                    </RadioGroup>
                                )}
                            />
                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <Text variant='bold'>Enforce controls over chat sharing in ChatGPT</Text>
                                <Text variant='small' color='black-70'>Enforce controls over chat sharing in ChatGPT.</Text>
                            </div>
                            <Controller
                                name={'blockChatGPTShare'}
                                defaultValue={values.blockChatGPTShare}
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        row
                                        {...field}
                                        value={field.value}
                                    >
                                        <PSFormControlLabel checked={field.value === "off"} value={"off"} control={<Radio size='small' />} label="Allow chat sharing" />
                                        <PSFormControlLabel checked={field.value === "all"} value={"all"} control={<Radio size='small' />} label="Block chat sharing" />
                                    </RadioGroup>
                                )}
                            />
                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <div css={EmployeesExtensionSettingsStyle.rowCheckbox}>
                                    <Controller
                                        control={control}
                                        defaultValue={values.inspectFiles}
                                        name='inspectFiles'
                                        render={({ field }) => (
                                            <Checkbox
                                                size='small'
                                                checked={field.value} onChange={field.onChange}
                                            />
                                        )}
                                    />
                                    <Text variant='bold'>Inspect Uploaded Files</Text>
                                </div>
                                <Text variant='small' color='black-70'>Process uploaded files and redact or block GenAI violations.</Text>
                                <Text variant='small' color='black-70'>This only applies to supported file formats (reach out to us for an updated list of supported file types).</Text>
                            </div>

                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <div css={EmployeesExtensionSettingsStyle.rowCheckbox}>
                                    <Controller
                                        control={control}
                                        defaultValue={values.inspectResponses}
                                        name='inspectResponses'
                                        render={({ field }) => (
                                            <Checkbox
                                                size='small'
                                                checked={field.value} onChange={field.onChange}
                                            />
                                        )}
                                    />
                                    <Text variant='bold'>Log responses</Text>
                                </div>
                                <Text variant='small' color='black-70'>Log responses from ChatGPT.</Text>
                            </div>

                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <Text variant='bold'>Personal Accounts</Text>
                                <Text variant='small' color='black-70'>Allow/Block personal (non-corporate) accounts in ChatGPT</Text>
                            </div>
                            <Controller
                                name={'blockPersonalAccounts'}
                                defaultValue={values.blockPersonalAccounts}
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        row
                                        {...field}
                                        value={field.value}
                                    >
                                        <PSFormControlLabel checked={field.value === "off"} value={"off"} control={<Radio size='small' />} label="Allow" />
                                        <PSFormControlLabel checked={field.value === "files"} value={"files"} control={<Radio size='small' />} label="Only block files" />
                                        <PSFormControlLabel checked={field.value === "all"} value={"all"} control={<Radio size='small' />} label="Completely block" />
                                    </RadioGroup>
                                )}
                            />
                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <Text variant='bold'>Enforce a specific ChatGPT Workspace</Text>
                                <Text variant='small' color='black-70'>Specify the workspace ID (taken from the _account cookie in chat.openai.com) that you wish to enforce for all your employees (they won't be able to use their personal ChatGPT)</Text>
                            </div>
                            <Controller
                                control={control}
                                defaultValue={values.enforceChatGPTWorkspace}
                                name='enforceChatGPTWorkspace'
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ flex: 1, minWidth: 300 }}
                                        size='small'
                                    />
                                )}
                            />
                        </div>
                    </div>
                </PSBox>

                <PSBox padding={30}>
                    <div css={EmployeesExtensionSettingsStyle.titleContainer}>
                        <Icon iconName='PSEducateIcon' color='black-70' />
                        <Text variant='bold'>Employee education on safe usage of genAI</Text>
                    </div>
                    <div css={EmployeesExtensionSettingsStyle.formContainer}>
                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <Text variant='bold'>Customize Block Page Message</Text>
                                <Text variant='small' color='black-70'>A custom message that will be presented to users when the browser extension blocks their access to a domain</Text>
                            </div>
                            <Controller
                                control={control}
                                defaultValue={values.customMessageBlockPage}
                                name='customMessageBlockPage'
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ flex: 1, minWidth: 300 }}
                                        size='small'
                                    />
                                )}
                            />
                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <div css={EmployeesExtensionSettingsStyle.rowCheckbox}>
                                    <Controller
                                        control={control}
                                        defaultValue={values.coachAwareness}
                                        name='coachAwareness'
                                        render={({ field }) => (
                                            <Checkbox
                                                size='small'
                                                checked={field.value === CoachAwareness.All}
                                                onChange={(event) => field.onChange(event.target.checked ? CoachAwareness.All : CoachAwareness.Nothing)}
                                            />
                                        )}
                                    />
                                    <Text variant='bold'>Coach Awareness Message</Text>
                                </div>
                                <Text variant='small' color='black-70'>A custom message that will be presented to users when the browser extension detects a violation</Text>
                            </div>

                            <Controller
                                control={control}
                                defaultValue={values.customMessageCoachAwareness}
                                name='customMessageCoachAwareness'
                                disabled={values.coachAwareness === CoachAwareness.Nothing}
                                render={({ field }) => {
                                    const defaultValue = 'Sharing sensitive data with the chat is dangerous.';
                                    return (
                                        <div css={EmployeesExtensionSettingsStyle.buttonRow}>
                                            <TextField
                                                {...field}
                                                sx={{ flex: 1, minWidth: 300 }}
                                                size='small'
                                            />
                                            <PSButton
                                                variant='filled'
                                                onClick={() => field.onChange(defaultValue)}
                                                disabled={field.value === defaultValue}
                                            >
                                                Restore to default
                                            </PSButton>
                                        </div>
                                    )
                                }}
                            />
                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <Text variant='bold'>Customize Domain Warning Message</Text>
                                <Text variant='small' color='black-70'>A custom message that will be presented to users when the user enters a domain</Text>
                            </div>
                            <Controller
                                control={control}
                                defaultValue={values.customMessageDomainUsageWarning}
                                name='customMessageDomainUsageWarning'
                                render={({ field }) => {
                                    const defaultValue = 'Sharing sensitive data with the chat is dangerous.';
                                    return (
                                        <div css={EmployeesExtensionSettingsStyle.buttonRow}>
                                            <TextField
                                                {...field}
                                                sx={{ flex: 1, minWidth: 300 }}
                                                size='small'
                                            />
                                            <PSButton
                                                variant='filled'
                                                onClick={() => field.onChange(defaultValue)}
                                                disabled={field.value === defaultValue}
                                            >
                                                Restore to default
                                            </PSButton>
                                        </div>
                                    )
                                }}
                            />
                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <Text variant='bold'>Customize Message Guidelines Link</Text>
                                <Text variant='small' color='black-70'>A link that will be presented to users in the messages</Text>
                            </div>
                            <Controller
                                control={control}
                                defaultValue={values.customGuidelinesLink}
                                name='customGuidelinesLink'
                                rules={{ pattern: { value: /^https?:\/\/.*/, message: 'Invalid URL' } }}
                                render={({ field, fieldState }) => {
                                    const defaultValue = 'https://www.prompt.security/blog/extracting-gbs-of-training-data-from-chatgpt/';
                                    return (
                                        <div css={EmployeesExtensionSettingsStyle.buttonRow}>
                                            <TextField
                                                {...field}
                                                sx={{ flex: 1, minWidth: 300 }}
                                                size='small'
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                            />
                                            <PSButton
                                                variant='filled'
                                                onClick={() => field.onChange(defaultValue)}
                                                disabled={field.value === defaultValue}
                                            >
                                                Restore to default
                                            </PSButton>
                                        </div>
                                    )
                                }}
                            />
                        </div>

                        <div css={EmployeesExtensionSettingsStyle.formRow}>
                            <div css={EmployeesExtensionSettingsStyle.formRowLabelContainer}>
                                <Text variant='bold'>Customize Message Image</Text>
                                <Text variant='small' color='black-70'>A custom image that will be presented to users in the messages</Text>
                            </div>
                            <Controller
                                control={control}
                                name="customImage"
                                defaultValue={values.customImage}
                                render={({ field }) => (
                                    <div style={{ alignSelf: 'flex-end' }}>
                                        {field.value ? (
                                            <div css={EmployeesExtensionSettingsStyle.buttonContainer}>
                                                <img
                                                    src={`${field.value}`}
                                                    alt="customize message"
                                                    style={{ objectFit: 'contain', maxWidth: 200, aspectRatio: '2 / 1' }}
                                                />
                                                <IconButton
                                                    css={EmployeesExtensionSettingsStyle.removeButton}
                                                    onClick={() => field.onChange("")}
                                                >
                                                    <Icon iconName='PSCancelIcon' color='black' />
                                                </IconButton>
                                            </div>
                                        ) : (
                                            <FileUploadInput
                                                showPreview
                                                multiple={false}
                                                onFileChange={(file) => {
                                                    if (file) {
                                                        const reader = new FileReader();
                                                        reader.onloadend = () => {
                                                            field.onChange(reader.result);
                                                        };
                                                        reader.readAsDataURL(file);
                                                    }
                                                }}
                                                acceptedFileTypes={{ 'image/*': ['.png', '.jpg', '.jpeg', '.gif'] }}
                                            />
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </PSBox>
            </div>
            <div css={EmployeesExtensionSettingsStyle.footer}>
                <div css={EmployeesExtensionSettingsStyle.actionsContainer}>
                    <PSButton
                        variant='flat'
                        onClick={handleCancel}
                        disabled={updateExtensionSettings.isPending || !formState.isDirty}
                        isLoading={updateExtensionSettings.isPending}
                    >
                        Cancel
                    </PSButton>
                    <PSButton
                        variant='filled'
                        onClick={handleSave}
                        disabled={updateExtensionSettings.isPending || Object.keys(formState.errors).length > 0 || !formState.isDirty}
                        isLoading={updateExtensionSettings.isPending}
                    >
                        Save
                    </PSButton>
                </div>
            </div>
        </React.Fragment>

    )
}

export default EmployeesExtensionSettings;