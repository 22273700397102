import { useEffect, useState } from 'react';
import { IntegrationItem } from '../IntegrationComponents/IntegrationItem';
import { Box } from '@mui/material';
import { graphql, createGraphQLClient } from "../../../gql";
import { useQuery } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LogsTypes, OverridePantherIntegrationInput } from '../../../gql/generated/graphql';
import { EditButtons } from '../../../components/EditButtons';
import { StatusRadio } from '../IntegrationComponents/StatusRadio';
import { PopupMessage } from '../../../components/PopupMessage';
import { TextInput } from '../IntegrationComponents/TextInput';
import { TypeRadio, typeDisplay } from '../IntegrationComponents/TypeRadio';

const HIDDEN_TEXT = '*****';

const queryPantherIntegration = graphql(`
    query PantherIntegration {
        pantherIntegration {
            id
            logsType
            endpointUrl
            authTokenExists
        }
    }
`);

const mutationOverridePantherIntegration = graphql(`
    mutation OverridePantherIntegration($logsType: LogsTypes, $endpointUrl: String, $authToken: String, $isEnabled: Boolean) {
        overridePantherIntegration(
            input: {
                logsType: $logsType
                endpointUrl: $endpointUrl
                authToken: $authToken,
                isEnabled: $isEnabled
            }
        ) {
            id
        }
    }
`);

type IntegrationPantherCardProps = {
    inputIsEnabled: boolean
};


export const IntegrationPantherCard = ({ inputIsEnabled }: IntegrationPantherCardProps) => {
    const { data: pantherIntegration } = useQuery(
        {
            queryKey: ["pantherIntegration"],
            queryFn: async ({ signal }) => {
                const client = createGraphQLClient(signal);
                const { pantherIntegration } = await client.request(queryPantherIntegration);
                return pantherIntegration
            },
        }
    )

    useEffect(() => {
        if (pantherIntegration !== undefined) {
            setEndpointUrlToDisplay(pantherIntegration.endpointUrl);
            setAuthTokenToDisplay(pantherIntegration?.authTokenExists ? HIDDEN_TEXT : null);
            setLogsTypeToDisplay(pantherIntegration.logsType);
            setInputLogsType(pantherIntegration.logsType);
        } else {
            setInputLogsType(LogsTypes.Full);
            setLogsTypeToDisplay(LogsTypes.Full);
        }
    }, [pantherIntegration]);

    const queryClient = useQueryClient();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [isEnabledToDisplay, setIsEnabledToDisplay] = useState<boolean>(inputIsEnabled);
    const [endpointUrlToDisplay, setEndpointUrlToDisplay] = useState<string | undefined | null>();
    const [authTokenToDisplay, setAuthTokenToDisplay] = useState<string | undefined | null>();
    const [logsTypeToDisplay, setLogsTypeToDisplay] = useState<LogsTypes | undefined | null>();
    const [inputLogsType, setInputLogsType] = useState<LogsTypes | undefined | null>();
    const [openSavePopup, setOpenSavePopup] = useState<boolean>(false);
    const [popupText, setPopupText] = useState<string>('');
    const [popupTitle, setPopupTitle] = useState<string>('');

    const overridePantherIntegration = useMutation({
        mutationFn: async (variables: any) => {
            const client = createGraphQLClient();
            await client.request(mutationOverridePantherIntegration, variables);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["pantherIntegration"] });
            queryClient.invalidateQueries({ queryKey: ["integration"] });

            setAuthTokenToDisplay(pantherIntegration?.authTokenExists ? HIDDEN_TEXT : null);
            setPopupText('Your changes have been successfully saved');
            setPopupTitle('Panther Integration Updated');
            setOpenSavePopup(true);
            setEditMode(false);
        },
        onError: (error) => {
            const errorMessage = 'Failed to update Panther Integration';
            setPopupText(errorMessage);
            setPopupTitle('Panther Integration Update Failed');
            setOpenSavePopup(true);
        }
    });

    const checkTextValidity = (value: string | undefined | null, varName: string, required: boolean): boolean => {
        if ((value !== null && value !== undefined && value.trim() === '') ||
            (required === true && (value === null || value === undefined))
        ) {
            setPopupText(`${varName} can't be empty`);
            setPopupTitle('Panther Integration Update Failed');
            setOpenSavePopup(true);
            return false;
        }
        return true;
    }

    const handleSave = () => {
        const isEnabled = (isEnabledToDisplay === true || (isEnabledToDisplay === null && inputIsEnabled === true));
        let overrideData: OverridePantherIntegrationInput = {
            isEnabled: isEnabledToDisplay
        }

        if (isEnabled) {
            if (!checkTextValidity(endpointUrlToDisplay, 'Endpoint URL', true) ||
                !checkTextValidity(authTokenToDisplay, 'Authentication Token', true)
            ) {
                return;
            }

            overrideData.endpointUrl = endpointUrlToDisplay;
            if (authTokenToDisplay && authTokenToDisplay !== HIDDEN_TEXT) {
                overrideData.authToken = authTokenToDisplay;
            }
            overrideData.logsType = logsTypeToDisplay;
        }

        overridePantherIntegration.mutate(overrideData);
    };

    const handleCancel = () => {
        setIsEnabledToDisplay(inputIsEnabled);
        setEndpointUrlToDisplay(pantherIntegration?.endpointUrl);
        setAuthTokenToDisplay(pantherIntegration?.authTokenExists ? HIDDEN_TEXT : null);
        setLogsTypeToDisplay(inputLogsType);
        setEditMode(false);
    };

    const handleEmptyValues = (value: string | undefined | null) => {
        if (value === '') {
            return null;
        }
        return value;
    }

    const handleIntegrationChange = (
        isEnabled: boolean,
        endpointUrl: string | null | undefined,
        authToken: string | null | undefined,
        logsType: LogsTypes | null | undefined,
    ) => {
        endpointUrl = handleEmptyValues(endpointUrl);
        authToken = handleEmptyValues(authToken);

        if (isEnabled !== isEnabledToDisplay) {
            setIsEnabledToDisplay(isEnabled);
        }
        if (endpointUrl !== endpointUrlToDisplay) {
            setEndpointUrlToDisplay(endpointUrl);
        }
        if (authToken !== authTokenToDisplay) {
            setAuthTokenToDisplay(authToken);
        }
        if (logsType !== logsTypeToDisplay) {
            setLogsTypeToDisplay(logsType);
        }

        if (isEnabled !== inputIsEnabled ||
            (endpointUrl !== pantherIntegration?.endpointUrl && !(endpointUrl === null && pantherIntegration?.endpointUrl === undefined)) ||
            (authToken !== HIDDEN_TEXT && !((authToken === null || pantherIntegration?.authTokenExists === undefined) && pantherIntegration === undefined)) ||
            logsType !== inputLogsType
        ) {
            setEditMode(true);
        } else {
            setEditMode(false);
        }
    }

    const handleSavePopupClose = () => {
        setOpenSavePopup(false);
    };

    if (!editMode) {
        if (isEnabledToDisplay !== inputIsEnabled) {
            setIsEnabledToDisplay(inputIsEnabled);
        }
    }

    const handleEnableOnChange = (value: boolean) => {
        setIsEnabledToDisplay(value);
        handleIntegrationChange(value, endpointUrlToDisplay, authTokenToDisplay, logsTypeToDisplay);
    };

    const handleEndpointUrlOnChange = (value: string) => {
        setEndpointUrlToDisplay(value);
        handleIntegrationChange(isEnabledToDisplay, value, authTokenToDisplay, logsTypeToDisplay);
    };

    const handleAuthTokenOnChange = (value: string) => {
        setAuthTokenToDisplay(value);
        handleIntegrationChange(isEnabledToDisplay, endpointUrlToDisplay, value, logsTypeToDisplay);
    };

    const handleLogsTypeOnChange = (value: string) => {
        setLogsTypeToDisplay(value as LogsTypes);
        handleIntegrationChange(isEnabledToDisplay, endpointUrlToDisplay, authTokenToDisplay, value as LogsTypes);
    };

    const logsTypeDisplayText = (type: string): typeDisplay => {
        if (type === LogsTypes.Full) {
            return { text: 'Send Full Log', helpText: 'The full log will be sent to Panther' };
        } else {
            return { text: 'Send Only Metadata', helpText: 'Only metadata info will be sent to Panther' };
        }
    }

    return (
        <div>
            <IntegrationItem>
                <Box>
                    <StatusRadio value={isEnabledToDisplay} handleOnChange={handleEnableOnChange} text='Status' />
                    <TextInput value={endpointUrlToDisplay} title='Endpoint URL' help='Endpoint URL' handleOnChange={handleEndpointUrlOnChange} disable={isEnabledToDisplay === false} multiline={false} />
                    <TextInput value={authTokenToDisplay} title='Authentication Token' help='Authentication Token' handleOnChange={handleAuthTokenOnChange} disable={isEnabledToDisplay === false} multiline={false} />
                    <TypeRadio value={logsTypeToDisplay} title='Logs Type' help='The type of logs that will be sent' handleOnChange={handleLogsTypeOnChange} disable={isEnabledToDisplay === false} types={Object.values(LogsTypes)} typeDisplayText={logsTypeDisplayText} />
                </Box>
            </IntegrationItem>
            <EditButtons sx={{ mt: 6, ml: 3.5, marginBottom: 1 }} editMode={editMode} handleSaveClick={handleSave} handleCancelClick={handleCancel} />
            <PopupMessage title={popupTitle} text={popupText} open={openSavePopup} handlePopupMessageClose={handleSavePopupClose} />
        </div>
    )
};