/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { TextField, TextFieldProps } from '@mui/material';
import { useDebounce } from "react-use";
import EndAdornment from './EndAdornment';
import { PSInputStyles } from './PSInput.css'
import { IconNames } from "../../Icon/Icon";
import { SerializedStyles } from "@emotion/react";

type BaseProps = {
    inputLabel?: string;
    value?: string;
    defaultValue?: string;
    onChange?: (val: string) => void;
    isLoading?: boolean;
    inputCss?: SerializedStyles;
    includeClearButton?: boolean;
    endAdornmentClassName?: string;
    minWidth?: number | string;
    maxWidth?: number | string;
} & Omit<TextFieldProps<'outlined'>, 'variant' | 'onChange'>

type DebounceProps =
    | { isDebounce?: false; debounceTimeout?: never }
    | { isDebounce: true; debounceTimeout: number };

type IconProps =
    | { includeRightIcon?: false; rightIconName?: never }
    | { includeRightIcon: true; rightIconName: IconNames };

type IProps = BaseProps & DebounceProps & IconProps;

const PSInput: React.FC<IProps> = (props) => {
    const {
        onChange,
        inputLabel = '',
        inputCss,
        isLoading,
        debounceTimeout,
        endAdornmentClassName,
        isDebounce = false,
        includeRightIcon = false,
        includeClearButton = true,
        size = 'small',
        value: controlledValue,
        defaultValue = '',
        rightIconName = '',
        minWidth,
        maxWidth,
        ...textFieldProps
    } = props;

    const [internalValue, setInternalValue] = useState<string>(defaultValue);
    const [debouncedValue, setDebouncedValue] = useState<string>(defaultValue);

    const isControlled = controlledValue !== undefined;
    const value = isControlled ? controlledValue : internalValue;

    const [, cancel] = useDebounce(() => {
        if (!isDebounce) return;
        if (debouncedValue?.trim()) {
            onChange?.(debouncedValue);
        } else {
            onChange?.('');
        }
        return () => cancel();
    }, debounceTimeout, [debouncedValue]);

    const localOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue = e.target.value;

        if (!isControlled) {
            setInternalValue(targetValue);
        }

        if (isDebounce) {
            setDebouncedValue(targetValue);
        } else {
            onChange?.(targetValue);
        }
    }

    const onClearClick = () => {
        if (!isControlled) {
            setInternalValue('');
        }
        setDebouncedValue('');
        onChange?.('');
    }

    return (
        <TextField
            {...textFieldProps}
            size={size}
            value={isDebounce ? debouncedValue : value}
            onChange={localOnChange}
            label={inputLabel}
            css={[PSInputStyles.self, inputCss]}
            InputProps={{
                endAdornment: (<EndAdornment
                    isLoading={isLoading}
                    showClear={includeClearButton && Boolean(debouncedValue)}
                    onClick={onClearClick}
                    className={endAdornmentClassName}
                    includeRightIcon={includeRightIcon}
                    iconName={rightIconName}
                />),
                style: { minWidth, maxWidth }
            }}
        />

    )
}

export default PSInput;
