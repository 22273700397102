import React, { useEffect, useState } from "react";
import { Autocomplete, Button, IconButton, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { graphql, createGraphQLClient } from "../../../gql";
import { RuleContentKeys, RuleContentOperators } from "../../../gql/generated/graphql";
import { useQuery } from "@tanstack/react-query";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

type SingleRuleContentProps = {
    content: RuleContent;
    onChange: (content: any) => void;
    validContentKeys: string[];
}

export type RuleContentSingle = {
    key: RuleContentKeys;
    operator: RuleContentOperators;
    value: string[];
}

export type RuleContent = RuleContentSingle[];

type SingleContentProps = {
    contentKey: RuleContentSingle["key"];
    onContentKeyChange: (event: SelectChangeEvent) => void;
    contentOperator: RuleContentSingle["operator"];
    onContentOperatorChange: (event: SelectChangeEvent) => void;
    contentValue: RuleContentSingle["value"]
    onContentValueChange: (event: any, value: string[]) => void;
    validContentKeys: string[];
    onContentAddCondition: (event: any) => void;
    onContentDeleteCondition: (event: any) => void;
    contentIndex: number;
    contentLength: number;
}

const querySuggestRuleContentValues = graphql(`
    query SuggestRuleContentValuesString($key: RuleContentKeys!, $contains: String) {
        suggestRuleContentValuesString(key: $key, contains: $contains)
    }
`);


const SingleContent = ({ contentKey, onContentKeyChange, contentOperator, onContentOperatorChange, contentValue, onContentValueChange, validContentKeys, onContentAddCondition, onContentDeleteCondition, contentIndex, contentLength }: SingleContentProps) => {
    const [inputValue, setInputValue] = useState('');
    const [localOptions, setLocalOptions] = useState<string[]>([]);

    const { data: suggestRuleContentValues } = useQuery(
        {
            queryKey: ["querySuggestRuleContentValues", contentKey, inputValue],
            queryFn: async ({ signal }) => {
                const client = createGraphQLClient(signal);
                const { suggestRuleContentValuesString } = await client.request(querySuggestRuleContentValues, { key: contentKey, contains: inputValue });
                return suggestRuleContentValuesString
            }
        }
    );

    useEffect(() => {
        if (suggestRuleContentValues !== undefined) {
            setLocalOptions(suggestRuleContentValues);
        }
    }, [suggestRuleContentValues])

    const onLocalContentOperatorChange = (event: SelectChangeEvent) => {
        onContentOperatorChange(event);
    }

    const operatorToText = (operator: RuleContentOperators) => {
        switch (operator) {
            case RuleContentOperators.IsOne:
                return 'Is one of the following';
            default:
                return operator;
        }
    }

    const keyToText = (key: RuleContentKeys) => {
        switch (key) {
            case RuleContentKeys.UserEmail:
                return 'User Email';
            case RuleContentKeys.UserGroups:
                return 'User Groups';
            default:
                return key;
        }
    }

    const validOperators = (key: RuleContentKeys): RuleContentOperators[] => {
        switch (key) {
            case RuleContentKeys.UserEmail:
                return [RuleContentOperators.IsOne];
            case RuleContentKeys.UserGroups:
                return [RuleContentOperators.IsOne];
            default:
                return [];
        }
    }

    return (
        <div style={{ display: 'flex', flexGrow: 1, width: '100%', alignItems: 'center', gap: '15px' }}>
            <div style={{ display: 'flex', flexGrow: 1, width: 'min(100%, 200px)', alignItems: 'center', gap: '15px' }}>
                <Select
                    value={contentKey}
                    onChange={onContentKeyChange}
                    size='small'
                    fullWidth
                >
                    {Object.values(RuleContentKeys)
                        .filter(value => validContentKeys.includes(value))
                        .map((value, j) =>
                            <MenuItem key={j} value={value}>{keyToText(value)}</MenuItem>
                        )}
                </Select>
            </div>
            <div style={{ display: 'flex', flexGrow: 1, width: 'min(100%, 200px)', alignItems: 'center', gap: '15px' }}>
                <Select
                    value={contentOperator}
                    onChange={onLocalContentOperatorChange}
                    size='small'
                    fullWidth
                >
                    {Object.values(RuleContentOperators).filter(value => validOperators(contentKey).includes(value)).map((value, j) =>
                        <MenuItem key={j} value={value}>{operatorToText(value)}</MenuItem>
                    )}
                </Select>
            </div>
            <div style={{ display: 'flex', flexGrow: 5, width: 'min(100%, 200px)', alignItems: 'center', gap: '15px' }}>
                <Autocomplete
                    limitTags={7}
                    multiple
                    freeSolo
                    value={contentValue}
                    onChange={onContentValueChange}
                    filterOptions={(x) => x}
                    options={localOptions}
                    getOptionLabel={(option) => option}
                    size="small"
                    fullWidth
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            margin="none"
                        />
                    )}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                />
            </div>
            <Button onClick={onContentAddCondition} disabled={contentIndex !== contentLength - 1}>AND</Button>
            <IconButton size='small' onClick={onContentDeleteCondition} aria-label="delete" disabled={contentLength === 1} style={{ borderRadius: '0' }}>
                <DeleteIcon />
            </IconButton>
        </div>
    )
}


export const SingleRuleContentRegular = ({ content, onChange, validContentKeys }: SingleRuleContentProps) => {
    const onItemChange = (index: number, field: string) => (event: SelectChangeEvent) => {
        const changedContent = content.map((x, i) => i === index ? {
            ...x,
            [field]: event.target.value
        } : x);
        onChange(changedContent);
    };

    const onValueChange = (index: number) => (event: any, value: string[]) => {
        const sortedValue = [...value].sort();
        const changedContent = content.map((x, i) => i === index ? {
            ...x,
            value: sortedValue
        } : x);
        onChange(changedContent);
    };

    const onAddCondition = () => (event: any) => {
        const changedContent: object[] = [...content];
        changedContent.push({
            key: '',
            operator: '',
            value: []
        });
        onChange(changedContent);
    };

    const onDeleteCondition = (index: number) => (event: any) => {
        const changedContent: object[] = [...content];
        changedContent.splice(index, 1);
        onChange(changedContent);
    };

    return (
        <>
            {
                content.map((x, i) =>
                    <SingleContent key={i}
                        contentKey={x.key}
                        contentOperator={x.operator}
                        contentValue={x.value}
                        onContentKeyChange={onItemChange(i, "key")}
                        onContentOperatorChange={onItemChange(i, "operator")}
                        onContentValueChange={onValueChange(i)}
                        validContentKeys={validContentKeys}
                        onContentAddCondition={onAddCondition()}
                        onContentDeleteCondition={onDeleteCondition(i)}
                        contentIndex={i}
                        contentLength={content.length}
                    />
                )
            }
        </>
    )
}