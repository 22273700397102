import { Box, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { graphql, createGraphQLClient } from '../../gql';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { PolicyTypes } from '../../gql/generated/graphql';
import { PSButton, Text } from '../../ui-kit';

const mutationCreateApplicationAndRule = graphql(`
    mutation CreateApplicationAndRule($name: String!, $policyId: ID!) {
        createApplicationAndRule(input: { name: $name, policyId: $policyId }) {
            id
            name
        }
    }
`);

const queryPolices = graphql(`
    query PoliciesType($policyTypes: [String!]!) {
        policies(policyTypes: $policyTypes) {
            id
            name
            policyType
        }
    }   
`);

type ApplicationsAddProps = {
    handlePopupMessage: (title: string, test: string) => void
}

export const ApplicationAdd = ({ handlePopupMessage }: ApplicationsAddProps) => {
    const queryClient = useQueryClient();

    const [name, setName] = useState("");

    const { data: policies } = useQuery(
        {
            queryKey: ["policies", PolicyTypes.Regular, PolicyTypes.CodeAssistant],
            queryFn: async ({ signal }) => {
                const client = createGraphQLClient(signal);
                const { policies } = await client.request(queryPolices, { policyTypes: [PolicyTypes.Regular, PolicyTypes.CodeAssistant] });
                return policies
            }
        }
    );
    useEffect(() => {
        if (policies && policies.length > 0) {
            setPolicyId(policies[0].id);
        }
    }, [policies])

    const [policyId, setPolicyId] = useState("");


    const policyChange = (event: SelectChangeEvent) => {
        setPolicyId(event.target.value as string);
    };

    const createApplicationAndRule = useMutation({
        mutationFn: async (variables: any) => {
            const client = createGraphQLClient();
            await client.request(mutationCreateApplicationAndRule, variables);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['applications'] })
        },
        onError: (error) => {
            let errorMessage = 'Failed to add connector';
            if ((error as any).response.errors[0].message === 'Duplicate Name') {
                errorMessage = 'Duplicate connector name is not allowed';
            }
            handlePopupMessage('Adding Failed', errorMessage);
        },
    });

    const nameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    };

    const saveApplication = () => {
        if (name.trim() === '') {
            handlePopupMessage('Adding Failed', 'Empty name is not allowed');
            setName("");
            return;
        }
        createApplicationAndRule.mutate({ name, policyId });
        setName("");
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Text variant='bold'>Add Connector</Text>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                <TextField
                    type="text"
                    label="name"
                    variant="outlined"
                    size='small'
                    value={name}
                    onChange={nameChange}
                />
                <Select
                    labelId="policy"
                    value={policyId}
                    onChange={policyChange}
                    size='small'
                >
                    {
                        policies?.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)
                    }
                </Select>
                <PSButton variant="filled" onClick={saveApplication}>Add</PSButton>
            </Box>
        </Box>
    )

}