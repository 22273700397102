/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import {Text, PSBadge, Icon} from '../../ui-kit'
import { PSPercentageChangeBadgeStyles } from './PSPercentageChangeBadge.css';
import { ColorThemeKeys } from "../../styles";

type TTheme = 'dark' | 'light' | 'default';

type IProps = {
    theme?: TTheme;
    number: number;
    direction: "up" | "down";
}

const themeToColorMap: Record<TTheme, { text: ColorThemeKeys, background: ColorThemeKeys  }> = {
    dark: {
        text: 'white',
        background: 'blue-dark', // todo - #6947CF
    },
    light: {
        text: 'blue-dark', // todo - #6947CF
        background: 'white',
    },
    default: {
        text: 'white',
        background: 'black-70', // todo - #939598
    },
}

const PSPercentageChangeBadge: FC<IProps> = (props) => {
    const { number, direction, theme = 'default' } = props;

    return (
        <PSBadge
            size='large'
            backgroundColor={themeToColorMap[theme].background}
        >
            <div css={PSPercentageChangeBadgeStyles.self}>
                <Icon
                    iconSize={15}
                    iconName={direction === 'up' ? 'PSArrowUpIcon' : 'PSArrowDownIcon'}
                    color={themeToColorMap[theme].text}
                    css={PSPercentageChangeBadgeStyles.icon}
                />
                <Text color={themeToColorMap[theme].text}>{`${number}%`}</Text>
            </div>
        </PSBadge>
    )
}

export default PSPercentageChangeBadge;