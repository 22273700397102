/** @jsxImportSource @emotion/react */
import React, { useCallback, useState, useEffect } from 'react';
import { PSPaginationStyle } from './PSPagination.css';
import Text from '../../Text/Text';
import Button from '../../PSButton/PSButton';
import PSIconButton from '../../PSIconButton/PSIconButton';

type IProps = {
    currentPage: number;
    totalItems: number;
    itemsPerPage: number;
    isLoading?: boolean;
    isFirstLoading?: boolean;
    onPageChange: (newPage: number) => void;
};

const Pagination: React.FC<IProps> = (props) => {
    const { currentPage, totalItems, itemsPerPage, isLoading, isFirstLoading, onPageChange } = props;

    const [optimisticPage, setOptimisticPage] = useState(currentPage);

    useEffect(() => {
        setOptimisticPage(currentPage);
    }, [currentPage]);

    const paginationFrom = (optimisticPage - 1) * itemsPerPage + 1;
    const paginationTo = Math.min(paginationFrom + itemsPerPage - 1, totalItems);
    const paginationTotalPages = Math.ceil(totalItems / itemsPerPage);

    const canGoToFirstPage = optimisticPage > 1;
    const canGoToPreviousPage = optimisticPage > 1;
    const canGoToNextPage = optimisticPage < paginationTotalPages;
    const canGoToLastPage = optimisticPage < paginationTotalPages;

    const handlePageChange = useCallback((newPage: number) => {
        setOptimisticPage(newPage);
        onPageChange(newPage);
    }, [onPageChange]);

    if (isFirstLoading) {
        return null;
    }


    return (
        <div css={PSPaginationStyle.self}>
            <Text color='black-70'>
                {paginationFrom} to {paginationTo} of {totalItems}
            </Text>

            <div css={PSPaginationStyle.paginationActions}>
                <PSIconButton
                    variant='circle'
                    variantType='secondary'
                    iconName='PSChevronLeftLastIcon'
                    iconSize={15}
                    onClick={() => handlePageChange(1)}
                    disabled={!canGoToFirstPage || isLoading}
                />
                <PSIconButton
                    variant='circle'
                    variantType='secondary'
                    iconName='PSChevronLeftIcon'
                    iconSize={15}
                    onClick={() => handlePageChange(optimisticPage - 1)}
                    disabled={!canGoToPreviousPage || isLoading}
                />

                <Text>
                    <Text inline>Page </Text>
                    <b>{optimisticPage} </b>
                    <Text inline>of </Text>
                    <b>{paginationTotalPages}</b>
                </Text>

                <PSIconButton
                    variant='circle'
                    variantType='secondary'
                    iconName='PSChevronRightIcon'
                    iconSize={15}
                    onClick={() => handlePageChange(optimisticPage + 1)}
                    disabled={!canGoToNextPage || isLoading}
                />
                <PSIconButton
                    variant='circle'
                    variantType='secondary'
                    iconName='PSChevronRightLastIcon'
                    iconSize={15}
                    onClick={() => handlePageChange(paginationTotalPages)}
                    disabled={!canGoToLastPage || isLoading}
                />
            </div>
        </div>
    )
}

export default Pagination;
