/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import { TProtectionTextType } from '../protections_types';
import { ProtectionCollapsibleStyle } from './ProtectionCollapsible.css';
import { Chip, Circle, Icon, PSFormControlLabel, Text, TextType, Tooltip } from '../../../../../ui-kit';
import { Checkbox, Collapse, IconButton } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { LLMsTranslations } from '../protections_defaults';

type Protection = {
    name: string;
    description: string;
} & Partial<Record<TProtectionTextType, boolean>>;

type IProps = Protection & {
    children?: React.ReactNode;
    control: Control<any>;
    formName: string;
    isExpandAllowed?: boolean;
    top10?: Array<Uppercase<keyof typeof LLMsTranslations>>;
}

const ProtectionCollapsible: React.FC<IProps> = (props) => {
    const { name, description, prompt, response, children, formName, isExpandAllowed = true, control, top10 = [] } = props;

    const [isExpanded, setIsExpanded] = React.useState(false);

    const isPolicyEnabled = prompt || response;
    const isExpandable = children && isExpandAllowed;

    const onToggleCollapse = useCallback(() => {
        setIsExpanded((prev) => !prev);
    }, []);

    useEffect(() => {
        if (!isExpandable) {
            setIsExpanded(false);
        }
    }, [isExpandable])

    return (
        <div css={ProtectionCollapsibleStyle.self} className={clsx({ 'disabled': !isPolicyEnabled, 'collapse-open': isExpanded })}>
            <style>
                {`
                    @container protectionContainer (width < 750px){
                        .protection-container {
                            flex-direction: column;
                        }
                    }
                `}
            </style>
            <div css={ProtectionCollapsibleStyle.collapsedContainer} className="protection-container">
                <div css={ProtectionCollapsibleStyle.detailsContainer}>
                    <div css={ProtectionCollapsibleStyle.titleContainer}>
                        <Circle circleCss={isPolicyEnabled ? ProtectionCollapsibleStyle.circlePulse : ''} size={10} color={isPolicyEnabled ? 'green' : 'black-50'} />
                        <Text>{name}</Text>
                    </div>
                    <div css={ProtectionCollapsibleStyle.descriptionContainer}>
                        <Text ellipsis>{description}</Text>
                        <div css={ProtectionCollapsibleStyle.top10Container}>
                            {top10.map(item => (
                                <Tooltip title={LLMsTranslations[item.toLowerCase() as keyof typeof LLMsTranslations]} key={item} placement='top' disableInteractive>
                                    <div><Chip label={item.toLowerCase()} variant='outlined' size='small' /></div>
                                </Tooltip>

                            ))}
                        </div>
                    </div>
                </div>
                <div css={ProtectionCollapsibleStyle.actionsContainer}>
                    <div css={ProtectionCollapsibleStyle.protectContainer}>
                        <div css={ProtectionCollapsibleStyle.textTypesContainer}>
                            {prompt !== undefined && (
                                <Controller
                                    name={`content.prompt.${formName}.enabled`}
                                    control={control}
                                    defaultValue={prompt}
                                    render={({ field }) => (
                                        <PSFormControlLabel
                                            onChange={() => field.onChange(!field.value)}
                                            checked={field.value}
                                            label={<TextType iconOnly={false} value={'Prompt'} />}
                                            control={<Checkbox />}
                                        />
                                    )}
                                />
                            )}
                        </div>
                        <div css={ProtectionCollapsibleStyle.textTypesContainer}>
                            {response !== undefined && (
                                <Controller
                                    name={`content.response.${formName}.enabled`}
                                    control={control}
                                    defaultValue={response}
                                    render={({ field }) => (
                                        <PSFormControlLabel
                                            onChange={() => field.onChange(!field.value)}
                                            checked={field.value}
                                            label={<TextType iconOnly={false} value={'Response'} />}
                                            control={<Checkbox />}
                                        />
                                    )}
                                />
                            )}
                        </div>
                    </div>

                    <div>
                        <IconButton onClick={onToggleCollapse} disabled={!isExpandable}>
                            <Icon color={isExpandable ? 'black-70' : 'black-10'} iconName={isExpanded ? 'PSChevronUpIcon' : 'PSChevronDownIcon'} />
                        </IconButton>
                    </div>
                </div>
            </div>
            <Collapse in={isExpanded} timeout='auto' unmountOnExit>
                <div css={ProtectionCollapsibleStyle.expandedContainer}>
                    {children}
                </div>
            </Collapse>
        </div>
    )
}

export default ProtectionCollapsible;