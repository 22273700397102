/** @jsxImportSource @emotion/react */
import clsx from 'clsx';
import { ReactNode, useState } from 'react';
import Text from '../Text/Text';
import { PSSegmentsSwitchStyle } from './PSSegmentsSwitch.css';
type TOption = {
    id: string;
    label: string | ReactNode;
    disabled?: boolean;
}

type IProps<T extends TOption> = {
    options: T[];
    value?: T['id'];
    onChange?: (value: T['id']) => void;
    defaultValue?: T['id'];
    name?: string;
    className?: string;
    disabled?: boolean;
}

const PSSegmentsSwitch = <T extends TOption>(props: IProps<T>) => {
    const {
        options,
        value,
        defaultValue,
        onChange,
        className,
        disabled = false,
    } = props;

    const [internalValue, setInternalValue] = useState<T['id']>(() => {
        if (defaultValue !== undefined) return defaultValue;
        const firstEnabled = options.find(opt => !opt.disabled);
        return firstEnabled?.id || options[0]?.id;
    });

    const isControlled = value !== undefined;
    const currentValue = isControlled ? value : internalValue;

    const handleChange = (optionId: TOption['id'], isDisabled: boolean) => {
        if (disabled || isDisabled) return;

        if (isControlled) {
            onChange?.(optionId);
        } else {
            setInternalValue(optionId);
            onChange?.(optionId);
        }
    };

    return (
        <div
            css={PSSegmentsSwitchStyle.self}
            className={className}
            data-disabled={disabled}
            role="tablist"
        >
            {options.map((option) => {
                const isDisabled = disabled || option.disabled;
                const isSelected = currentValue === option.id;
                const labelLength = (option.label?.toString()?.length) || 0;

                return (
                    <button
                        key={option.id}
                        css={PSSegmentsSwitchStyle.option(labelLength * 13)}
                        className={clsx({ 'isSelected': isSelected, 'isDisabled': isDisabled })}
                        role="tab"
                        type="button"
                        aria-selected={isSelected}
                        disabled={isDisabled}
                        data-text={option.label}
                        onClick={() => handleChange(option.id, isDisabled || false)}
                    >
                        <Text className='text'>{option.label}</Text>
                    </button>
                );
            })}
        </div>
    );
};

export default PSSegmentsSwitch;