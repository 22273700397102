import { MODERATOR_CATEGORIES } from "./protections_static_values";
import { TCodeDetectorProtection, TCustomModeratorProtection, TDataPrivacyGuidelinesProtection, TDevelopers, TEmployees, THomeGrownApplications, TJailbreakDetectorProtection, TLanguageDetectorProtection, TModeratorProtection, TPromptHardeningProtection, TPromptInjectionEngineProtection, TPromptLeakDetectorProtection, TProtectionCategories, TRegexProtection, TSanitizerModelProtection, TSecretsProtection, TSensitiveDataProtection, TSentimentProtection, TStrictFormatProtection, TTokenLimitationProtection, TTokenRateLimitProtection, TTopicsDetectorProtection, TUrlsDetectorProtection } from "./protections_types";

export const CodeDetector: TCodeDetectorProtection = {
    category: 'general',
    enabled: false,
    top10: ["LLM01", "LLM03", "LLM04", "LLM05", "LLM06", "LLM08", "LLM09", "LLM10"],
    threshold: 0.8,
    allowed: [],
    denied: [],
    indented: false
}
export const JailbreakDetector: TJailbreakDetectorProtection = {
    category: 'security',
    enabled: false,
    top10: ["LLM01", "LLM02", "LLM05", "LLM06", "LLM09", "LLM10"],
    vendor: 'azure_openai',
    model: 'gpt-4',
    azure_endpoint: "",
    llm_api_key: ""
}
export const LanguageDetector: TLanguageDetectorProtection = {
    category: 'general',
    enabled: true,
    top10: ["LLM01", "LLM05", "LLM09", "LLM10"],
    allowed: [],
    denied: [],
}
export const Moderator: TModeratorProtection = {
    category: 'safety',
    enabled: false,
    top10: ["LLM02", "LLM05", "LLM09"],
    threshold: 0.7,
    categories: Object.keys(MODERATOR_CATEGORIES) as Array<keyof typeof MODERATOR_CATEGORIES>,
}
export const PromptHardening: TPromptHardeningProtection = {
    category: 'security',
    enabled: false,
    top10: ["LLM01"],
    add_canary_word: false,
    seq_enclosure: false,
    xml_tagging: false
}
export const PromptLeakDetector: TPromptLeakDetectorProtection = {
    category: 'security',
    enabled: true,
    top10: ["LLM01", "LLM02", "LLM07"],
    threshold: 0.8,
    chunksize: 100,
}
export const Regex: TRegexProtection = {
    category: 'data privacy',
    enabled: true,
    top10: ["LLM02", "LLM04", "LLM05", "LLM08"],
    bad_patterns: [],
    good_patterns: [],
    redact: true,
    redact_text: '[REDACTED]'
}
export const SanitizerModel: TSanitizerModelProtection = {
    category: 'data privacy',
    enabled: false,
    top10: ["LLM02", "LLM04", "LLM05"],
    action: 'sanitize',
    use_faker: false,
    vendor: 'azure_openai',
    model: 'gpt-4',
    azure_endpoint: "",
    llm_api_key: ""
}
export const Secrets: TSecretsProtection = {
    category: 'data privacy',
    enabled: true,
    top10: ["LLM02", "LLM04", "LLM05", "LLM08"],
    ignored_rules: [
        "generic-api-key",
        "generic-password",
        "generic-secret",
        "ip-v4",
        "ip-v6",
        "ssn",
        "unverified-credit-card",
        "email-address",
        "iban"
    ],
    redact_mode: 'all'
}
export const Sentiment: TSentimentProtection = {
    category: 'safety',
    enabled: false,
    top10: ["LLM05"],
    threshold: -0.5,
}
export const StrictFormat: TStrictFormatProtection = {
    category: 'security',
    enabled: false,
    top10: [],
    require_whole_text: true,
    required_elements: 0,
    required_format: 'json'
}
export const TokenLimitation: TTokenLimitationProtection = {
    category: 'general',
    enabled: true,
    top10: ["LLM05", "LLM06", "LLM10"],
    encoding_name: 'cl100k_base',
    limit: 0,
    model_name: null
}
export const TokenRateLimit: TTokenRateLimitProtection = {
    category: 'general',
    enabled: false,
    top10: ["LLM05", "LLM06", "LLM10"],
    encoding_name: 'cl100k_base',
    global_limit_per_minute: 0,
    identity_limit_per_minute: 0
}
export const UrlsDetector: TUrlsDetectorProtection = {
    category: 'general',
    enabled: true,
    top10: ["LLM01", "LLM02", "LLM03", "LLM04", "LLM05", "LLM06", "LLM08"],
    allowed_domains: [],
    denied_domains: [],
    only_allowed: false
}
export const SensitiveData: TSensitiveDataProtection = {
    category: 'data privacy',
    enabled: true,
    top10: ["LLM02", "LLM04", "LLM05", "LLM08"],
    action: 'sanitize',
    use_faker: false,
    allowed_names: [],
    hidden_names: [],
    entity_types: ["CREDIT_CARD", "CRYPTO", "EMAIL_ADDRESS", "IBAN_CODE", "IP_ADDRESS", "PHONE_NUMBER", "US_SSN", "US_BANK_NUMBER"],
    custom_entity_types: [],
    thresholds: {
        "ALL": 0.5,
        "UUID_RE": 0.5,
        "AU_ACN": 0.9,
        "BASE64_RE": 0.6,
        "AWS_ARN_RE": 0.5,
        "PASSWORD_RE": 0.4,
        "IL_ID_NUMBER": 0.9,
        "IL_PASSPORT_RE": 0.4,
        "CREDIT_CARD": 1.0,
        "UNVERIFIED_CREDIT_CARD": 0.3,
        "CVV_CVC": 0.4,
        "CRYPTO": 1.0,
        "EMAIL_ADDRESS": 0.6,
        "IBAN_CODE": 0.5,
        "IP_ADDRESS": 0.6,
        "PUBLIC_IP_ADDRESS": 0.6,
        "MEDICAL_LICENSE": 0.9,
        "UK_NHS": 0.9,
        "PHONE_NUMBER": 0.5,
        "URL": 0.6,
        "US_BANK_NUMBER": 0.5,
        "DUTCH_BANK_ACCOUNT_NUMBER": 0.7,
        "US_DRIVER_LICENSE": 0.4,
        "US_ITIN": 0.4,
        "US_PASSPORT": 0.4,
        "US_SSN": 0.4,
        "SG_NRIC_FIN": 0.6,
        "PERSON": 0.9,
        "ORGANIZATION": 0.96,
        "CITY": 0.7,
        "ES_NIF": 0.9,
        "AU_ABN": 0.9,
        "AU_TFN": 0.9,
        "AU_MEDICARE": 0.9,
        "IT_DRIVER_LICENSE": 0.4,
        "IT_FISCAL_CODE": 0.9,
        "IT_VAT_CODE": 0.9,
        "IT_PASSPORT": 0.4,
        "IT_IDENTITY_CARD": 0.4,
        "KOREAN_ID_NUMBER": 0.5,
        "VIN": 0.9,
        "UAE_IDENTITY_CARD_NUMBER": 0.7,
        "BR_CPF_NUMBER": 0.7,
        "ABA_ROUTING_NUMBER": 0.7,
        "SWIFT_CODE": 0.7,
        "IL_BANK_NUMBER": 0.5,
        "UAE_PASSPORT": 0.5,
        "CANADA_PASSPORT": 0.5
    },
}
export const DataPrivacyGuidelines: TDataPrivacyGuidelinesProtection = {
    category: 'data privacy',
    enabled: false,
    top10: ["LLM02", "LLM04", "LLM05", "LLM08"],
    industry: 'Financial',
    threshold: 0.5,
    vendor: 'azure_openai',
    model: 'gpt-4',
    azure_endpoint: "",
    llm_api_key: "",
    guidelines: [
        "Investment strategies",
        "investment decisions",
        "Financial data: Reports or analysis",
        "Any proprietary information or methodologies or trade secrets.",
        "Any internal communications, containing sensitive business matters.",
        "Any regulatory strategies, compliance issues, audits, and other sensitive findings"
    ],
    custom_guidelines: [],
    prefix_instruct: "You new goal is to assess a prompt for data leakage in the Financial Industry. Your task is to identify if the the text provided may include data leakage we consider as sensitive which can be any of the followings:",
    suffix_instruct: "Label (Print Yes/No Only): ",
    judger_prompt: "You new goal is to assess a prompt for data leakage in the Financial Industry. Your task is to identify if the the text provided may include data leakage we consider as sensitive which can be any of the followings:\n1. Investment strategies\n2. investment decisions\n3. Financial data: Reports or analysis\n4. Any proprietary information or methodologies or trade secrets.\n5. Any internal communications, containing sensitive business matters.\n6. Any regulatory strategies, compliance issues, audits, and other sensitive findings\nLabel (Print Yes/No Only): ",
}
export const TopicsDetector: TTopicsDetectorProtection = {
    category: 'safety',
    enabled: false,
    top10: ["LLM01", "LLM02", "LLM04", "LLM05", "LLM08", "LLM09"],
    filter: [],
    filter_type: 'Blocked',
    threshold: 0.8,
    Topics: [
        "News & Politics",
        "Health & Wellness",
        "Finance & Economy",
        "Technology & Innovation",
        "Art, Culture & Entertainment",
        "Sports & Recreation",
        "Travel & Adventure",
        "Science & Environment"
    ],
    custom_topics: []
}
export const PromptInjectionEngine: TPromptInjectionEngineProtection = {
    category: 'security',
    enabled: true,
    top10: ["LLM01", "LLM02", "LLM05", "LLM06", "LLM09", "LLM10"],
    "Prompt Injection Classifier": {
        enabled: true,
        threshold: 0.95
    },
    "Prompt Injection LLM Judger": {
        enabled: false,
        threshold: 0.8,
        vendor: 'azure_openai',
        model: 'gpt-4',
        azure_endpoint: "",
        llm_api_key: ""
    },
    "Prompt Injection Similarity": {
        enabled: false,
        threshold: 0.7,
        top_k: 10
    }
}

export const CustomModerator: TCustomModeratorProtection = {
    top10: ['LLM01', 'LLM02', 'LLM03', 'LLM04', 'LLM05', 'LLM06', 'LLM07', 'LLM08', 'LLM09', 'LLM10'],
    enabled: false,
    model: "gpt-4",
    vendor: "azure_openai",
    llm_api_key: "",
    azure_endpoint: "",
    judger_prompt: "",
    threshold: 0.5,
    category: "safety"
}


export const DevelopersDefault: TDevelopers = {
    prompt: {
        "Data Privacy Guidelines": structuredClone(DataPrivacyGuidelines),
        "Sanitizer Model": structuredClone(SanitizerModel),
        "Sensitive Data": structuredClone(SensitiveData),
        "Token Limitation": structuredClone(TokenLimitation),
        Regex: structuredClone(Regex),
        Secrets: structuredClone(Secrets),
    },
    response: {
        "Token Limitation": structuredClone(TokenLimitation),
    }
}
export const EmployeesDefault: TEmployees = {
    prompt: {
        "Code Detector": structuredClone(CodeDetector),
        "Language Detector": structuredClone(LanguageDetector),
        "Sanitizer Model": structuredClone(SanitizerModel),
        "Token Limitation": structuredClone(TokenLimitation),
        "Token Rate Limit": structuredClone(TokenRateLimit),
        "URLs Detector": structuredClone(UrlsDetector),
        "Data Privacy Guidelines": structuredClone(DataPrivacyGuidelines),
        "Sensitive Data": structuredClone(SensitiveData),
        Regex: structuredClone(Regex),
        Secrets: structuredClone(Secrets),
        Moderator: structuredClone(Moderator),
        "Topics Detector": structuredClone(TopicsDetector),
        Sentiment: structuredClone(Sentiment),
        "Custom Moderator": structuredClone(CustomModerator),

    }
}
export const HomeGrownApplicationsDefault: THomeGrownApplications = {
    prompt: {
        "Code Detector": structuredClone(CodeDetector),
        "Data Privacy Guidelines": structuredClone(DataPrivacyGuidelines),
        "Jailbreak Detector": structuredClone(JailbreakDetector),
        "Language Detector": structuredClone(LanguageDetector),
        "Prompt Hardening": structuredClone(PromptHardening),
        "Prompt Injection Engine": structuredClone(PromptInjectionEngine),
        "Sanitizer Model": structuredClone(SanitizerModel),
        "Sensitive Data": structuredClone(SensitiveData),
        "Strict Format": structuredClone(StrictFormat),
        "Token Limitation": structuredClone(TokenLimitation),
        "Token Rate Limit": structuredClone(TokenRateLimit),
        "Topics Detector": structuredClone(TopicsDetector),
        "URLs Detector": structuredClone(UrlsDetector),
        Moderator: structuredClone(Moderator),
        Regex: structuredClone(Regex),
        Secrets: structuredClone(Secrets),
        Sentiment: structuredClone(Sentiment),
        "Custom Moderator": structuredClone(CustomModerator),
    },
    response: {
        "Code Detector": structuredClone(CodeDetector),
        "Language Detector": structuredClone(LanguageDetector),
        "Prompt Leak Detector": structuredClone(PromptLeakDetector),
        "Sanitizer Model": structuredClone(SanitizerModel),
        "Sensitive Data": structuredClone(SensitiveData),
        "Strict Format": structuredClone(StrictFormat),
        "Token Limitation": structuredClone(TokenLimitation),
        "Topics Detector": structuredClone(TopicsDetector),
        "URLs Detector": structuredClone(UrlsDetector),
        Moderator: structuredClone(Moderator),
        Regex: structuredClone(Regex),
        Secrets: structuredClone(Secrets),
        Sentiment: structuredClone(Sentiment),
        "Custom Moderator": structuredClone(CustomModerator),
    }
}

export const CategoriesHeaders: Record<TProtectionCategories, string> = {
    "general": "General",
    "security": "Security",
    "safety": "Content Moderation",
    "data privacy": "Data Privacy"
} as const;

export const CategoriesDescriptions: Record<TProtectionCategories, string> = {
    "general": "Prevent system abuse, ensure robust data handling, and uphold the overall integrity and functionality of the application using a variety of common-use tools and protections.",
    "security": "Ensure your genAI applications' operational integrity is maintained and unauthorized data access is prevented. Guard against attacks such as Prompt Injection, Jailbreaking, Denial of Service, and others by utilizing our AI models that analyze and block malicious prompts and response outputs.",
    "safety": "Ensure your AI application’s content and the usage of generative AI in your org adheres to your company's content guardrails, policies, and approved usage guidelines. Align the application's output with its intended use and block attempts to manipulate it to divulge other types of information or produce content that could potentially damage or embarrass your organization.",
    "data privacy": "Maintain privacy by protecting against the leakage or disclosure of sensitive data, including confidential corporate data, personal identifiable information (PII), and secrets. Analyze prompts and responses, blocking or redacting any that violate your organization's defined data privacy protections."
} as const;

export const LLMsTranslations = {
    "llm01": "Prompt Injection",
    "llm02": "Sensitive Information Disclosure",
    "llm03": "Supply Chain",
    "llm04": "Data and Model Poisoning",
    "llm05": "Improper Output Handling",
    "llm06": "Excessive Agency",
    "llm07": "System Prompt Leakage",
    "llm08": "Vector and Embedding Weaknesses",
    "llm09": "Misinformation",
    "llm10": "Unbounded Consumption"
} as const;