/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const KeyValueRowStyle = {
    self: css`
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 0;
        border-bottom: 1px solid var(--color-black-50);
    
        &:last-of-type {
          border-bottom: none;
        }
  `,
    keyMinWidth: css`
        min-width: 150px;
  `,
    value: css`
        flex: 1;
    `
};
