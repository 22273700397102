/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import { GenAiApplicationStyle } from './GenAiApplication.css';
import Text from '../../../Text/Text';
import Icon from '../../../Icon/Icon';

type IProps = {
    includeAppDomain?: boolean;
    iconSize?: number;
} & Partial<CustomCellRendererProps>;

const GenAiApplication: React.FC<IProps> = (props) => {
    const { value, includeAppDomain = false, iconSize = 24 } = props;
    const [imageError, setImageError] = useState(false);

    const DefaultAiApplicationIcon = <Icon iconName='PSDefaultAiApplicationIcon' color='black-70' iconSize={iconSize} />;

    if (!value) return (
        <div css={GenAiApplicationStyle.self}>
            {DefaultAiApplicationIcon}
        </div>
    );

    const imageSrc = value.logo || value.faviconUrl;
    const isCustom = value.isCustom || value.verifiedOrCustom === 'Custom';

    return (
        <div css={GenAiApplicationStyle.self}>
            {isCustom && <Icon iconName='PSDefaultAiApplicationIcon' iconSize={iconSize} />}
            {!isCustom && imageSrc && !imageError ? (
                <img
                    width={iconSize}
                    height={iconSize}
                    src={imageSrc}
                    alt={value.name}
                    loading='lazy'
                    onError={() => setImageError(true)}
                />
            ) : !isCustom && (
                DefaultAiApplicationIcon
            )}
            {value.name && <Text ellipsis>{value.name}</Text>}
            {includeAppDomain && value.domain && <Text variant='small' color='black-70'>({value.domain})</Text>}
        </div>
    );
};

export default GenAiApplication;