import { useEffect, useState } from 'react';
import { IntegrationItem } from '../IntegrationComponents/IntegrationItem';
import { Box } from '@mui/material';
import { graphql, createGraphQLClient } from "../../../gql";
import { useQuery } from "@tanstack/react-query";
import Link from '@mui/material/Link';
import { PopupMessage } from '../../../components/PopupMessage';

const querySlackAppIntegration = graphql(`
    query slackAppIntegration {
        slackAppIntegration {
            organizationKey
        }
    }
`);


export const IntegrationSlackAppCard = () => {
    const { data: slackAppIntegration } = useQuery(
        {
            queryKey: ["slackAppIntegration"],
            queryFn: async ({ signal }) => {
                const client = createGraphQLClient(signal);
                const { slackAppIntegration } = await client.request(querySlackAppIntegration);
                return slackAppIntegration
            },
        }
    )

    useEffect(() => {
        if (slackAppIntegration !== undefined) {
            if (slackAppIntegration.organizationKey !== null && slackAppIntegration.organizationKey !== undefined) {
                setDownloadLink(`https://global.prompt.security/slack/install?organizationKey=${slackAppIntegration.organizationKey}`);
            } else {
                setPopupText('Failed to get Slack App download link');
                setPopupTitle('Slack App Download Link Failure');
                setOpenPopup(true);
            }
        }
    }, [slackAppIntegration]);

    const [downloadLink, setDownloadLink] = useState<string>();
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const [popupText, setPopupText] = useState<string>('');
    const [popupTitle, setPopupTitle] = useState<string>('');

    const handlePopupClose = () => {
        setOpenPopup(false);
    };

    return (
        <div>
            <IntegrationItem>
                <Box>
                    <Link href={downloadLink}>Download</Link>
                </Box>
            </IntegrationItem>
            <PopupMessage title={popupTitle} text={popupText} open={openPopup} handlePopupMessageClose={handlePopupClose} />
        </div>
    )
};