/** @jsxImportSource @emotion/react */
import React from 'react';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import { PSErrorStyle } from './PSError.css';

export type TErrorDirection = 'row' | 'column';

type IProps = {
    header?: string;
    message?: string;
    direction?: TErrorDirection;
};

const PSError: React.FC<IProps> = (props) => {
    const { header = 'Error', message = 'Error occurred please try again later', direction = 'column' } = props;

    return (
        <div css={PSErrorStyle.self(direction)}>
            <div css={PSErrorStyle.iconContainer(direction)}>
                <Icon iconName='PSCriticalIcon' color='red' iconSize={20} />
            </div>
            {header && <Text textCss={PSErrorStyle.errorHeader(direction)} color='black'>{header}</Text>}
            {message && <Text textCss={PSErrorStyle.errorMessage(direction)} variant='small' color='black-50'>{message}</Text>}
        </div>
    )
}

export default PSError;