export const REDACT_MODES = {
  all: "Redact All",
  partial: "Redact Partial",
  hash: "Hash",
  none: "None",
} as const;

export const PROTECTION_ACTIONS = {
  sanitize: "sanitize",
  block: "block",
} as const;

export const SUPPORTED_CODE_LANGUAGES = {
  "ARM Assembly": "ARM Assembly",
  AppleScript: "AppleScript",
  C: "C",
  "C#": "C#",
  "C++": "C++",
  COBOL: "COBOL",
  Erlang: "Erlang",
  Fortran: "Fortran",
  Go: "Go",
  Java: "Java",
  JavaScript: "JavaScript",
  Kotlin: "Kotlin",
  Lua: "Lua",
  "Mathematica/Wolfram Language": "Mathematica/Wolfram Language",
  PHP: "PHP",
  Pascal: "Pascal",
  Perl: "Perl",
  PowerShell: "PowerShell",
  Python: "Python",
  R: "R",
  Ruby: "Ruby",
  Rust: "Rust",
  Scala: "Scala",
  Swift: "Swift",
  "Visual Basic .NET": "Visual Basic .NET",
  jq: "jq",
  SQL: "SQL",
} as const;

export const SENSITIVE_DATA_TYPES = {
  PASSWORD_RE: "PASSWORD_RE",
  CREDIT_CARD: "CREDIT_CARD",
  UNVERIFIED_CREDIT_CARD: "UNVERIFIED_CREDIT_CARD",
  CRYPTO: "CRYPTO",
  CVV_CVC: "CVV_CVC",
  EMAIL_ADDRESS: "EMAIL_ADDRESS",
  STREET_BUILDING: "STREET_BUILDING",
  AU_ACN: "AU_ACN",
  IL_ID_NUMBER: "IL_ID_NUMBER",
  IL_PASSPORT_RE: "IL_PASSPORT_RE",
  IBAN_CODE: "IBAN_CODE",
  IP_ADDRESS: "IP_ADDRESS",
  PUBLIC_IP_ADDRESS: "PUBLIC_IP_ADDRESS",
  MEDICAL_LICENSE: "MEDICAL_LICENSE",
  UK_NHS: "UK_NHS",
  PHONE_NUMBER: "PHONE_NUMBER",
  US_BANK_NUMBER: "US_BANK_NUMBER",
  DUTCH_BANK_ACCOUNT_NUMBER: "DUTCH_BANK_ACCOUNT_NUMBER",
  US_DRIVER_LICENSE: "US_DRIVER_LICENSE",
  US_ITIN: "US_ITIN",
  US_PASSPORT: "US_PASSPORT",
  US_SSN: "US_SSN",
  SG_NRIC_FIN: "SG_NRIC_FIN",
  PERSON: "PERSON",
  CITY: "CITY",
  ES_NIF: "ES_NIF",
  AU_ABN: "AU_ABN",
  AU_TFN: "AU_TFN",
  AU_MEDICARE: "AU_MEDICARE",
  IT_DRIVER_LICENSE: "IT_DRIVER_LICENSE",
  IT_VAT_CODE: "IT_VAT_CODE",
  IT_PASSPORT: "IT_PASSPORT",
  IT_IDENTITY_CARD: "IT_IDENTITY_CARD",
  IT_FISCAL_CODE: "IT_FISCAL_CODE",
  URL: "URL",
  AWS_ARN_RE: "AWS_ARN_RE",
  UUID_RE: "UUID_RE",
  BASE64_RE: "BASE64_RE",
  ORGANIZATION: "ORGANIZATION",
  KOREAN_ID_NUMBER: "KOREAN_ID_NUMBER",
  VIN: "VIN",
  UAE_IDENTITY_CARD_NUMBER: "UAE_IDENTITY_CARD_NUMBER",
  BR_CPF_NUMBER: "BR_CPF_NUMBER",
  ABA_ROUTING_NUMBER: "ABA_ROUTING_NUMBER",
  SWIFT_CODE: "SWIFT_CODE",
  IL_BANK_NUMBER: "IL_BANK_NUMBER",
  UAE_PASSPORT: "UAE_PASSPORT",
  CANADA_PASSPORT: "CANADA_PASSPORT"
} as const;

export const TOPICS_DETECTOR_TOPICS = {
  "News & Politics": "News & Politics",
  "Health & Wellness": "Health & Wellness",
  "Finance & Economy": "Finance & Economy",
  "Technology & Innovation": "Technology & Innovation",
  "Art, Culture & Entertainment": "Art, Culture & Entertainment",
  "Sports & Recreation": "Sports & Recreation",
  "Travel & Adventure": "Travel & Adventure",
  "Science & Environment": "Science & Environment",
} as const;

export const SECRET_PLUGINS = {
  "adafruit-api-key": "Adafruit API Key",
  "adobe-client-id": "Adobe OAuth Web Client ID",
  "adobe-client-secret": "Adobe Client Secret",
  "age-secret-key": "Age encryption tool secret key",
  "airtable-api-key": "Airtable API Key",
  "algolia-api-key": "Algolia API Key",
  "alibaba-access-key-id": "Alibaba Cloud AccessKey ID",
  "alibaba-secret-key": "Alibaba Cloud Secret Key",
  "asana-client-id": "Asana Client ID",
  "asana-client-secret": "Asana Client Secret",
  "atlassian-api-token": "Atlassian API token",
  "authress-service-client-access-key": "Authress Service Client Access Key",
  "aws-access-token": "AWS credentials",
  "aws-secret-key": "AWS secret key credentials",
  "beamer-api-token": "Beamer API token",
  "bitbucket-client-id": "Bitbucket Client ID",
  "bitbucket-client-secret": "Bitbucket Client Secret",
  "bittrex-access-key": "Bittrex Access Key",
  "bittrex-secret-key": "Bittrex Secret Key",
  "clojars-api-token": "Clojars API token",
  "cloudflare-api-key": "Cloudflare API Key",
  "cloudflare-global-api-key": "Cloudflare Global API Key",
  "cloudflare-origin-ca-key": "Cloudflare Origin CA Key",
  "codecov-access-token": "Codecov Access Token",
  "coinbase-access-token": "Coinbase Access Token",
  "confluent-access-token": "Confluent Access Token",
  "confluent-secret-key": "Confluent Secret Key",
  "contentful-delivery-api-token": "Contentful delivery API token",
  "databricks-api-token": "Databricks API token",
  "datadog-access-token": "Datadog Access Token",
  "defined-networking-api-token": "Defined Networking API token",
  "digitalocean-access-token": "DigitalOcean OAuth Access Token",
  "digitalocean-pat": "DigitalOcean Personal Access Token",
  "digitalocean-refresh-token": "DigitalOcean OAuth Refresh Token",
  "discord-api-token": "Discord API key",
  "discord-client-id": "Discord client ID",
  "discord-client-secret": "Discord client secret",
  "doppler-api-token": "Doppler API token",
  "droneci-access-token": "Droneci Access Token",
  "dropbox-api-token": "Dropbox API secret",
  "dropbox-long-lived-api-token": "Dropbox long-lived API token",
  "dropbox-short-lived-api-token": "Dropbox short-lived API token",
  "duffel-api-token": "Duffel API token",
  "dynatrace-api-token": "Dynatrace API token",
  "easypost-api-token": "EasyPost API token",
  "easypost-test-api-token": "EasyPost test API token",
  "etsy-access-token": "Etsy Access Token",
  "facebook-access-token": "Facebook Access Token",
  "facebook-page-access-token": "Facebook Page Access Token",
  "facebook-secret": "Facebook Application secret",
  "fastly-api-token": "Fastly API key",
  "finicity-api-token": "Finicity API token",
  "finicity-client-secret": "Finicity Client Secret",
  "finnhub-access-token": "Finnhub Access Token",
  "flickr-access-token": "Flickr Access Token",
  "flutterwave-encryption-key": "Flutterwave Encryption Key",
  "flutterwave-public-key": "Finicity Public Key",
  "flutterwave-secret-key": "Flutterwave Secret Key",
  "frameio-api-token": "Frame.io API token",
  "freshbooks-access-token": "Freshbooks Access Token",
  "gcp-api-key": "GCP API key",
  "generic-api-key": "Generic API Key",
  "github-app-token": "GitHub App Token",
  "github-fine-grained-pat": "GitHub Fine-Grained Personal Access Token",
  "github-oauth": "GitHub OAuth Access Token",
  "github-pat": "GitHub Personal Access Token",
  "github-refresh-token": "GitHub Refresh Token",
  "gitlab-pat": "GitLab Personal Access Token",
  "gitlab-ptt": "GitLab Pipeline Trigger Token",
  "gitlab-rrt": "GitLab Runner Registration Token",
  "gitter-access-token": "Gitter Access Token",
  "gocardless-api-token": "GoCardless API token",
  "grafana-api-key": "Grafana API key",
  "grafana-cloud-api-token": "Grafana cloud API token",
  "grafana-service-account-token": "Grafana service account token",
  "hashicorp-tf-api-token": "HashiCorp Terraform user/org API token",
  "hashicorp-tf-password": "HashiCorp Terraform password field",
  "heroku-api-key": "Heroku API Key",
  "hubspot-api-key": "HubSpot API Token",
  "huggingface-access-token": "Hugging Face Access token",
  "huggingface-organization-api-token": "Hugging Face Organization API token",
  "infracost-api-token": "Infracost API Token",
  "intercom-api-key": "Intercom API Token",
  "jfrog-api-key": "JFrog API Key",
  "jfrog-identity-token": "JFrog Identity Token",
  "jwt": "JSON Web Token",
  "jwt-base64": "Base64-encoded JSON Web Token",
  "kraken-access-token": "Kraken Access Token",
  "kucoin-access-token": "Kucoin Access Token",
  "kucoin-secret-key": "Kucoin Secret Key",
  "launchdarkly-access-token": "Launchdarkly Access Token",
  "linear-api-key": "Linear API Token",
  "linear-client-secret": "Linear Client Secret",
  "linkedin-client-id": "LinkedIn Client ID",
  "linkedin-client-secret": "LinkedIn Client secret",
  "lob-api-key": "Lob API Key",
  "lob-pub-api-key": "Lob Publishable API Key",
  "mailchimp-api-key": "Mailchimp API key",
  "mailgun-private-api-token": "Mailgun private API token",
  "mailgun-pub-key": "Mailgun public validation key",
  "mailgun-signing-key": "Mailgun webhook signing key",
  "mapbox-api-token": "MapBox API token",
  "mattermost-access-token": "Mattermost Access Token",
  "messagebird-api-token": "MessageBird API token",
  "messagebird-client-id": "MessageBird client ID",
  "microsoft-teams-webhook": "Microsoft Teams Webhook",
  "netlify-access-token": "Netlify Access Token",
  "new-relic-browser-api-token": "New Relic ingest browser API token",
  "new-relic-user-api-id": "New Relic user API ID",
  "new-relic-user-api-key": "New Relic user API Key",
  "npm-access-token": "npm access token",
  "nytimes-access-token": "Nytimes Access Token",
  "okta-access-token": "Okta Access Token",
  "openai-api-key": "OpenAI API Key",
  "plaid-api-token": "Plaid API Token",
  "plaid-client-id": "Plaid Client ID",
  "plaid-secret-key": "Plaid Secret key",
  "planetscale-api-token": "PlanetScale API token",
  "planetscale-oauth-token": "PlanetScale OAuth token",
  "planetscale-password": "PlanetScale password",
  "postman-api-token": "Postman API token",
  "prefect-api-token": "Prefect API token",
  "private-key": "Private Key",
  "pulumi-api-token": "Pulumi API token",
  "pypi-upload-token": "PyPI upload token",
  "rapidapi-access-token": "RapidAPI Access Token",
  "readme-api-token": "Readme API token",
  "rubygems-api-token": "Rubygem API token",
  "scalingo-api-token": "Scalingo API token",
  "sendbird-access-id": "Sendbird Access ID",
  "sendbird-access-token": "Sendbird Access Token",
  "sendgrid-api-token": "SendGrid API token",
  "sendinblue-api-token": "Sendinblue API token",
  "sentry-access-token": "Sentry Access Token",
  "shippo-api-token": "Shippo API token",
  "shopify-access-token": "Shopify access token",
  "shopify-custom-access-token": "Shopify custom access token",
  "shopify-private-app-access-token": "Shopify private app access token",
  "shopify-shared-secret": "Shopify shared secret",
  "sidekiq-secret": "Sidekiq Secret",
  "sidekiq-sensitive-url": "Sidekiq Sensitive URL",
  "slack-app-token": "Slack App-level token",
  "slack-bot-token": "Slack Bot token",
  "slack-config-access-token": "Slack Configuration access token",
  "slack-config-refresh-token": "Slack Configuration refresh token",
  "slack-legacy-bot-token": "Slack Legacy bot token",
  "slack-legacy-token": "Slack Legacy token",
  "slack-legacy-workspace-token": "Slack Legacy Workspace token",
  "slack-user-token": "Slack User token",
  "slack-webhook-url": "Slack Webhook",
  "snyk-api-token": "Snyk API token",
  "square-access-token": "Square Access Token",
  "squarespace-access-token": "Squarespace Access Token",
  "stripe-access-token": "Stripe Access Token",
  "sumologic-access-id": "SumoLogic Access ID",
  "sumologic-access-token": "SumoLogic Access Token",
  "telegram-bot-api-token": "Telegram Bot API Token",
  "travisci-access-token": "Travis CI Access Token",
  "twilio-api-key": "Twilio API Key",
  "twitch-api-token": "Twitch API token",
  "twitter-access-secret": "Twitter Access Secret",
  "twitter-access-token": "Twitter Access Token",
  "twitter-api-key": "Twitter API Key",
  "twitter-api-secret": "Twitter API Secret",
  "twitter-bearer-token": "Twitter Bearer Token",
  "typeform-api-token": "Typeform API token",
  "vault-batch-token": "Vault Batch Token",
  "vault-service-token": "Vault Service Token",
  "yandex-access-token": "Yandex Access Token",
  "yandex-api-key": "Yandex API Key",
  "yandex-aws-access-token": "Yandex AWS Access Token",
  "zendesk-secret-key": "Zendesk Secret Key",
  "intra42-client-secret": "Intra42 Client Secret",
  "new-relic-insert-key": "New Relic insight insert key",
  "harness-api-key": "Harness Access Token",
  "aws-access-key-id": "AWS Access Key ID",
  "aws-session-token": "AWS Session Token",
  "github-secret": "GitHub Secret",
  "bearer-token": "Bearer Token",
  "azure-openai-api-key": "Azure Open API Key",
  "azure-subscription-key": "Azure Subscription Key",
  "claude-api-key": "Claude API Key",
  "consumer-key": "Consumer Key",
  "consumer-secret": "Consumer Secret",
  "mac-address": "MAC Address",
  "iban": "IBAN",
  "sql-password": "SQL Password",
  "google-api": "Google API",
  "google-access-token": "Google Access Token",
  "oauth-secret": "OAuth Secret",
  "generic-password": "Generic Password",
  "generic-secret": "Generic Secret",
  "microsoft-teams-webhook-2": "Microsoft Teams Webhook 2",
  "outlook-team-webhook": "Outlook Teams Webhook",
  "snowflake-credentials": "Snowflake Credentials",
  "pinecone-api-key": "Pinecone API Key",
  "openai-service-account-api-key": "OpenAI Service Account API Key",
  "openai-project-api-key": "OpenAI Project API Key",
  "langchain-api-key": "Langchain API Key",
  "groq-api-key": "Groq API Key",
  "google-oauth2-client-secret": "Google OAuth2 Client Secret",
  "azure-storage-account-key-or-apikey": "Azure Storage Account Key Or API Key",
  "ibm-cloud-api-key": "IBM Cloud API Key",
  "ibm-api-connect-credentials": "IBM API Connect Credentials",
  "github-access-token": "GitHub Access Token",
  "ip-v4": "IPv4 Address",
  "ip-v6": "IPv6 Address",
  "email-address": "Email Address",
  "unverified-credit-card": "Credit Card Number",
  "aws-arn": "AWS ARN",
  "openshift-user-token": "OpenShift User Token",
  "ssn": "Social Security Number"
} as const;

export const TOKEN_LIMITATION_ENCODING_NAMES = {
  cl100k_base: "cl100k_base",
  p50k_base: "p50k_base",
  r50k_base: "r50k_base",
  p50k_edit: "p50k_edit",
  gpt2: "gpt2",
} as const;

export const VENDORS = {
  "azure_openai": "Azure OpenAI",
  openai: "OpenAI",
  local: "Local",
} as const;

export const LANGUAGES = {
  Afrikaans: "Afrikaans",
  Amharic: "Amharic",
  Arabic: "Arabic",
  Bulgarian: "Bulgarian",
  Bengali: "Bengali",
  Catalan: "Catalan",
  Chinese: "Chinese",
  Czech: "Czech",
  Welsh: "Welsh",
  Danish: "Danish",
  German: "German",
  Greek: "Greek",
  English: "English",
  Spanish: "Spanish",
  Estonian: "Estonian",
  Persian: "Persian",
  Finnish: "Finnish",
  French: "French",
  Gujarati: "Gujarati",
  Hebrew: "Hebrew",
  Hindi: "Hindi",
  Croatian: "Croatian",
  Hungarian: "Hungarian",
  Indonesian: "Indonesian",
  Italian: "Italian",
  Japanese: "Japanese",
  Kannada: "Kannada",
  Korean: "Korean",
  Lithuanian: "Lithuanian",
  Latvian: "Latvian",
  Macedonian: "Macedonian",
  Malayalam: "Malayalam",
  Marathi: "Marathi",
  Nepali: "Nepali",
  Dutch: "Dutch",
  Norwegian: "Norwegian",
  Punjabi: "Punjabi",
  Polish: "Polish",
  Portuguese: "Portuguese",
  Romanian: "Romanian",
  Russian: "Russian",
  Slovak: "Slovak",
  Slovenian: "Slovenian",
  Somali: "Somali",
  Albanian: "Albanian",
  Swedish: "Swedish",
  Swahili: "Swahili",
  Tamil: "Tamil",
  Telugu: "Telugu",
  Thai: "Thai",
  Tagalog: "Tagalog",
  Turkish: "Turkish",
  Ukrainian: "Ukrainian",
  Urdu: "Urdu",
  Vietnamese: "Vietnamese",
} as const;

export const MODELS = {
  "gpt-3.5-turbo": "gpt-3.5-turbo",
  "gpt-4": "gpt-4",
  "gpt-4o": "gpt-4o"
} as const;

export const MODERATION_TYPES = {
  Allowed: "Allowed",
  Blocked: "Blocked",
};

export const DATA_PRIVACY_GUIDELINES_PRESET_KEYS = {
  Financial: "Financial",
  Healthcare: "Healthcare",
  Technology: "Technology",
  Insurance: "Insurance",
  "Other...": "Other...",
} as const;

export type TDataPrivacyGuidelinesPresetKeys = keyof typeof DATA_PRIVACY_GUIDELINES_PRESET_KEYS;

export type TDataPrivacyGuidelinesPresets = {
  [K in TDataPrivacyGuidelinesPresetKeys]: Array<string>;
};

export const DATA_PRIVACY_GUIDELINES_PRESETS: TDataPrivacyGuidelinesPresets = {
  Financial: [
    "Investment strategies",
    "investment decisions",
    "Financial data: Reports or analysis",
    "Any proprietary information or methodologies or trade secrets.",
    "Any internal communications, containing sensitive business matters.",
    "Any regulatory strategies, compliance issues, audits, and other sensitive findings",
  ],
  Healthcare: [
    "Patient Health Information",
    "Treatment plans and outcomes",
    "Clinical trials and research data",
    "Compliance and Regulatory information",
  ],
  Technology: [
    "Product development plans",
    "Source code and algorithms",
    "Business strategies",
    "Market analysis",
    "Competitive research findings",
    "Customers information",
    "Partnership and Deal Information",
  ],
  Insurance: [
    "Customer Personal and Financial Information",
    "Proprietary methodologies",
    "Claim Settlement Processes",
    "Product Development Plans",
    "Internal Financial Data",
    "Regulatory Compliance Strategies",
  ],
  "Other...": [],
};

export const MODERATOR_CATEGORIES = {
  "Child Exploitation": "Child Exploitation",
  "Hate": "Hate",
  "Indiscriminate Weapons": "Indiscriminate Weapons",
  "Intellectual Property": "Intellectual Property",
  "Non-Violent Crimes": "Non-Violent Crimes",
  "Privacy": "Privacy",
  "Self-Harm": "Self-Harm",
  "Sex Crimes": "Sex Crimes",
  "Sexual Content": "Sexual Content",
  "Violent Crimes": "Violent Crimes",
} as const;