/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { PSBox, Text, PSTab, PSTabs, Risk, PSDialog, GenAiApplication, NoData, PSError } from '../../../ui-kit';
import { GenAiAppsInUsedStyle } from './GenAiAppsInUsed.css';
import { ColDef } from 'ag-grid-community';
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';
import { Skeleton } from '@mui/material';
import { DateTime } from 'luxon';
import { useQuery } from '@tanstack/react-query';
import { graphql, createGraphQLClient } from '../../../gql';
import { useDateContext } from '../../../contexts';
import { QueryGenAiAppsInUsedQuery, Risk as TRisk } from '../../../gql/generated/graphql';


const queryGenAiAppsInUsed = graphql(`
    query QueryGenAiAppsInUsed($input: queryGenAiApplicationWidgetTableInput!) {
        queryGenAiApplicationWidgetTable(input: $input) {
            blockType
            genAiApplication {
                description
                domain
                faviconUrl
                id
                vendor
                type
                risk
                name
                isCustom
                logo
            }
            usersCount
            lastUsed
        }
    }
`)
type AppTypes = 'new' | 'risky' | 'all' | 'blocked';

type AppsInUsed = {
    id: string;
    genAiApplication: QueryGenAiAppsInUsedQuery['queryGenAiApplicationWidgetTable'][0]['genAiApplication'];
    usedBy: number;
    lastUsed: string;
    risk: string;
    isBlocked: boolean;
}

type IProps = {};

const createPlaceholderData = (rowCount: number) => {
    return Array.from({ length: rowCount }, (_, index) => {
        ;
        return {
            id: `placeholder-${index}`,
        }
    });
};

const GenAiAppsInUsed: React.FC<IProps> = (props) => {
    const { } = props;
    const gridApiRef = React.useRef<AgGridReact>(null);
    const [tab, setTab] = React.useState<AppTypes>('all');
    const mockRowsData = useMemo(() => createPlaceholderData(20), []);
    const [rowData, setRowData] = React.useState<Array<AppsInUsed>>([]);

    const colDefs = useMemo((): Array<ColDef<AppsInUsed>> => ([
        {
            headerName: 'APP NAME',
            field: 'genAiApplication',
            valueGetter: (params) => params.data?.genAiApplication?.name || '',
            cellRenderer: (params: CustomCellRendererProps) => <div css={css`display: flex; gap: 10px;`}>
                <Text color="black-50">{(params.node.rowIndex || 0) + 1}</Text>
                <GenAiApplication value={params.data.genAiApplication} />
            </div>
        },
        { headerName: '# USED BY', field: 'usedBy', valueFormatter: (params) => `${params.value} Employees` },
        { headerName: 'LAST USED', field: 'lastUsed', valueFormatter: (params) => DateTime.fromISO(params.value).toRelative() || '' },
        // { headerName: 'RISK', field: 'risk', cellRenderer: memo(Risk), maxWidth: 130, minWidth: 130, width: 130, },
        // {
        //     headerName: 'ACTIONS',
        //     field: 'actions',
        //     maxWidth: 130,
        //     minWidth: 130,
        //     width: 130,
        //     sortable: false,
        //     pinned: 'right',
        //     cellRenderer: (params: CustomCellRendererProps) => {
        //         return (
        //             <div css={css`
        //                 margin-right: auto !important;
        //                 margin-left: auto !important;
        //             `}>
        //                 <PSButton onClick={() => {
        //                     setIsBlockApp(!params.data.isBlocked);
        //                     setAppName(params.data.appName);
        //                     setOpen(true);
        //                 }} variant='square'>{params.data.isBlocked ? 'Unblock' : 'Block'}</PSButton>
        //             </div>
        //         )
        //     }
        // },
    ]), [])

    const defaultColDef: ColDef = React.useMemo(() => {
        return {
            sortable: true,
            filter: false,
            resizable: false,
            rowDrag: false,
            suppressMovable: true,
            minWidth: 150,
            flex: 1,
            cellRenderer: (param: CustomCellRendererProps) => {
                return <Text color='black-70' ellipsis>{param.valueFormatted ?? param.value}</Text>
            },
            cellRendererSelector: (param) => {
                if (param.data.id.startsWith('placeholder')) {
                    return {
                        component: () => <Skeleton variant='text' animation='pulse' height={20} width={'100%'} className='loading-skeleton' />
                    }
                }
            }
        }
    }, [])

    const { date } = useDateContext();
    const { data, isLoading, isError } = useQuery({
        queryKey: ['apps-in-use', date.dates.map(d => d.toISOString())],
        queryFn: async ({ signal }) => {
            const client = createGraphQLClient(signal);
            const { queryGenAiApplicationWidgetTable } = await client.request(queryGenAiAppsInUsed, {
                input: {
                    time: {
                        from: date.dates[0].toISOString(),
                        to: date.dates[1].toISOString(),
                    }
                }
            });

            const data: AppsInUsed[] = queryGenAiApplicationWidgetTable.map((item, index) => ({
                id: item.genAiApplication?.id || index.toString(),
                genAiApplication: item.genAiApplication?.name ? item.genAiApplication : { name: 'Unknown' } as any,
                usedBy: item.usersCount || 0,
                lastUsed: item.lastUsed || '',
                risk: item.genAiApplication?.risk || TRisk.Low,
                isBlocked: item.blockType !== null,
            }));

            return data;
        }
    })

    const filteredApps = useCallback((value: AppTypes) => {
        switch (value) {
            case 'new':
                return (data as any)?.filter((item: any) => DateTime.fromISO(item.lastUsed, { zone: 'utc' }) >= DateTime.utc().minus({ days: 7 }));
            case 'risky':
                return (data as any)?.filter((item: any) => item.risk === 'high' || item.risk === 'critical');
            case 'all':
                return (data as any);
            case 'blocked':
                return (data as any)?.filter((item: any) => item.isBlocked);
        }
    }, [data]);

    useEffect(() => {
        setRowData(filteredApps(tab));
    }, [data])


    useEffect(() => {
        if (!gridApiRef.current) return;
        if (!isError) return;
        setRowData([]);
        gridApiRef?.current?.api?.showNoRowsOverlay();
    }, [gridApiRef, isError])


    const onTabChange = (event: React.ChangeEvent<{}>, newValue: AppTypes) => {
        setTab(newValue);
        setRowData(filteredApps(newValue));
    }


    const [open, setOpen] = useState(false);
    const [isBlockApp, setIsBlockApp] = useState(false);
    const [appName, setAppName] = useState('');

    const onBlockApp = async () => { }

    const onUnblockApp = async () => { }


    return (
        <>
            <PSBox padding={0}>
                <Text textCss={GenAiAppsInUsedStyle.title} variant='monoSmall'>GENAI APPS IN USED</Text>
                <PSTabs
                    value={tab}
                    onChange={onTabChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant='fullWidth'
                    scrollButtons="auto"
                >
                    {/* <PSTab isLoading={isLoading} number={filteredApps('new')?.length} value='new' label={'New Shadow AI'} /> */}
                    {/* <PSTab isLoading={isLoading} number={filteredApps('risky')?.length} value='risky' label={'Risky Apps'} /> */}
                    <PSTab isLoading={isLoading} number={filteredApps('all')?.length} value='all' label={'All Apps'} />
                    <PSTab isLoading={isLoading} number={filteredApps('blocked')?.length} value='blocked' label={'Blocked Apps'} />
                </PSTabs>
                <div className="ag-theme-quartz" style={{ height: 400 }}>
                    <AgGridReact
                        ref={gridApiRef}
                        rowHeight={66}
                        rowData={isLoading ? mockRowsData : rowData}
                        columnDefs={colDefs}
                        defaultColDef={defaultColDef}
                        onSortChanged={(event) => event.api.refreshCells()}
                        suppressRowClickSelection
                        suppressCellFocus
                        noRowsOverlayComponent={() => {
                            if (isError) {
                                return <PSError />
                            }
                            return <NoData />
                        }}
                    />
                </div>
            </PSBox>
            <PSDialog
                title={`${isBlockApp ? 'Block' : 'Unblock'} ${appName} App`}
                open={open}
                onClose={() => setOpen(false)}
                action={isBlockApp ? onBlockApp : onUnblockApp}
            >
                <Text color='black'>
                    Are you sure you want to {isBlockApp ? 'block' : 'unblock'}
                    <span css={css`display: inline-block; font-weight: bold; margin: 0 10px; padding: 10px; border-radius: 10px; background: var(--color-black-10); margin-bottom: 0;`}>{appName}</span>
                    app for all your organization?
                </Text>
            </PSDialog>
        </>
    )
}

export default GenAiAppsInUsed;