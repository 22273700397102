export type ColorTheme = typeof colorTheme;

type ShadeKeys<T> = T extends Record<string, any> ? keyof T : never;

export type ColorThemeKeys = {
  [Color in keyof ColorTheme]: {
    [Shade in ShadeKeys<ColorTheme[Color]>]: Shade extends ""
    ? Color
    : `${Color}-${Shade}`;
  }[ShadeKeys<ColorTheme[Color]>];
}[keyof ColorTheme];

const colorTheme = {
  black: {
    "": "#1C1D22",
    70: "#5D5D5D",
    50: "#C0C0C2",
    25: "#DDDEE3",
    10: "#E8E8E9",
    5: "#FAFAFB",
  },
  background: {
    "": "#F2F2F2",
  },
  white: {
    "": "#FFFFFF",
    20: "#FFFFFF33",
    15: "#FFFFFF26",
    10: "#FFFFFF1A",
    5: "#FFFFFF0D",
  },
  blue: {
    "": "#7B4CC9", //"#415FFF",
    light: "#A569E7",
    dark: "#681DA7",
    25: "#CFD7FF",
    10: "#F5F7FF",
    menu: {
      "": '#0C1A40',
      hover: '#040F2D',
    }
  },
  green: {
    "": "#3FCB95",
    light: "#65D5AA",
    dark: "#32A277",
    10: "#ECFAF4",
    5: "#F5FCFA",
  },
  orange: {
    "": "#FFA302",
    light: "#FFB535",
    dark: "#CC8202",
    10: "#FFF6E6",
    5: "#FFFAF2",
  },
  red: {
    "": "#EC3177",
    light: "#F05A92",
    dark: "#BD275F",
    10: "#FDEAF1",
    5: "#FEF5F8",
  },
  purple: {
    logo: {
      dark: "#681DA7",
      mid: "#B56FED",
      light: "#E4C7FD",
    },
    75: "#9238D9",
    50: "#A569E7",
    25: "#BC8CFA",
    15: "#D5ADFD",
    10: "#F3DDFD",
    5: "#FAF1FE",
  },
  mahogany: {
    "": "#891E46",
    light: "#A14B6B",
    dark: "#6E1838",
    10: "#F0E8EB",
    5: "#F8F3F5",
  },
  transparent: {
    "": "transparent",
  },
  graph: {
    blue: "#8AC6FF",
    green: "#8CE5C3",
    orange: "#FACD8C",
    red: "#FF9A9A",
    purple: "#BC8CFA",
  },
  gradient: {
    purple: 'linear-gradient(180deg, #412884 -66.77%, #8750E3 85.81%)'
  },
} as const;


export type BreakpointKeys = keyof typeof breakpoints;
export const breakpoints = {
  sm: 0,
  md: 1367,
  lg: 1601,
  xl: 1920,
} as const;

export const mediaQueries = (Object.keys(breakpoints) as BreakpointKeys[]).reduce((acc, key) => {
  acc[key] = `@media (min-width: ${breakpoints[key]}px)`;
  return acc;
}, {} as Record<BreakpointKeys, string>);

export type SpacingKeys = keyof typeof spacing;
export const spacing = {
  xxs: '1px',
  xs: '5px',
  sm: '10px',
  md: '15px',
  lg: '20px',
  xl: '25px',
  xxl: '30px',
} as const;

export type RadiusKeys = keyof typeof radius;
export const radius = {
  xxs: '5px',
  xs: '10px',
  sm: '15px',
  md: '20px',
  lg: '30px',
  xl: '40px',
  xxl: '50px',
} as const;

export type OuterElevationKeys = keyof typeof outerElevation;
export const outerElevation = {
  1: "0px 4px 4px 0px rgba(0, 0, 0, 0.04)",
  2: "0px 2px 6px 0px rgba(0, 0, 0, 0.10)",
  3: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
  4: "0px 12px 24px 0px rgba(0, 0, 0, 0.12)",
} as const;

export type InnerElevationKeys = keyof typeof innerElevation;
export const innerElevation = {
  1: "5px 0px 10px 0px rgba(0, 0, 0, 0.08) inset"
} as const;

export type PromptSecurityTheme = typeof promptSecurityTheme;
export const promptSecurityTheme = {
  color: colorTheme,
  breakpoints,
  spacing,
  radius,
  outerElevation,
  innerElevation,
} as const;