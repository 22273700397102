/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import {
    Breakpoint,
    Dialog as MaterialDialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle as MuiDialogTitle,
    DialogProps
} from '@mui/material';
import styled from 'styled-components';
import PSButton, { TFilledButtonVariantType } from '../PSButton/PSButton';
import Text from '../Text/Text';
import { PSDialogStyle } from './PSDialog.css';

type IProps = {
    open: boolean;
    onClose: () => void;
    title: string;
    secondaryTitle?: string;
    children: React.ReactNode;
    action?: (...args: any[]) => Promise<any> | any;
    actionButtonVariantType?: TFilledButtonVariantType;
    actionButtonText?: string;
    isActionDisabled?: boolean;
    cancelButtonText?: string;
    isShowCancelButton?: boolean;
    onCloseOnAction?: boolean;
    maxWidth?: Breakpoint;
    fullContentWidth?: boolean;
    preventEscapeAndBackdropClose?: boolean;
} & DialogProps;

const Dialog = styled(MaterialDialog)`
    .MuiDialog-paper {
        padding: 30px 30px 20px 40px;
        border-radius: 25px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    }
`;
const DialogTitle = styled(MuiDialogTitle)`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0;
    margin-bottom: 10px;
`;

const DialogActions = styled(MuiDialogActions)`
    padding: 0;
    margin: 0;
    justify-content: flex-end;
`;

const DialogContent = styled(MuiDialogContent)`
    padding: 0;
    padding-top: 10px;
`;

const PSDialog: React.FC<IProps> = (props) => {
    const { open, onClose, title, secondaryTitle, children, preventEscapeAndBackdropClose, action = () => { }, cancelButtonText = 'Cancel', actionButtonText = 'Okay', isActionDisabled, isShowCancelButton = true, onCloseOnAction = true, maxWidth = 'md', actionButtonVariantType = 'info', fullContentWidth, ...dialogProps } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [failedMessage, setFailedMessage] = useState('');

    useEffect(() => {
        if (isFailed === false) {
            setFailedMessage('');
        }
    }, [isFailed])

    const onActionButtonClicked = async () => {
        setIsLoading(true);
        setIsFailed(false);
        try {
            await action();
            if (onCloseOnAction) onClose();
        } catch (error) {
            setIsFailed(true);
            setFailedMessage((error as any).message || '');
            console.error(error);
        }
        setIsLoading(false);
    }

    const handleOnClose = () => {
        setIsLoading(false);
        setIsFailed(false);
        onClose();
    }

    useEffect(() => {
        return () => {
            setIsLoading(false);
            setIsFailed(false);
        }
    }, [])

    return (
        <Dialog
            {...dialogProps}
            fullWidth
            maxWidth={maxWidth}
            open={open}
            onClose={preventEscapeAndBackdropClose ? undefined : onClose}
            disableEscapeKeyDown={preventEscapeAndBackdropClose}
        >
            <DialogTitle>
                <Text color='black' variant="header2">{title}</Text>
                {secondaryTitle && <Text color='black-70'>{secondaryTitle}</Text>}
            </DialogTitle>
            <DialogContent css={fullContentWidth ? PSDialogStyle.fullContentWidth : ''}>{children}</DialogContent>
            {isFailed && <Text textCss={PSDialogStyle.errorMessage} color='red' variant="bold">{'Error - ' + failedMessage || 'Error occurred try again later'}</Text>}
            <DialogActions css={PSDialogStyle.dialogActions}>
                {isShowCancelButton && <PSButton variant='flat' onClick={handleOnClose} color="primary">
                    {cancelButtonText}
                </PSButton>}
                <PSButton isLoading={isLoading} disabled={isActionDisabled} variant='filled' variantType={actionButtonVariantType} onClick={onActionButtonClicked}>
                    {actionButtonText}
                </PSButton>
            </DialogActions>
        </Dialog>
    )
}

export default PSDialog;