/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const WidgetBoxStyles = {
    self: css``,
    title: css`
        padding: 14px 30px;
        border-bottom: 1px solid var(--color-black-25);
    `,
    childrenContainer: (widgetPadding: string) => css`
        padding: ${widgetPadding};
    `,
};
