/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import CustomModeratorForm from './CustomModeratorForm';

const CustomModeratorProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    if (!isAdvancedMode) return null;

    return (
        <ProtectionWrapper
            label="Custom Moderator"
            description="Create your own moderation rules"
            formName="Custom Moderator"
            control={control}
            Component={CustomModeratorForm}
            getValues={getValues}
            isAdvancedMode={isAdvancedMode}
            summary={`Custom Moderator allows you to create your own moderation rules. This is useful if you want to block specific content types or keywords that are not covered by the other protections.`}
        />
    )
}

export default CustomModeratorProtection;