/** @jsxImportSource @emotion/react */

import {FC, ReactNode} from 'react';
import {PSBox} from "../../../../ui-kit";
import {WidgetBoxStyles} from './WidgetBox.css';
import {SerializedStyles} from "@emotion/react";

type IProps = {
    title: string | ReactNode;
    widgetPadding?: string;
    widgetCss?: SerializedStyles;
    children: ReactNode;
}

const WidgetBox: FC<IProps> = (props) => {
    const { title, widgetPadding = '20px', widgetCss, children } = props;
    return (
        <PSBox borderRadius={15} padding={0} boxCss={widgetCss}>
            <div css={WidgetBoxStyles.title}>
                {title}
            </div>
            <div css={WidgetBoxStyles.childrenContainer(widgetPadding)}>
                {children}
            </div>
        </PSBox>
    );
};

export default WidgetBox;