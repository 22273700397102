import { DateTime } from "luxon";
import { TPredefinedDateOrTimeSelection } from "../../contexts";

export const formatDate = (date: Date): string => {
  return DateTime.fromJSDate(date).toFormat("dd/MM/yyyy");
};

export const formatDateWithTime = (date: Date): string => {
  return DateTime.fromJSDate(date).toFormat("dd/MM/yyyy, hh:mm a");
};

const getLocalStorageDataRetention = () => {
  const dataRetention = localStorage.getItem("dataRetention");
  if (!dataRetention) return DateTime.now();
  return DateTime.fromISO(dataRetention);
}

export const getDataRetentionLabel = (): string => {
  return `Last ${Math.abs(getLocalStorageDataRetention().diff(DateTime.now(), 'days').days).toFixed(0)} Days`;
};

export const predefinedLabelMapper: {
  [key in TPredefinedDateOrTimeSelection]: string;
} = {
  today: "Today",
  yesterday: "Yesterday",
  last7days: "Last 7 Days",
  "last-month": "Last Month",
  "last-year": "Last Year",
  last5minutes: "Last 5 Minutes",
  last15minutes: "Last 15 Minutes",
  last30minutes: "Last 30 Minutes",
  last1hour: "Last 1 Hour",
  last3hours: "Last 3 Hours",
  last6hours: "Last 6 Hours",
  last12hours: "Last 12 Hours",
  "data-retention": getDataRetentionLabel(),
};

export const predefinedToDates = (
  predefined: Exclude<TPredefinedDateOrTimeSelection, "Custom">
): [Date, Date] => {
  const now = DateTime.now();
  let dates: [DateTime, DateTime];

  switch (predefined) {
    case "today":
      dates = [now.startOf("day"), now.endOf("day")];
      break;
    case "yesterday":
      dates = [
        now.minus({ days: 1 }).startOf("day"),
        now.minus({ days: 1 }).endOf("day"),
      ];
      break;
    default:
    case "last7days":
      dates = [now.minus({ days: 6 }).startOf("day"), now.endOf("day")];
      break;
    case "last-month":
      dates = [now.minus({ months: 1 }).startOf("day"), now.endOf("day")];
      break;
    case "last-year":
      dates = [now.minus({ years: 1 }).startOf("day"), now.endOf("day")];
      break;
    case "last5minutes":
      dates = [now.minus({ minutes: 5 }), now];
      break;
    case "last15minutes":
      dates = [now.minus({ minutes: 15 }), now];
      break;
    case "last30minutes":
      dates = [now.minus({ minutes: 30 }), now];
      break;
    case "last1hour":
      dates = [now.minus({ hours: 1 }), now];
      break;
    case "last3hours":
      dates = [now.minus({ hours: 3 }), now];
      break;
    case "last6hours":
      dates = [now.minus({ hours: 6 }), now];
      break;
    case "last12hours":
      dates = [now.minus({ hours: 12 }), now];
      break;
    case "data-retention":
      const dataRetentionDate = getLocalStorageDataRetention();
      if (!dataRetentionDate) throw new Error("Data retention date not found");
      dates = [dataRetentionDate.startOf("day"), now.endOf("day")];
      break;
  }

  return [dates[0].toJSDate(), dates[1].toJSDate()];
};





export const datesToPredefined = (start: Date, end: Date): TPredefinedDateOrTimeSelection | "Custom" => {
  const startDate = DateTime.fromJSDate(start).startOf('day');
  const endDate = DateTime.fromJSDate(end).endOf('day');
  const now = DateTime.now();
  const dataRetentionDate = getLocalStorageDataRetention();

  if (startDate.equals(now.startOf('day')) && endDate.equals(now.endOf('day'))) {
    return "today";
  } else if (startDate.equals(now.minus({ days: 1 }).startOf('day')) && endDate.equals(now.minus({ days: 1 }).endOf('day'))) {
    return "yesterday";
  } else if (startDate.equals(now.minus({ days: 6 }).startOf('day')) && endDate.equals(now.endOf('day'))) {
    return "last7days";
  } else if (startDate.equals(now.minus({ months: 1 }).startOf('day')) && endDate.equals(now.endOf('day'))) {
    return "last-month";
  } else if (startDate.equals(now.minus({ years: 1 }).startOf('day')) && endDate.equals(now.endOf('day'))) {
    return "last-year";
  } else if (startDate.equals(dataRetentionDate.startOf('day')) && endDate.equals(now.endOf('day'))) {
    return "data-retention";
  } else {
    return "Custom";
  }
};