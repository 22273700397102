/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { TextStyle } from '../Text/Text.css';

export const PSSegmentsSwitchStyle = {
    self: css`
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 2px;
        position: relative;
        border-radius: 20px;
        background: var(--color-black-5);
        border: 1px solid var(--color-black-25);
        overflow-x: auto;
        max-width: fit-content;

        &:disabled {
            pointer-events: none;
        }
  `,
    option: (width: number) => css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        padding: 7px 10px;
        border: 1px solid var(--color-transparent);
        background: var(--color-transparent);
        white-space: nowrap;
        outline: none;
        cursor: pointer;
        width: ${width}px;

        &::before {
            content: attr(data-text);
            height: 0;
            visibility: hidden;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            position: absolute;
            white-space: nowrap;
            left: 10px;
            top: 0;
            ${TextStyle.bold}
        }

        &:hover {
            background: var(--color-black-10);
        }

        &:focus-visible {
            border-color: var(--color-blue-25);
        }

        &:active {
            & .text { font-weight: 700; }
            background: var(--color-transparent);
            border-color: var(--color-blue-25);
        }

        &.isSelected {
            background: white;
            border-color: var(--color-black-25);
            box-shadow: var(--outer-elevation-1);
            & .text { font-weight: 700; }
        }

        &.isDisabled, &:disabled {
            color: var(--color-black-25);
            pointer-events: none;
        }

        &.isSelected.isDisabled, &.isSelected:disabled {
            background: white;
            border-color: var(--color-black-25);
            box-shadow: var(--outer-elevation-1);
            & .text { font-weight: 700; color: var(--color-black-25); }
            pointer-events: none;
        }
    `
};
