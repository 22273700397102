/** @jsxImportSource @emotion/react */
import { Fragment } from "react";
import {PSBadge, PSOverview, PSPercentageChangeBadge, PSPopper, Text, Circle} from "../../../../ui-kit";
import {Link} from "react-router-dom";
import { ViolationsPreventedStyles } from "./ViolationsPrevented.css";

const dummyData = [
    {pci: 44},
    {creditCards: 40},
    {crypto: 4},
]

const ViolationsPrevented = () => {

    return (
        <PSOverview
            minWidth={277}
            maxContentWidth={395}
            variant='dark'
            iconName='PSChatgptIcon'
            textContent={'Violations Prevented'}
            numberContent={465}
            isLoading={false} // todo
            topBadge={
                <PSPopper // todo - #FFD15C instead of orange
                    tooltip={
                        <>
                            <Text variant='text'>You are in monitor mode.</Text>
                            <Link to={`${window.location.origin}/employees/manage/Employees%20Connector/manage-gen-ai-applications`} style={{textDecoration: 'underline'}}><Text variant='text'>Edit</Text></Link>
                        </>
                    }
                >
                    <PSBadge label="Monitor Mode" size='medium' backgroundColor="orange-light"/>
                </PSPopper>
            }
            badge={ // todo - add correct popper value
                <PSPopper tooltip={<Text variant='text'>Compared to last 7 days</Text>}>
                    <PSPercentageChangeBadge direction='up' number={90} theme='light'/>
                </PSPopper>
            }
            footer={ // todo - add correct popper value, add max-width to footer text - ask Naama
                <PSPopper
                    placement='bottom'
                    tooltip={
                        <div css={ViolationsPreventedStyles.sensitiveData}>
                            <div css={ViolationsPreventedStyles.sensitiveDataTitle}>
                                {/* todo - #6947CF instead of blue-dark */}
                                <Circle size={10} color='blue-dark'/>
                                <Text variant='monoSmall' color='white'>Sensitive Data</Text>
                            </div>

                            <div css={ViolationsPreventedStyles.sensitiveDataGrid}>
                                {dummyData.map((item: any, index: number) => { // todo - sort out types when backend is ready
                                    const items = Object.entries(item)
                                    const isFirstRow = index === 0;
                                    const textVariant = isFirstRow ? 'bold' : 'text';
                                    return (
                                        <Fragment key={`${index}-${items[0][0]}`}>
                                            <div css={ViolationsPreventedStyles.sensitiveDataRow(isFirstRow)}>
                                                <Text variant={textVariant}
                                                      color='white'>{items[0][1] as unknown as string}</Text>
                                            </div>
                                            <div css={ViolationsPreventedStyles.sensitiveDataRow(isFirstRow)}>
                                                <Text variant={textVariant}
                                                      color='white'>{items[0][0] as unknown as string}</Text>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    }
                >
                    <Text variant='text' color='white'>Compliance: GDPR, PCI, PII</Text>

                </PSPopper>
            }
        />
    )
}

export default ViolationsPrevented;