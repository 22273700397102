import { FilterInput, FilterValuesFields, InputMaybe, OperatorKey, SearchType } from "../../../../gql/generated/graphql";
import { createGraphQLClient, graphql } from "../../../../gql";
import { Entity, TFilterQueryProperties, TSelectedOptionsQueryProperties } from "./types";

const queryAlertsLogsFilter = graphql(`
    query FilterValuesEntity($fields: [FilterValuesFields!]!, $filter: FilterInput, $size: Int!) {
        logFilterValues(options: {fields: $fields, size: $size, filter: $filter}) {
            filterValues {
                field
                values {
                    count
                    value
                }
            }
        }
    }
`);

export type TGeneratedFilterValuesFields = keyof typeof FilterValuesFields;

export class LogEntity extends Entity<typeof queryAlertsLogsFilter, TGeneratedFilterValuesFields> {
    private static readonly FIELDS_MAP: Record<TGeneratedFilterValuesFields, string> = {
        AppName: "appName",
        GenAiApplicationName: "genAiApplicationName",
        Id: "id",
        User: "user",
        UserGroups: "userGroups",
        Violations: "violations",
    } as const;

    constructor() {
        super('log', LogEntity.FIELDS_MAP, queryAlertsLogsFilter);
    }

    async filterQuery({ signal, searchField, initialFetchCount, debounceValue, date }: TFilterQueryProperties) {
        const client = createGraphQLClient(signal);

        const variables: {
            fields: FilterValuesFields | FilterValuesFields[];
            filter?: InputMaybe<FilterInput> | undefined;
            size: number;
        } = {
            fields: [searchField as FilterValuesFields],
            size: initialFetchCount,
            filter: {
                key: OperatorKey.And,
                value: [
                    {
                        time: {
                            from: date.dates[0].toISOString(),
                            to: date.dates[1].toISOString(),
                        }
                    },
                ],
            }
        };

        if (debounceValue !== '') {
            variables.filter?.value.push({
                search: {
                    value: debounceValue,
                    fields: [LogEntity.FIELDS_MAP[searchField as FilterValuesFields]],
                    type: SearchType.Contains,
                },
            })
        }

        const { logFilterValues } = await client.request(this.query, variables);
        return logFilterValues.filterValues.flatMap(fv =>
            fv.values.map(v => ({ label: v.value, value: v.value, count: v.count }))
        );
    }

    async selectedOptionsQuery({ signal, selectedIds, searchField, date }: TSelectedOptionsQueryProperties) {
        const client = createGraphQLClient(signal);

        const variables: {
            fields: FilterValuesFields | FilterValuesFields[];
            filter?: InputMaybe<FilterInput> | undefined;
            size: number;
        } = {
            fields: [searchField as FilterValuesFields],
            size: selectedIds.length,
            filter: {
                key: OperatorKey.And,
                value: [
                    {
                        [searchField as FilterValuesFields]: selectedIds,
                        time: {
                            from: date.dates[0].toISOString(),
                            to: date.dates[1].toISOString(),
                        }
                    },
                ],
            }
        };

        const { logFilterValues } = await client.request(this.query, variables);
        return logFilterValues.filterValues.flatMap(fv =>
            fv.values.map(v => ({ label: v.value, value: v.value, count: v.count }))
        );
    }
}

export const logEntity = new LogEntity();
