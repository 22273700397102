/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const ActivityMonitorDetailsStyle = {
  self: css``,
  objectContainer: css`
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    text-wrap: wrap;
    padding: 10px;
    border: 1px solid var(--color-black-50);
    border-radius: 5px;
    margin-block: 10px;
  `,
  hiddenText: css`
    color: var(--color-white);
    background: var(--color-black);
    padding: 8px;
    transform: rotate(3deg);
    width: fit-content;
  `,
};
