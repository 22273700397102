/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { RootStyle } from './Root.css';
import NavigationMenu from './NavigationMenu';
import clsx from 'clsx';
import { Outlet, useMatches } from 'react-router-dom';
import NavigationTopBar from './NavigationTopBar';
import { useLocalStorage } from 'react-use';
import { OutletErrorBoundary } from './ErrorPages';
import { useRightDrawerContext } from '../contexts';
import RightDrawer from './RightDrawer';

type IProps = {};

const Root: React.FC<IProps> = (props) => {
    const [isCollapsedLocalStorage, setIsCollapsedLocalStorage] = useLocalStorage<boolean>('isCollapsed', false);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(isCollapsedLocalStorage ?? false)
    const matches = useMatches();
    const shouldDisablePadding = matches.some(match => match.handle && (match.handle as any).shouldDisablePadding);


    const randomNumber = new Date().getTime();

    const { drawerWidth } = useRightDrawerContext();

    const handleToggleCollapse = (isCollapse?: boolean) => {
        if (isCollapse === undefined) {
            setIsCollapsed((collapsed) => !collapsed);
            return;
        }
        setIsCollapsed(isCollapse);
    }

    useEffect(() => {
        setIsCollapsedLocalStorage(isCollapsed);
    }, [isCollapsed, setIsCollapsedLocalStorage])

    return (
        <>
            <div css={RootStyle.self(drawerWidth)} className={clsx({ 'collapsed': isCollapsed })}>
                <NavigationMenu isCollapsed={isCollapsed} handleToggleCollapse={handleToggleCollapse} />
                <div css={RootStyle.contentContainer}>
                    <NavigationTopBar />
                    <div css={RootStyle.outletContainer(shouldDisablePadding)}>
                        <OutletErrorBoundary>
                            <Outlet />
                        </OutletErrorBoundary>
                    </div>
                </div>
                <RightDrawer />
            </div>

            {process.env.NODE_ENV !== 'development' && <iframe src={`/kibana/app/dashboards?random=${randomNumber}`} css={RootStyle.kibanaIframe} />}
        </>
    )
}

export default Root;