/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const SortHeaderStyle = {
  self: css`
    display: flex;
    align-items: start;
    cursor: pointer;
    user-select: none;
    gap: 5px;
    width: 100%;
  `,
};
