import React, { ChangeEvent, useEffect, useState } from 'react';
import { IconButton, MenuItem, Select, SelectChangeEvent, TextField, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { CanBypass, CoachAwareness, InspectionAction, LogAction, Policy, PolicyTypes, Rule, RuleActions, RuleContentKeys, WarnBeforeDomainUsage } from '../../../gql/generated/graphql';
import { graphql, createGraphQLClient } from '../../../gql';
import { SingleRuleContentBrowserExtension } from '../SingleRuleContent/singleRuleContentBrowserExtension';

type SingleRuleProps = {
    rule: Rule;
    updateRule: (updatedRule: Rule) => void;
    deleteRule?: () => void;
    isDefault?: boolean;
}

const queryPolices = graphql(`
    query PoliciesType($policyTypes: [String!]!) {
        policies(policyTypes: $policyTypes) {
            id
            name
            policyType
        }
    }
`);

const querySelectedOrganizationGenAiApplications = graphql(`
    query SelectedOrganizationGenAiApplications {
        selectedOrganizationGenAiApplications {
            id
            name
            domain
        }
    }
`);

export const SingleRuleBrowserExtension = ({ rule, updateRule, deleteRule, isDefault }: SingleRuleProps) => {
    const { inspectionAction, logAction, canBypassBlock, canBypassInspection, coachAwareness, warnBeforeDomainUsage } = rule.subActions;
    const [displayAllInspectionActions, setDisplayAllInspectionActions] = useState(true);
    const [localSelectedOrganizationGenAiApplications, setLocalSelectedOrganizationGenAiApplications] = useState(new Map());
    const policyId = rule.policy?.id || '';
    const action = rule.action;

    if (action === RuleActions.Inspect || (action === RuleActions.Block && canBypassBlock !== '' && canBypassBlock !== CanBypass.Prevent)) {
        if (displayAllInspectionActions === false) {
            setDisplayAllInspectionActions(true);
        }
    } else {
        if (displayAllInspectionActions === true) {
            setDisplayAllInspectionActions(false);
        }
    }

    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        const updatedRule: Rule = { ...rule, description: event.target.value };
        updateRule(updatedRule);
    };

    const onActionChange = (event: SelectChangeEvent) => {
        let policy = rule.policy;

        if (event.target.value === RuleActions.Block) {
            if (rule.subActions.canBypassBlock === CanBypass.Prevent) {
                policy = { id: "", name: "" } as Policy;
                rule.subActions.canBypassInspection = "";
                rule.subActions.inspectionAction = "";
                rule.subActions.logAction = "";
                rule.subActions.coachAwareness = "";
            }
            rule.subActions.warnBeforeDomainUsage = "";
        } else if (event.target.value === RuleActions.Ignore) {
            policy = { id: "", name: "" } as Policy;
            rule.subActions.canBypassBlock = "";
            rule.subActions.canBypassInspection = "";
            rule.subActions.inspectionAction = "";
            rule.subActions.logAction = "";
            rule.subActions.coachAwareness = "";
            rule.subActions.warnBeforeDomainUsage = "";
        }
        const updatedRule: Rule = {
            ...rule,
            policy,
            action: event.target.value as RuleActions
        };
        updateRule(updatedRule);
    };

    const onInspectionActionChange = (event: SelectChangeEvent) => {
        const updatedRule: Rule = {
            ...rule,
            subActions: {
                ...rule.subActions,
                inspectionAction: event.target.value
            }
        };
        updateRule(updatedRule);
    };

    const onPolicyChange = (event: SelectChangeEvent) => {
        const rulePolicy = rule.policy || { id: '', name: '' } as Policy;

        const updatedRule: Rule = {
            ...rule,
            policy: {
                ...rulePolicy,
                id: event.target.value
            }
        };
        updateRule(updatedRule);
    };

    const onLogActionChange = (event: SelectChangeEvent) => {
        const updatedRule: Rule = {
            ...rule,
            subActions: {
                ...rule.subActions,
                logAction: event.target.value
            }
        };
        updateRule(updatedRule);
    };

    const onCanBypassBlockChange = (event: SelectChangeEvent) => {
        let subActions = rule.subActions;
        let policy = rule.policy;

        if (event.target.value === CanBypass.Prevent) {
            subActions = {
                canBypassInspection: "",
                inspectionAction: "",
                logAction: "",
                coachAwareness: "",
                warnBeforeDomainUsage: "",
            };
            policy = { id: "", name: "" } as Policy;
        }

        const updatedRule: Rule = {
            ...rule,
            policy,
            subActions: {
                ...subActions,
                canBypassBlock: event.target.value
            }
        };
        updateRule(updatedRule);
    };

    const onCanBypassInspectionChange = (event: SelectChangeEvent) => {
        const updatedRule: Rule = {
            ...rule,
            subActions: {
                ...rule.subActions,
                canBypassInspection: event.target.value
            }
        };
        updateRule(updatedRule);
    };

    const onCoachAwarenessActionChange = (event: SelectChangeEvent) => {
        const updatedRule: Rule = {
            ...rule,
            subActions: {
                ...rule.subActions,
                coachAwareness: event.target.value
            }
        };
        updateRule(updatedRule);
    };

    const onWarnBeforeDomainUsageChange = (event: SelectChangeEvent) => {
        const updatedRule: Rule = {
            ...rule,
            subActions: {
                ...rule.subActions,
                warnBeforeDomainUsage: event.target.value
            }
        };
        updateRule(updatedRule);
    };

    const onContentChange = (content: any) => {
        const updatedRule: Rule = {
            ...rule, content
        };
        updateRule(updatedRule);
    };

    const InspectionActionToDisplay = (inspectionAction: InspectionAction) => {
        switch (inspectionAction) {
            case InspectionAction.Detect:
                return 'Yes';
            case InspectionAction.Prevent:
                return 'No';
        }
    }

    const coachAwarenessToText = (coachAwareness: CoachAwareness) => {
        switch (coachAwareness) {
            case CoachAwareness.All:
                return 'Yes';
            case CoachAwareness.Nothing:
                return 'No';
        }
    }

    const { data: policies } = useQuery(
        {
            queryKey: ["policies", PolicyTypes.Regular],
            queryFn: async ({ signal }) => {
                const client = createGraphQLClient(signal);
                const { policies } = await client.request(queryPolices, { policyTypes: [PolicyTypes.Extension] });
                return policies;
            },
            select: data => Object.fromEntries(data.map(x => [x.name, x.id]))
        }
    );

    const { data: selectedOrganizationGenAiApplications } = useQuery(
        {
            queryKey: ["selectedOrganizationGenAiApplications"],
            queryFn: async ({ signal }) => {
                const client = createGraphQLClient(signal);
                const { selectedOrganizationGenAiApplications } = await client.request(querySelectedOrganizationGenAiApplications);
                return selectedOrganizationGenAiApplications;
            },
            select: data => new Map(data.map(x => [x.id, x.name]))
        }
    );

    useEffect(() => {
        if (selectedOrganizationGenAiApplications !== undefined) {
            setLocalSelectedOrganizationGenAiApplications(selectedOrganizationGenAiApplications);
        }
    }, [selectedOrganizationGenAiApplications])

    function addSpacesToCamelCase(str: string) {
        return str.replace(/(?<!^)([A-Z])/g, ' $1');
    }

    if (policies === undefined) {
        return <></>
    }

    return (
        <div style={{ display: 'flex', paddingLeft: '10px', paddingRight: '20px', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', paddingLeft: '10px', paddingRight: '20px', width: '100%' }}>
                <div style={{ display: 'flex', gap: '15px', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', flexGrow: 5, width: 'min(100%, 300px)', alignItems: 'center', gap: '15px' }}>
                        <Typography>Description</Typography>
                        <TextField
                            value={rule.description}
                            onChange={onDescriptionChange}
                            size='small'
                            fullWidth
                        />
                    </div>
                    <div style={{ display: 'flex', flexGrow: 1, width: 'min(100%, 300px)', alignItems: 'center', gap: '15px' }}>
                        <Typography>Action</Typography>
                        <Select
                            value={action ? action : ''}
                            onChange={onActionChange}
                            size='small'
                            fullWidth
                        >
                            {Object.values(RuleActions).filter(value => isDefault ? value !== RuleActions.Block : true).map((value, i) =>
                                <MenuItem key={i} value={value}>{addSpacesToCamelCase(value)}</MenuItem>
                            )}
                        </Select>
                    </div>
                    {action === RuleActions.Block && <div style={{ display: 'flex', flexGrow: 1, width: 'min(100%, 300px)', alignItems: 'center', gap: '15px' }}>
                        <Tooltip title="Determine if employees can bypass the blockage">
                            <Typography style={{ width: 'min(100%, 200px)' }}>Can Bypass Block</Typography>
                        </Tooltip>
                        <Select
                            value={canBypassBlock}
                            onChange={onCanBypassBlockChange}
                            size='small'
                            fullWidth
                        >
                            {Object.values(CanBypass).sort((a, b) => a.localeCompare(b)).filter(value => value !== 'UponApproval').map((value, i) =>
                                <MenuItem key={i} value={value}>{addSpacesToCamelCase(value)}</MenuItem>
                            )}
                        </Select>
                    </div>}
                </div>
                {displayAllInspectionActions && <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                    <div style={{ display: 'flex', gap: '30px', alignItems: 'center', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', flexGrow: 1, width: 'min(100%, 200px)', alignItems: 'center', gap: '15px' }}>
                            <Typography style={{ width: 'min(100%, 150px)' }}>Monitor Only</Typography>
                            <Select
                                value={inspectionAction}
                                onChange={onInspectionActionChange}
                                size='small'
                                fullWidth
                            >
                                {Object.values(InspectionAction).sort((a, b) => a.localeCompare(b)).map((value, i) =>
                                    <MenuItem key={i} value={value}>{InspectionActionToDisplay(value)}</MenuItem>
                                )}
                            </Select>
                        </div>
                        <div style={{ display: 'flex', flexGrow: 1, width: 'min(100%, 200px)', alignItems: 'center', gap: '15px' }}>
                            <Tooltip title="Determine the type of logs that are being sent to the SIEM integrations">
                                <Typography>Log</Typography>
                            </Tooltip>
                            <Select
                                value={logAction}
                                onChange={onLogActionChange}
                                size='small'
                                fullWidth
                            >
                                {Object.values(LogAction).sort((a, b) => a.localeCompare(b)).filter(value => addSpacesToCamelCase(value) !== 'None').map((value, i) =>
                                    <MenuItem key={i} value={value}>{addSpacesToCamelCase(value)}</MenuItem>
                                )}
                            </Select>
                        </div>
                        <div style={{ display: 'flex', flexGrow: 4, width: 'min(100%, 200px)', alignItems: 'center', gap: '15px' }}>
                            <Typography style={{ width: 'min(100%, 100px)' }}>Use Policy</Typography>
                            <Select
                                value={policyId}
                                onChange={onPolicyChange}
                                size='small'
                                fullWidth
                            >
                                {Object.entries(policies).map(([name, id]) =>
                                    <MenuItem key={id} value={id}>{name}</MenuItem>
                                )}
                            </Select>
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: '30px', alignItems: 'center', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', flexGrow: 1, width: 'min(100%, 300px)', alignItems: 'center', gap: '15px' }}>
                            <Tooltip title="Determine if employees can bypass the browser extension inspection for GenAI applications">
                                <Typography style={{ width: 'min(100%, 250px)' }}>Can Bypass Inspection</Typography>
                            </Tooltip>
                            <Select
                                value={canBypassInspection}
                                onChange={onCanBypassInspectionChange}
                                size='small'
                                fullWidth
                            >
                                {Object.values(CanBypass).sort((a, b) => a.localeCompare(b)).map((value, i) =>
                                    <MenuItem key={i} value={value}>{addSpacesToCamelCase(value)}</MenuItem>
                                )}
                            </Select>
                        </div>
                        {inspectionAction === InspectionAction.Prevent && <div style={{ display: 'flex', flexGrow: 1, width: 'min(100%, 300px)', alignItems: 'center', gap: '15px' }}>
                            <Tooltip title="Determine if employees will receive warnings when sharing sensitive data with GenAI applications">
                                <Typography style={{ width: 'min(100%, 200px)' }}>Coach Awareness</Typography>
                            </Tooltip>
                            <Select
                                value={coachAwareness}
                                onChange={onCoachAwarenessActionChange}
                                size='small'
                                fullWidth
                            >
                                {Object.values(CoachAwareness).map((value, i) =>
                                    <MenuItem key={i} value={value}>{coachAwarenessToText(value)}</MenuItem>
                                )}
                            </Select>
                        </div>}
                        {!isDefault && action === RuleActions.Inspect && <div style={{ display: 'flex', flexGrow: 1, width: 'min(100%, 300px)', alignItems: 'center', gap: '15px' }}>
                            <Tooltip title="Determine if employees will receive a warning message when entering GenAI applications">
                                <Typography style={{ width: 'min(100%, 400px)' }}>Warn Before GenAI App Usage</Typography>
                            </Tooltip>
                            <Select
                                value={warnBeforeDomainUsage}
                                onChange={onWarnBeforeDomainUsageChange}
                                size='small'
                                fullWidth
                            >
                                {Object.values(WarnBeforeDomainUsage).sort((a, b) => a.localeCompare(b)).map((value, i) =>
                                    <MenuItem key={i} value={value}>{value}</MenuItem>
                                )}
                            </Select>
                        </div>}
                    </div>
                </div>}
                {isDefault ? null : <div>
                    <div style={{ display: 'flex', flexGrow: 1, minWidth: '300px', alignItems: 'center', gap: '20px' }}>
                        <>
                            <Typography>Conditions</Typography>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px', width: '100%' }}>
                                <SingleRuleContentBrowserExtension content={rule.content} onChange={onContentChange} validContentKeys={rule.action === RuleActions.Block ? [RuleContentKeys.UserEmail, RuleContentKeys.UserGroups, RuleContentKeys.GenAiApplication] : [RuleContentKeys.UserEmail, RuleContentKeys.UserGroups, RuleContentKeys.GenAiApplication]} selectedOrganizationGenAiApplications={localSelectedOrganizationGenAiApplications} />
                            </div>
                        </>
                    </div>
                </div>}
            </div>
            {
                isDefault ? null :
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                        <IconButton aria-label="delete" onClick={deleteRule} style={{ borderRadius: '0' }}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
            }
        </div>
    )
}